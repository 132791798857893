import {useState} from 'react';

import Skeleton from '@mui/material/Skeleton';

import {isEmptyObj} from 'ultra/helpers/utils';

import ShoppingCardButton from './Buttons/ShoppingCardButton'
import GlobalSearchNodeButton from './Buttons/GlobalSearchNodeButton'
import NodeManipulationsButton from './Buttons/NodeManipulationsButton'
import ShareButton from './Buttons/ShareButton'

import {useNavigationStore} from '../../../../Stores/navigation';
import {useContentStore} from '../../../../Stores/content';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import './index.scss'

export default function NodeActions() {
    const [showActions, setShowActions] = useState(false);

    const {contentLoading} = useNavigationStore();
    const {userState} = useGlobalCustomContext();
    const {content} = useContentStore();

    if (userState?.userLoginInProgress) return <div className='actionsList'>
        <div className='actionsListButtons'>
            <Skeleton variant="rectangular" width={56} height={56} style={{
                borderRadius: '15px',
                display: 'inline-flex',
                marginLeft: '0.5rem'
            }} />
        </div>
    </div>

    if(isEmptyObj(content?.page?.content)) return <></>

    return <div className='actionsList'>
        <div className='actionsListButtons'>
            <ShoppingCardButton disabled={contentLoading} showActions={showActions}/>

            <ShareButton did={content?.page?.content?._did} title={content?.page?.content?.title} showActions={showActions}/>

            <GlobalSearchNodeButton disabled={contentLoading} showActions={showActions}/>

            <NodeManipulationsButton config={content?._virtual_uri || content} content={content?.page?.content} onOpen={setShowActions} />
        </div>
    </div>
}
