import React from 'react';
import {getClientCity} from 'ultra/configs/general';

import {DEFAULT_CITY} from 'ultra/const/general';

import Fab from '@mui/material/Fab';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {NODE_ACTIONS} from '../../../../Reducers/node';

export default function ValidateNodeButton(props) {
    const {disabled, published, uri, closeAction} = props;
    const {nodeDispatch} = useGlobalCustomContext();

    const handleClick = () => {
        if (disabled) return;
        closeAction();
        const city = getClientCity(window) || DEFAULT_CITY;

        if (published) {
            nodeDispatch({type: NODE_ACTIONS.UNVALIDATE, data: {city, uri}});
        } else {
            nodeDispatch({type: NODE_ACTIONS.VALIDATE, data: {city, uri}});
        }
    };

    return <>
        <Fab
            className={`validateContentIcon ${published ? 'Unpublish' : ''}`}
            color="neutral"
            onClick={handleClick}>
                {!published && <CheckCircleOutlinedIcon />}
                {published && <UnpublishedOutlinedIcon />}
        </Fab>
    </>
}
