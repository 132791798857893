import {getClientConfig} from 'ultra/configs/general';
import {cachedGet} from './http';

const {api} = getClientConfig(window)

export function getModule(module) {
    return cachedGet('modules', module, `${api}/module/${module}`).promise;
}

export function getInsuranceConfig() {
    return cachedGet('insurance', 'all', `${api}/config/content/insurance`).promise;
}
