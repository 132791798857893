import React, {useState, useEffect} from 'react'
import {useNavigation, useNavigate} from "react-router-dom";

// import ClickAwayListener from '@mui/material/ClickAwayListener';

import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import Slide from '@mui/material/Slide';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {hasFullAccess} from 'ultra/helpers/auth';

import AddNodeButton from '../../Buttons/AddNodeButton'
import CorrectNodeButton from '../../Buttons/CorrectNodeButton'
import UpdateNodeButton from '../../Buttons/UpdateNodeButton'
import RemoveNodeButton from '../../Buttons/RemoveNodeButton'
import ValidateNodeButton from '../../Buttons/ValidateNodeButton'

import {useSearchStore} from '../../../../../../Stores/search';
import {useNavigationStore} from '../../../../../../Stores/navigation';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import './index.scss';

function Actions(props) {
    const {config, content, onCloseAction} = props;

    const {userState} = useGlobalCustomContext();

    const navigate = useNavigate();

    const {contentLoading} = useNavigationStore()
    const createNodes = config?.permits?.children?.create;
    const createIdentifiedNodes = config?.permits?.identified?.children?.create;
    const ableToAdd = (createNodes?.length > 0) || (createIdentifiedNodes?.length > 0);
    const ableToUpdate = config?.permits?.update;
    const ableToDelete = config?.permits?.delete;
    const ableToValidate = config?.permits?.validate
    const ableToCorrect = !ableToUpdate && config?.form?.availableToCorrect;
    const ableViewHistory = hasFullAccess(userState?.user);
    
    const handleHistoryClick = () => {
        onCloseAction();
        const city = getClientCity(window) || DEFAULT_CITY;
        navigate(`/profile/history/node/${city}/${config.page.content._did}`)
    }

    return <>
        {ableViewHistory && <Fab
            color="neutral"
            onClick={handleHistoryClick}>
                <HistoryOutlinedIcon />
        </Fab>}

        {ableToValidate && <ValidateNodeButton closeAction={onCloseAction} disabled={contentLoading} published={content._published} uri={config?.page?.content._uri} />}

        {ableToDelete && <RemoveNodeButton closeAction={onCloseAction} disabled={contentLoading} uri={config?.page?.content._uri} type={config?.page?.content._type}/>}

        {ableToUpdate && <UpdateNodeButton type={config?.page?.content._type} uri={config?.page?.content._uri} closeAction={onCloseAction}/>}

        {!ableToUpdate && ableToCorrect && <CorrectNodeButton closeAction={onCloseAction} disabled={contentLoading} />}

        {ableToAdd && <AddNodeButton
            closeAction={onCloseAction}
            parent={config?.page?.content._uri}
            nodes={createNodes}
            identifiedNodes={createIdentifiedNodes}
            childrenOptions={config.page?.config?.childrenOptions}
        />}
    </>
}

export default function NodeButton(props) {
    const {config, onOpen} = props;

    const navigation = useNavigation();
    const {displaySearch} = useSearchStore();

    const [showActions, setShowActions] = useState(false);

    const createNodes = config?.permits?.children?.create;
    const createIdentifiedNodes = config?.permits?.identified?.children?.create;
    const ableToAdd = (createNodes?.length > 0) || (createIdentifiedNodes?.length > 0);
    const ableToUpdate = config?.permits?.update;
    const ableToDelete = config?.permits?.delete;
    const ableToValidate = config?.permits?.validate

    let availableButtons = 0    
    if (ableToAdd) availableButtons++
    if (ableToUpdate) availableButtons++
    if (ableToDelete) availableButtons++
    if (ableToValidate) availableButtons++

    useEffect(() => {
        if (navigation.state === 'loading') {
            setShowActions(false);
            close();
        }
    }, [navigation])

    function open() {
        onOpen(true);
        setShowActions(true);
    }

    function close() {
        onOpen(false);
        setShowActions(false);
    }

    function showItToggle() {
        showActions ? close() : open()
    }

    if (availableButtons === 0) return <></>

    if (availableButtons === 1) return <Actions {...props} onCloseAction={close}/>

    return <Slide direction="left" in={!displaySearch}>
        <div className='NodeManipulationsButton'>
        
            <Slide direction="up" in={showActions} mountOnEnter unmountOnExit>
                <div className='NodeActions'>
                    <Actions {...props} onCloseAction={showItToggle}/>
                </div>
            </Slide>

            {/* <ClickAwayListener onClickAway={() => showActions ? close() : open()}> */}
            <Fab color="contrast" className={'NodeActionsButton' + (showActions ? ' Close' : '')} onClick={showItToggle}>
                {showActions ? <CloseIcon /> : <SettingsOutlinedIcon /> }
            </Fab>
            {/* </ClickAwayListener> */}

            {/* {showActions && <span className='transparentBack' onClick={close}></span>} */}
        </div>
    </Slide>
}
