import {useState} from 'react';
import {isMobile} from "react-device-detect";

import {useLocation, useNavigate} from "react-router-dom";
import {sendPasswordResetEmail} from "firebase/auth";

import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";
import {signOut, getAuth} from "firebase/auth";
import {revertPath} from 'ultra/helpers/route';
import {getClientCity, getCityConfig} from 'ultra/configs/general';

import Grid from '@mui/material/Grid';
// import Badge from '@mui/material/Badge';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

import Switch from '@mui/material/Switch';

import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import TelegramIcon from '@mui/icons-material/Telegram';

// import {DEFAULT_CITY} from 'ultra/const/general';
// import {getClientCity} from 'ultra/configs/general';
// import {getClientUrl} from 'ultra/configs/general';

import {useToasterStore} from '../../../../../Stores/toster';
import {useFirebaseStore} from '../../../../../Stores/firebase';
import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import {getNodeContent} from '../../../../../Helpers/content';
import {useGlobalCustomContext} from '../../../../../Helpers/context';

import {userLogout} from '../../../../Profile/Helpers/user';
import {USER_ACTIONS} from '../../../../Profile/Reducers/user';

import {NODE_ACTIONS} from '../../../Reducers/node';

import Avatar from '../../../Widgets/Avatar';

import {saveUserHistoryAndNotify, updateUser} from '../../../../Profile/Helpers/user';

import Preloader from '../../../../../Components/Preloaders/Preloader';
import AdaptiveDialog from '../../../../../Components/Adaptive/Dialog';

import './index.scss';

export default function UserLinksHeader(props) {
    const {closeMenu} = props;

    const auth = getAuth();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

    const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();

    const location = useLocation();
    const navigate = useNavigate();
    const [settingsOpen, setSettingsOpen] = useState(false);
    // const [notifications, setNotifications] = useState(0);
  
    // useState(() => {
    //   if (userState?.user) {
    //     let n = userState?.user?.income || 0
    //     if (userState?.user?._not_checked) n++;
    //     if (userState?.user?.emailNotVerified) n++;

    //     setNotifications(n)
    //   }
    // }, [userState?.user])

    // const notificationsList = () => {
    //   closeMenu();
    //   navigate('/profile/messages');
    //   // const city = getClientCity(window) || DEFAULT_CITY;
    //   // window.location.assign(getClientUrl(city, '/profile/messages'));
    // }

    const handleUserMenuClose = () => {
      // userDispatch({type: USER_ACTIONS.PROFILE_EDIT_CLOSE});
      nodeDispatch({type: NODE_ACTIONS.CLOSE_USER_MENU});
    }

    const onProfileSettings = () => {
      // closeMenu();
      setSettingsOpen(true);
    }

    const onSettingsClose = () => {
      setSettingsOpen(false);
    }

  // TODO: make one function / has duplicate
  const onExitHandler = () => {
    handleUserMenuClose();
    showBackdropPreloader();

    userLogout()
      .promise
      .then(async () => {
          signOut(auth);

          userDispatch({type: USER_ACTIONS.USER_REMOVE});

          nodeDispatch({type: NODE_ACTIONS.CLOSE_USER_MENU});

          hideBackdropPreloader();

          const isProfilePage = location.pathname.indexOf('/profile') === 0

          if (isProfilePage) {
            navigate('/');
          }
          else {
            const city = getClientCity(window);
            
            try {
              await getNodeContent({
                city,
                uri: location.pathname
              }).promise

              navigate(location.pathname + location.search);
            }
            catch (e) {
              navigate('/');
            }
          }

          window.localStorage.removeItem("verify-email-msg-displayed");
      })
      .catch(() => {
        userDispatch({type: USER_ACTIONS.USER_REMOVE});
      })
    }

    return <div className='UserCard WithActions'>
        <span className='UserLogo'>
          <Avatar image={userState?.user?.photoURL} roles={userState?.user?.roles} minimal={`${isMobile ? 'false' : 'true'}`}/>
          <ModeEditOutlineOutlinedIcon className='edit' onClick={onProfileSettings}/>
        </span>
  
        <p className='CardTitle'>{userState?.user?.displayName}</p>
        <p className='CardEmail'>{userState?.user?.email}</p>
  
        <span className='exit'>
          <ExitToAppOutlinedIcon onClick={onExitHandler}/>
        </span>

        {/* <Badge
            className='income'
            color='error'
            badgeContent={notifications}
            
          >
        </Badge> */}
  
        {/* {userState?.user?._not_checked && <div className='informer globalInformer error'>
          <ErrorOutlineIcon />
          Ваш аккаунт проходить перевірку після реєстрації
        </div>} */}
  
        {/* {userState?.user?.emailNotVerified && <div className='informer globalInformer error'>
          <AlternateEmailIcon />
          Підтвердіть вашу електрону адресу, перейшовши за посиланням, яке ми відправили вам на email
        </div>} */}

        <AdaptiveDialog open={settingsOpen} onClose={onSettingsClose} title="Налаштування">
          <SettingsBlock closeMenu={closeMenu} />
        </AdaptiveDialog>
      </div>
}

function SettingsBlock(props) {
  const {closeMenu} = props;

  const {showBackdropPreloader} = useBackdropPreloaderStore()
  const {userState, userDispatch} = useGlobalCustomContext();
  const {showSuccess, showError} = useToasterStore();
  const {auth} = useFirebaseStore();

  const [notificationsChangeInProgress, setNotificationsChangeInProgress] = useState();

  const onProfileEdit = () => {
    closeMenu();
    userDispatch({type: USER_ACTIONS.PROFILE_EDIT})
  }

  const onPasswordEdit = () => {
    closeMenu();

    if (userState?.user.emailNotVerified) {
        showError({
            snackbarMessage: 'Для зміни паролю, необхідно підтвердити ваш email',
        })
        return;
    }

    showBackdropPreloader();

    // TODO: make similar to email validation
        // save flag
        // and move logs and history to backend
    if (!userState?.user?.email) return;

    sendPasswordResetEmail(auth, userState.user.email)
        .then(async () => {
            showSuccess({
                snackbarMessage: 'На вашу електронну адресу було направлено посилання для зміни паролю',
            });

            await saveUserHistoryAndNotify(userState.user.email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS);
        })
        .catch(async err => {
            console.log(err)
            if (err.code === 'auth/invalid-email') {
                showError({
                    snackbarMessage: `${userState.user.email} - не коректний email`
                });
            } else {
                showError({
                    snackbarMessage: 'Виникла помилка, спробуйте пізніше'
                });
            }

            await saveUserHistoryAndNotify(userState?.user?.email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_ERROR, err);
        })
  }

  const onNotificationsClick = (e) => {
    e.stopPropagation();
  }

  const onNotificationsChange = (e) => {
    setNotificationsChangeInProgress(true)
    updateUser(userState.user.id, {telegram_notify: !userState.user.telegram_notify})
      .then(() => {
        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
        setTimeout(() => {
          setNotificationsChangeInProgress(false)
        }, 1000)
      })
      .catch((e) => {
        showError({
          snackbarMessage: 'Виникла помилка збереження даних',
        })
        setNotificationsChangeInProgress(false)
      })
  }

  return <div className='UserSettingsBlock'>
        {/* Налаштування */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
              <span className='linkWrap' onClick={onProfileEdit}>
                {/* <ListItemIcon> */}
                  <DriveFileRenameOutlineOutlinedIcon />
                {/* </ListItemIcon> */}
                {/* <ListItemText> */}
                  Редагувати профіль
                {/* </ListItemText> */}
                </span>
          </Grid>
          <Grid item xs={12} md={6}>
              <span className='linkWrap' onClick={onPasswordEdit}>
                {/* <ListItemIcon> */}
                  <PasswordIcon />
                {/* </ListItemIcon> */}
                {/* <ListItemText> */}
                  Змінити пароль
                {/* </ListItemText> */}
                </span>
          </Grid>

          {userState.user.telegram_id && <Grid item xs={12} md={6}>
              <span className='linkWrap' onClick={onNotificationsClick}>
                {/* <ListItemIcon> */}
                  <TelegramIcon />
                {/* </ListItemIcon> */}
                {/* <ListItemText> */}
                  Сповіщення в бот
                  <div className='switchPreloader'>
                    {/* <LinearProgress /> */}
                    {notificationsChangeInProgress && <Preloader />}
                    <Switch disabled={notificationsChangeInProgress} checked={userState.user.telegram_notify} onChange={onNotificationsChange}/>
                  </div>
                {/* </ListItemText> */}
                </span>
          </Grid>}
        </Grid>
  </div>
}
