import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {timestampToDate, getTimezoneNameString, isTimestamp} from 'ultra/helpers/date'

import {useConfigStore} from '../../../Stores/config';
import {ukLocale} from '../../../Helpers/locale';

import './index.scss'

export default function TimestampField(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [className, setClassName] = useState('TimestampField' + (showErrorText ? ' error' : ''))
    const [placeholder] = useState((field?.placeholder ? field?.placeholder : '') + (field?.required ? ' *' : ''))

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = content._city || getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [value, setValue] = useState(null)

    useEffect(() => {
        setClassName('TimestampField' + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    useEffect(() => {
        const v = content[field.id];

        if (content[field.id]) {
            setValue(dayjs(isTimestamp(v) ? timestampToDate(v) : new Date(v)))
        }
    }, [content[field.id]])

    const onDateChange = (e) => { onChange({target: { value: e.valueOf() }}) }

    const onTimeChange = (e) => { onChange({target: { value: e.valueOf() }}) }

    return (<Grid className='TimestampFieldWrap' key={field.id} item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukLocale}>
            <Grid container spacing={1}>
                <Grid item xs={field?.options?.timePicker ? 6 : 12}>
                    <DesktopDatePicker
                        closeOnSelect
                        className={className}
                        label={placeholder}
                        disabled={isDisabled}
                        value={value}
                        onChange={onDateChange}
                        timezone={timeZone}
                    />
                </Grid>
                {field?.options?.timePicker && <Grid item xs={6}>
                    <DesktopTimePicker
                        closeOnSelect = {false}
                        className={className}
                        label="Час"
                        disabled={isDisabled}
                        value={value}
                        views={['hours', 'minutes']}
                        onChange={onTimeChange}
                        timezone={timeZone}
                        />
                </Grid>}
                {field?.options?.showTimezone && timeZone && <Grid item xs={12}>
                    <div className='locale'>
                        {getTimezoneNameString((value?.toDate() || new Date()), timeZone)}
                    </div>
                </Grid>}
            </Grid>
        </LocalizationProvider>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </Grid>)
}
