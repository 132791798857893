import {useNavigate} from "react-router-dom";
const {ROLES} = require("ultra/const/user");

export default function UserHistoryLink(props) {
    const {onlyText, name, id} = props;

    const navigate = useNavigate();

    const onClickHandler = () => {
        return navigate(`/profile/history/user/${id}`)
    }

    if (name === ROLES.ANONYMOUS) {
        return name;
    }
    
    if (onlyText) {
        return name;
    }
//  className='pseudoLink'
    return <span onClick={onClickHandler}>{name}</span>
}
