import React, {useEffect, useState} from 'react';

import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';

import {HISTORY_NODE} from "ultra/const/log";

import {useGlobalCustomContext} from '../../../../Helpers/context';

import InlineActions from '../../../Content/Widgets/InlineActions';

import {NODE_ACTIONS} from '../../../Content/Reducers/node';

import './index.scss';

export function getActions(options) {
    const {content, isRemoved} = options;

    const actions = [];

    if (!content) return actions;

    if (content) {
        let hasRestoreNodeAction

        if (content._code === HISTORY_NODE.REMOVE && isRemoved) {
            hasRestoreNodeAction = true
        }

        if (content._code === HISTORY_NODE.WORKER_REMOVE && !isRemoved) {
            hasRestoreNodeAction = true
        }

        if (hasRestoreNodeAction) {
            actions.push('restore')
        }
    }
    return actions;
}

export function NodeTableHistoryInlineActions(props) {
    const {id, data, actionsProps} = props

    return <NodeHistoryInlineActions
        {...actionsProps}
        content={data.list[id]}
    />
}

export default function NodeHistoryInlineActions(props) {
    const {content, unfolded, direction, className} = props;
    const {nodeDispatch} = useGlobalCustomContext();

    const [list, setList] = useState([])

    useEffect(() => {
        const actions = getActions(props);
        const list = actions.map(id => {
            let result;
            switch(id) {
                case 'restore':
                    result = {
                        id: 'restore',
                        icon: <SettingsBackupRestoreOutlinedIcon className='Icon' />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.RESTORE, data: {
                                date: content._timestamp,
                                action: content._code,
                                user: content._init_by,
                            }})
                        }
                    }
                    break;
            }
            return result;
        });
        setList(list);
    }, [content])

    if (!content) return null;
    if (list.length === 0) return null;

    return <InlineActions actions={list} className={className} direction={direction} unfolded={unfolded} id={content._did} />
}
