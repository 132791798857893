import {debounce} from 'lodash';
import {isMobile} from "react-device-detect";
import {Link as RouterLink} from "react-router-dom";
import React, {useEffect, useState, useRef, useMemo} from 'react';

import {useSearchParams} from "react-router-dom";

import {isUndefined} from 'lodash';

import {isEmptyObj} from 'ultra/helpers/utils';
import {getClientCity} from 'ultra/configs/general'
import {DEFAULT_CITY} from 'ultra/const/general';
import {hasFullAccess} from 'ultra/helpers/auth';
import {normalizeRoute, restorePath} from "ultra/helpers/route";

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';

import {TILE_SIZES} from '../../../../Helpers/preloaders';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import Icon from '../../../../Components/Icon';

import ContentImage from '../ContentImage';
import Pagination from '../../Widgets/Pagination';
import FilterChip from '../../Widgets/FilterChip'
import FiltersLine from '../../Widgets/FiltersLine';
import NodeInlineActions from '../../Widgets/NodeInlineActions';
import NotPublishedIcon from '../../Widgets/NotPublishedIcon';

import {useConfigStore} from '../../../../Stores/config';

import {useVirtualUriStore} from '../../../../Stores/url';

import './index.scss'

function TileSkeleton (props) {
    const {src} = props;

    const type = (src || 'thumbnail').toUpperCase()
    const device = isMobile ? 'MOBILE' : 'DESKTOP'

    return <>
        <div className="LinksTileImageWrap">
            <Skeleton variant="rectangular" width={'100%'} height={TILE_SIZES?.[type]?.[device]?.['H']} />
        </div>
        <div className='title'>
            <Skeleton variant="rectangular" width={'100%'} />
        </div>
    </>
}

function TileItem(props) {
    const {imgLoading, configs, link, src, label} = props;

    return <>
        <ContentImage image={link.data[src || 'thumbnail']} className={src || 'thumbnail'} src={src || 'thumbnail'} imgLoading={imgLoading} />
        {link.data.status && !label && <div className="photoLabel">
            <FilterChip value={link.data.status} config={configs.content.nodes[link.data._type]?.fields?.status} />
        </div>}
    </>
}

function TilePicture(props) {
    const {uri, href, badges, configs, link, src, label, linkHandler, imgLoading, isExternalLinksSection} = props;

    if (!isEmptyObj(badges) && !badges[uri]) {
        return <span className="LinksTileImageLink">
            <TileItem configs={configs} link={link} src={src} label={label} imgLoading={imgLoading} />
        </span>
    }

    if (linkHandler) {
        return <span className="LinksTileImageLink" onClick={() => linkHandler(normalizeRoute(uri?.slice(1)))}>
            <TileItem configs={configs} link={link} src={src} label={label} imgLoading={imgLoading} />
        </span>
    }

    if (isExternalLinksSection && href) {
        return <a href={href} className="LinksTileImageLink" target="_blank">
            <TileItem configs={configs} link={link} src={src} label={label} imgLoading={imgLoading} />
        </a>
    }

    if (!isExternalLinksSection || (isExternalLinksSection && !href)) {
        return <RouterLink to={uri?.slice(1)} className="LinksTileImageLink">
            <TileItem configs={configs} link={link} src={src} label={label} imgLoading={imgLoading} />
        </RouterLink>
    }

    return <></>
}

export default function LinksTile(props) {
    const {links, order, label, badges, spacing, xs, md, src, noTitle, title, autoScrollToActive, absentText, showPagination, showFilters, linkHandler, imgLoading, showSkeleton, isExternalLinksSection, className} = props

    const [filterBy, setFilterBy] = useState();

    const {configs} = useConfigStore();

    let [searchParams, setSearchParams] = useSearchParams();
    const {virtualUri} = useVirtualUriStore();

    const XsCellSize = xs || 12
    const MdCellSize = md || 6
    const showTitle = !noTitle

    const [city] = useState(getClientCity(window) || DEFAULT_CITY)
    const [sortedLinks, setSortedLinks] = useState([])
    const [blockClassName] = useState(`LinksTile ${className ? className : ''}`)

    useEffect(() => {
        let o = links?.order;

        if (order) {
            const m = {}
            links.order.map((uri) => {
                m[links.list[uri]._did] = uri
            })

            const notPresentInOrder = links.order.filter(uri => !order.includes(links.list[uri]._did))


            o = order.map(i => m[i]).concat(notPresentInOrder);
        }

        let list = links?.list;
        let permits = links?.permits;

        const updatedSortedLinks = []
        o?.map(link => {
            if (!list[link]) return;

            const linkUri = normalizeRoute(list[link]?._uri);
            const isActive = virtualUri === linkUri;

            updatedSortedLinks.push({
                permits: permits[link],
                data: {...list[link]},
                isActive
            });
        })

        setSortedLinks(updatedSortedLinks);

        setFilterBy(showFilters?.filterByInline);

        if (links && autoScrollToActive) {
            setTimeout(() => {
                if (linksRef?.current) {
                    const active = linksRef.current.querySelector('.LinksTileItem.active .photoWrap');

                    if (active) {
                        const rect = active?.getBoundingClientRect();

                        const isVisible =
                            (Math.floor(rect?.left) + active.offsetWidth) < linksRef.current.offsetWidth
                            && ((Math.floor(rect?.right) - active.offsetWidth - 16) > 0);

                        if (active && !isVisible) {
                            linksRef.current.scrollTo(Math.floor(rect?.left), 0); // + active.offsetWidth
                        }
                    }
                }
            })
        }
    }, [links, virtualUri])

    function renderTitle(link) {
        if (title) {
            return link.title && <div className={link._published ? "title" : "title notPublishedWrap"}>
                {link._published ? <></> : <NotPublishedIcon />}
                {link._icon ? <Icon icon={link._icon} /> : <></>}
                <span className="titleText">{title(link)}</span>
            </div>
        }

        if (!link.title) return <></>

        if (linkHandler) {
            return <div className={link._published ? "title" : "title notPublishedWrap"}>
                <span className="titleLink" onClick={() => linkHandler(normalizeRoute(link?._uri?.slice(1)))}>
                    {link._published ? <></> : <NotPublishedIcon />}
                    {link._icon ? <Icon icon={link._icon} /> : <></>}
                    <span className="titleText">{link.title}</span>
                </span>
            </div>
        }

        if (isExternalLinksSection && link?.link?.link) {
            return <div className={link._published ? "title" : "title notPublishedWrap"}>
                <a href={link.link.link} target="_blank" className="titleLink">
                    {link._published ? <></> : <NotPublishedIcon />}
                    {link._icon ? <Icon icon={link._icon} /> : <></>}
                    <span className="titleText">{link.title}</span>
                </a>
            </div>
        }

        if (!isExternalLinksSection || (isExternalLinksSection && !link?.link?.link)) {
            return <div className={link._published ? "title" : "title notPublishedWrap"}>
                <RouterLink to={link?._uri?.slice(1)} className="titleLink">
                    {link._published ? <></> : <NotPublishedIcon />}
                    {link._icon ? <Icon icon={link._icon} /> : <></>}
                    <span className="titleText">{link.title}</span>
                </RouterLink>
            </div>
        }

        return <></>
    }

    const linksRef = useRef(null);

    const skeletonItems = isMobile ? [1,2,3,4] : [1,2,3,4,5,6,7,8];
    if (showSkeleton) return <Grid className={blockClassName} container spacing={isUndefined(spacing) ? 2 : 0}>
        {skeletonItems.map(i => <Grid className="LinksTileItem LinksTileItemSkeleton" item xs={XsCellSize} md={MdCellSize} key={`preloader_${i}`}>
            <TileSkeleton src={src} />
        </Grid>)}
    </Grid>

    return <>
            {isMobile && filterBy?.length && <div className="MobileFiltersLine">
                <FiltersLine
                    size="small"
                    filters={filterBy}
                />
            </div>}

            <Grid className={blockClassName} container spacing={isUndefined(spacing) ? 2 : 0} ref={linksRef}>
                {links && sortedLinks.map(link => {
                    return <Grid className={`LinksTileItem ${src ? src : ''} ${link.isActive && "active"} ${!isEmptyObj(badges) && !badges[link?.data?._uri] && 'no_badge'}`} item xs={XsCellSize} md={MdCellSize} key={link?.data?._uri?.slice(1)} onClick={() => {}}>
                        {isExternalLinksSection && link?.data?.link?.link && <>
                            <div className='InlineActionsWrap'>
                            <div className="LinksTileImageWrap photoWrap">
                                <Badge color='error' badgeContent={badges?.[link?.data?._uri]}>
                                    <TilePicture
                                        badges={badges}
                                        href={link?.data?.link?.link}
                                        linkHandler={linkHandler}
                                        configs={configs}
                                        link={link}
                                        src={src}
                                        label={label}
                                        imgLoading={imgLoading}
                                        isExternalLinksSection={isExternalLinksSection}
                                    />
                                </Badge>
                                {label && <span className="photoLabel">{label(link.data)}</span>}
                            </div>

                            <NodeInlineActions permits={link.permits} node={link?.data} direction="up" city={city} />
                            </div>
                            {showTitle && renderTitle(link.data)}
                        </>}

                        {(!isExternalLinksSection || (isExternalLinksSection && !link?.data?.link?.link)) && <>
                            {/* {description && <div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />} */}
                            <div className='InlineActionsWrap'>
                            <div className="LinksTileImageWrap photoWrap">
                                <Badge color='error' badgeContent={badges?.[link?.data?._uri]}>
                                    <TilePicture
                                        badges={badges}
                                        href={link?.data?.link?.link}
                                        linkHandler={linkHandler}
                                        uri={link?.data?._uri}
                                        configs={configs}
                                        link={link}
                                        src={src}
                                        label={label}
                                        imgLoading={imgLoading}
                                        isExternalLinksSection={isExternalLinksSection}
                                    />
                                </Badge>
                                {label && <span className="photoLabel">{label(link.data)}</span>}
                            </div>

                            <NodeInlineActions permits={link.permits} node={link?.data} direction="up" city={city} />
                            </div>
                            {showTitle && renderTitle(link.data)}
                        </>}
                    </Grid>
                })}

                {sortedLinks.length === 0 && <Grid className="LinksTileItem" item xs={12}>
                    <div className="noResults">
                        {absentText || 'Нажаль, дані відсутні'}
                    </div>
                </Grid>}

                {showPagination && <Grid item xs={12}>
                    <Pagination pagination={links.pagination} />
                </Grid>}
            </Grid>
    </>
}
