import {useState} from 'react'

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import Links from '../../../../../../Widgets/Links'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture';

import './index.scss'

export default function Template(props) {
    const {content, permits, config, children, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    return <div className='Wiki'>
        <TopPagePicture cutHeightForDesktop image={content.banner}/>

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {/* showSkeleton={contentLoading} */}

        <CKEditorContent
            showSkeleton={contentLoading}
            content={content.description}
        />

        <Links
            showSkeleton={contentLoading}
            order={config.childrenOrder || content.childrenOrder}
            links={children}
        />
    </div>
}
