import {useEffect, useState} from "react";

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getFacebookLink, getInstagramLink} from 'ultra/helpers/messengers';
import {MESSENGER_COLORS} from 'ultra/const/messengers';
import {getMessengerLink} from 'ultra/helpers/messengers';

import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';

import Preloader from '../../../../../../../../../Components/Preloaders/Preloader';

import {getReaderInfo} from '../../../../../../../../../Helpers/library';

import Avatar from '../../../../../../../Widgets/Avatar' 
import Link from '../../../../../../../Widgets/Link'
import EmailButton from '../../../../../../../Widgets/EmailButton';
import BotNotificationButton from '../../../../../../../Widgets/BotNotificationButton';
import DefaultImage from '../../../../../../../Widgets/DefaultImage';
import TextZoomImage from '../../../../../../../Widgets/TextZoomImage';

import './input.scss';

export default function ReaderInfo(props) {
    const {content} = props;

    const [userInfo, setUserInfo] = useState();
    const [isLoading, setLoading] = useState();

    useEffect(() => {
        setLoading(true)
        const city = getClientCity(window) || DEFAULT_CITY;
        getReaderInfo(city, content._uri).promise
            .then(res => {
                setLoading(false)
                setUserInfo(res)
            })
    }, [])

    if (isLoading) {
        return <div className="ReaderInfo"><Preloader /></div>
    }

    if (!userInfo) {
        return <></>
    }

    return <div className="ReaderInfo">
            {/* <div className="UserInfo confirmed"> */}
            <div className="UserInfo">
                <Avatar image={userInfo?.photoURL}/>
            </div>

            <div className="actions">
                {userInfo?.pName && <div className="pname">
                    {userInfo?.pName}
                </div>}

                {userInfo?.facebook && <p className="labelWrap">
                    <span className="label">Facebook:</span>
                    <span className="labelValue">
                        <Link blank link={getFacebookLink(userInfo?.facebook)} />
                    </span>
                </p>}

                {userInfo?.instagram && <p className="labelWrap">
                    <span className="label">Instagram:</span>
                    <span className="labelValue">
                        <Link blank link={getInstagramLink(userInfo?.instagram)} />
                    </span>
                </p>}

                {/* {userInfo?.email && <p className="labelWrap">
                    <span className="label">Email:</span>
                    <span className="labelValue">
                        {userInfo.email}
                    </span>
                </p>} */}

                {userInfo?.deposit && <p className="labelWrap">
                    <span className="label">Завдаток:</span>
                    <span className="labelValue">
                        <span className="zoomTextWrapper pseudoLink">
                            <TextZoomImage
                                label="Скріншот"
                            >
                                <DefaultImage image={userInfo?.deposit} width="60" zoom />
                            </TextZoomImage>
                        </span>
                    </span>
                </p>}
            </div>

            <div className="buttons">
                {userInfo?.email && <EmailButton fullWidth label="Написати листа" email={userInfo?.email} />}
                {userInfo?.messenger?.telegram?.number && <Button
                    startIcon={<TelegramIcon sx={{color: MESSENGER_COLORS['telegram']}} />}
                    variant="outlined"
                    fullWidth
                    target="_blank"
                    href={getMessengerLink(userInfo?.messenger)}>
                        Написати
                </Button>}
                {userInfo?.telegram_id && <BotNotificationButton fullWidth id={userInfo?.telegram_id} />}
            </div>
    </div>
}
