import {hasFullAccess} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
// import {isYoursAccount} from '../../../../../../../Helpers/user';
// import NodeDetails from '../../../Components/NodeDetails'
import OrderActions from '../../../../../Widgets/OrderActions';
import {makeUrlsClickable} from '../../../../../../../Helpers/format';

export default function Template(props) {
    const {values, short} = props;

    // const {userState} = useGlobalCustomContext();

    // const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);
    // const fullAccess = hasFullAccess(userState?.user);

    // const initByCurrentUser = isYoursAccount(userState, values._init_by);

    // if (initByCurrentUser) {
    //     if (!values.details) {
    //         return <div className="NotificationTitle">
    //             Опубліковано
    //         </div>
    //     }
    //     if (values.details) {
    //         return <div className="NotificationTitle">
    //             <>Ви опубліковали </>
    //             <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
    //         </div>
    //     }
    // } 

    return <>
        {/* <div className="NotificationTitle"> */}
            <pre className='MessageText' dangerouslySetInnerHTML={{
                __html: makeUrlsClickable(
                    (short && values.details.message.replace(/\n/g, " ").length > 128)
                    ? `${values.details.message.replace(/\n/g, " ").substring(0, 128)}...`
                        : values.details.message
                )
            }}></pre>
        {/* </div> */}

        {/* <OrderActions details={values.details} /> */}
    </>
}
