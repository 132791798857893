import PreloaderTopThumbnail from '../PreloaderTopThumbnail'
import PreloaderText from '../PreloaderText'
import {isMobile} from "react-device-detect";

export default function PreloaderThumbnailNode() {
    const style1 = {}
    const style2 = {}
    if (!isMobile) {
        style1.float = 'left'
        style2.marginLeft = '280px'
    }

    if (isMobile) {
        return <>            
            <PreloaderTopThumbnail />

            <PreloaderText />
        </>
    }

    return <div style={{overflow: "hidden"}}>
        <div style={style1}>
            <PreloaderTopThumbnail />
        </div>
        <div style={style2}>
            <PreloaderText />
        </div>
    </div>
}
