export default function NodeReducer(state, action) {
    switch (action.type) {
        case ACTIONS.POPUPS_CLOSE:
            return {
                ...state,
                addPopupOpen: null,
                editPopupOpen: null,
                popupFormOpen: null,
                popupPageOpen: null,
                openUserMenu: null,
            }
    
        case ACTIONS.POPUP_FORM:
            return {
                ...state,
                openUserMenu: false,
                popupFormOpen: action.data
            };

        case ACTIONS.POPUP_PAGE:
            return {
                ...state,
                openUserMenu: false,
                popupPageOpen: action.data
            };

        case ACTIONS.ADD:
            return {
                ...state,
                openUserMenu: false,
                addPopupOpen: {
                    ...action.data
                }
            };

        case ACTIONS.EDIT:
            return {
                ...state,
                openUserMenu: false,
                editPopupOpen: action.data
            };

        case ACTIONS.DELETE_START:
            return {
                ...state,
                deletePopupOpen: action.data
            };

        case ACTIONS.DELETE_FINISHED:
            const deletedUrl = state.deletePopupOpen;

            return {
                ...state,
                deletePopupOpen: false,
                deletePopupFinished: deletedUrl
            };

        case ACTIONS.DELETE_CLEAN:
            return {
                ...state,
                deletePopupOpen: false,
                deletePopupFinished: false
            };
    
        case ACTIONS.VALIDATE:
            return {
                ...state,
                validateStart: action.data
            };

        case ACTIONS.VALIDATE_CLEANED:
            return {
                ...state,
                validateStart: null
            };

        case ACTIONS.UNVALIDATE:
            return {
                ...state,
                unvalidateStart: action.data
            };

        case ACTIONS.UNVALIDATE_CLEANED:
            return {
                ...state,
                unvalidateStart: null
            };

        case ACTIONS.RESTORE:
            return {
                ...state,
                restoreStart: action.data
            };

        case ACTIONS.RESTORE_CLEANED:
            
            return {
                ...state,
                restoreStart: null
            };

        case ACTIONS.UPDATE_PAGE_CONTENT_START:
            return {
                ...state,
                updatePageContentStart: true,
                // updatePageContentStartNotAuthorized: action.data?.not_authorized_content
            }

        case ACTIONS.UPDATE_PAGE_CONTENT_END:
            return {
                ...state,
                updatePageContentStart: false,
                // updatePageContentStartNotAuthorized: null
            }

        case ACTIONS.OPEN_USER_MENU:
            return {
                ...state,
                openUserMenu: true
            };

        case ACTIONS.CLOSE_USER_MENU:
            return {
                ...state,
                openUserMenu: false
            };

        default:
            return state
    }
}

const ACTIONS = {
    POPUP_FORM: 'node:popup:form',
    POPUP_PAGE: 'node:popup:page',
    ADD: 'node:add:open',
    EDIT: 'node:edit:open',
    POPUPS_CLOSE: 'node:popups:close',

    DELETE_START: 'node:delete:start',
    DELETE_FINISHED: 'node:delete:finished',
    DELETE_CLEAN: 'node:delete:clean',

    VALIDATE: 'node:validate:start',
    VALIDATE_CLEANED: 'node:validate:cleaned',

    RESTORE: 'node:restore:start',
    RESTORE_CLEANED: 'node:restore:cleaned',

    UNVALIDATE: 'node:unvalidate:start',
    UNVALIDATE_CLEANED: 'node:unvalidate:cleaned',

    UPDATE_PAGE_CONTENT_START: 'node:update:page:content:start',
    UPDATE_PAGE_CONTENT_END: 'node:update:page:content:end',

    OPEN_USER_MENU: 'node:open:user:menu',
    CLOSE_USER_MENU: 'node:close:user:menu',
}

export const NODE_ACTIONS = ACTIONS

export const createNodeInitialState = () => {
    return {
        popupFormOpen: null,
        popupPageOpen: null,
        openUserMenu: null,
        addPopupOpen: null,
        editPopupOpen: null,
        deletePopupOpen: null,
        validateStart: null,
        unvalidateStart: null,
        restoreStart: null,
        updatePageContentStart: null,
        // updatePageContentStartNotAuthorized: null,
    }
}
