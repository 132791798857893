import {isUndefined} from 'lodash';

import {useState, useEffect} from 'react';
import {useNavigate, useNavigation} from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import {useLocation} from "react-router-dom";
import {restorePath} from "ultra/helpers/route";
import {getUrl} from "ultra/configs/general";
import {getClientCityTimezone} from 'ultra/configs/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientConfig} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {NOTIFY, NOTIFICATION, NOTIFY_ORDERS, HISTORY_LIBRARY, HISTORY_NODE, HISTORY_USER, NOTIFY_NETWORKING} from "ultra/const/log";

import {getAuthUserID, isYoursAccount} from '../../../../Helpers/user';
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {getCurrentUrl} from '../../../../Helpers/content';

import {useConversationStore} from '../../../../Stores/conversation';
import {useConversationsStore} from '../../../../Stores/conversations';
import {useFullHeightContainerStore} from '../../../../Stores/fullHeightContainer';
import {useToasterStore} from '../../../../Stores/toster';
import {useNavigationStore} from '../../../../Stores/navigation';

import {NODE_ACTIONS} from '../../Reducers/node';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';
import {useConfigStore} from '../../../../Stores/config';

import Notification from '../../Widgets/Notification'

// import {getConversationInfo} from '../../../Profile/Helpers/conversations';

import './index.scss';

export default function UserNotify() {
    // props
    const [ws, setWS] = useState();
    const [notifyMessage, setNotifyMessage] = useState();

    const {configs} = useConfigStore();

    const navigate = useNavigate();
    const navigation = useNavigation();

    const {showSuccess} = useToasterStore();
    const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();
    const {addMessage, updateOrderStatus, updateSeenStatus} = useConversationStore();
    const {updateConversationNotReaded, updateConversation, addNewConversation} = useConversationsStore();

    const {startScrollTo} = useFullHeightContainerStore();

    useEffect(() => {
        // update connection on user login/logout
        getMessages();
    }, [userState?.user?.id])

    useEffect(() => {
        // update connection if it's lost
        if (navigation.state === 'idle') {
            getMessages();
        }
    }, [navigation])

    // // close ws on window close
    // window.onbeforeunload = function() {
    //     console.log('onbeforeunload')
    //     ws?.close()
    // }

    function isOpenWs(ws) {
        // ws.readyState != 2 && ws.readyState != 3
        return ws?.readyState == 1;
    }

    function onNotify(uri) {
        if (!uri) return;

        // startLoading();

        const city = getClientCity(window) || DEFAULT_CITY;

        if (uri.city === city) {
            if (uri.uri) {
                navigate(restorePath(uri.uri))
            }

            if (uri.url) {
                navigate(uri.url)
            }
        }
        else {
            const {domain} = getClientConfig(window);
            window.open(getUrl(domain, uri.city, restorePath(uri.uri)), "_self")
        }

        setNotifyMessage(false);
    }

    function getConversationUri(msg) {
        return {city: DEFAULT_CITY, uri: `/profile/messages/${msg.details._cid}`}
    }

    function getPageUri(msg) {
        if (!msg.details._did) return ''

        const url = new URL(getCurrentUrl());
        url.searchParams.set('goToDid', msg.details._city + ':' + msg.details._did)

        return {city: msg.details._city, url: url.pathname + url.search}
    }

    function showNotification(msg) { // txt
        let uri = getPageUri(msg)
        if (msg.details._cid) uri = getConversationUri(msg);

        let pre;
        if (msg._code === NOTIFICATION.MESSAGE) {
            pre = 'Нове повідомлення:'
        }

        setNotifyMessage(<span className='NotifyMessage' onClick={() => onNotify(uri)}>
            <span className='icon'>🔔</span>
            <Notification pre={pre} short code={msg._code} values={msg} />
            {/* {!txt && }
            {txt && <span>{txt}</span>} */}
        </span>);
    }

    function getMessages() {
        // connect if user present but no connection
        if (getAuthUserID(userState) && !isOpenWs(ws)) {

        const config = getClientConfig(window);
        console.log('ws.sub', config.notifications + "/" + getAuthUserID(userState))

        // WS
        const notifications = new WebSocket(config.notifications + "/" + getAuthUserID(userState))

        // clear keep alive connection if it's already present
        if (window._notify_interval) clearInterval(window._notify_interval);

        // keep alive connection (default alive - 60s)
        const ping_interval = 50 * 1000;
        window._notify_interval = setInterval(() => {
            console.log('ws.ping', window._notify_interval)

            if (notifications) {
                notifications.send(JSON.stringify({ping: 1}));
            } else {
                clearInterval(window._notify_interval);
            }
        }, ping_interval)

        notifications.addEventListener('close', () => {
            clearInterval(window._notify_interval);
        })

        setWS(notifications)

        function onNetworkRequesHandler(msg, short, callback) {
            showSuccess({
            snackbarMessage: <>
                <p>Ви отримали заявку на послуги з розділу нетворкінгу від {msg._init_by_name}</p>
                {/* <p>{msg.details.details}</p> */}
                {!short && <p>Деталі заявки можна дізнатись в розділі "Сповіщення"</p>}
            </>,
            snackbarDuration: 60000,
            onCloseHandler: () => {
                if (callback) callback();
                // navigate('/profile/messages');
            }
            })
        }

        function updateMessagesCounter() {
            userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
        }

        function reloadPage() {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
        }

        function isOrderPage(order) {
            const reg = new RegExp('\/profile\/history\/(.*)\/(.*)\/(.*)\/' + order);
            return reg.test(window.location.pathname);
        }

        function isConversationPage(cid) {
            return window.location.pathname.includes(`/profile/messages/${cid}`);
        }

        // function isWorker(msg) {
        //     return [
        //         HISTORY_NODE.WORKER_CREATE,
        //         HISTORY_NODE.WORKER_UPDATE,
        //         HISTORY_NODE.WORKER_REMOVE
        //     ].includes(msg._code)
        // }

        // function isLibrary(msg) {
        //     return [
        //         HISTORY_LIBRARY.CANCEL,
        //         HISTORY_LIBRARY.RESERVE,
        //         HISTORY_LIBRARY.RESERVE_NOT_APPROVED,
        //         HISTORY_LIBRARY.RESERVE_APPROVED,
        //         HISTORY_LIBRARY.RETURN,
        //         HISTORY_LIBRARY.RETURN_APPROVE
        //     ].includes(msg._code)
        // }

        function ignoreVisualNotify(msg) {
            return [
                NOTIFY_NETWORKING.REQUEST,
                HISTORY_USER.LOGOUT,
                NOTIFY.SEEN,
                NOTIFY.READALL,
                HISTORY_USER.BLOCK
            ].includes(msg._code)
        }

        function isCurrentPage(msg) {
            const page_did = document.getElementById('page_did')?.innerText;
            return !isUndefined(page_did) && msg?.details?._did === page_did; // && (msg._code === HISTORY_NODE.UPDATE || isLibrary(msg) || isWorker(msg))
        }

        function isNodeMoved(msg) {
            return [
                HISTORY_NODE.MOVE,
                HISTORY_NODE.RENAME,
                HISTORY_NODE.REMOVE
            ].includes(msg._code)
        }

        function isConversationMessages(msg) {
            return [
                NOTIFICATION.ACTION,
                NOTIFY_ORDERS.FORM_ORDER,
                NOTIFY_ORDERS.SHOP_ORDER,
                NOTIFY_ORDERS.ORDER_UPDATED,
                NOTIFICATION.MESSAGE,
                NOTIFICATION.FEEDBACK_REQUEST
            ].includes(msg?._code)
        }

        notifications.addEventListener("message", async event => {
            const msg = JSON.parse(event.data);

            // console.log(msg)

            if (!msg._code) return;

            const isConversationsPage = window.location.pathname === '/profile/messages' && window.location.search.length === 0;
            const selfNotify = isYoursAccount(userState, msg._init_by);

            const city = getClientCity(window) || DEFAULT_CITY;
            const timeZone = getClientCityTimezone(configs, city);

            // console.log(selfNotify)
            // !(isConversationMessages(msg) && selfNotify)
            if (
                !selfNotify
                ||
                (selfNotify && msg._code === NOTIFY.SEEN)
                ||
                (selfNotify && msg._code === NOTIFY.READALL)
                // ||
                // !ignoreVisualNotify(msg) 
                // ||
                // (selfNotify && isConversationMessages(msg))
            ) {
                updateMessagesCounter();
            }

            if (!ignoreVisualNotify(msg)) {
                if (
                    (!selfNotify || (selfNotify && document.hidden == true))
                    &&
                    !((isConversationsPage || isConversationPage(msg.details._cid)) && isConversationMessages(msg))
                ) {
                    showNotification(msg);
                }
            }

            if (isCurrentPage(msg)) {
                if (!isConversationsPage) {
                    if (!selfNotify || (selfNotify && document.hidden == true)) {
                        reloadPage()
                    }
                }
            }

            // all conversations page
            if (isConversationsPage) {
                if (msg?.details?.isNew) {
                    addNewConversation(msg.details.isNew);
                }
            }

            // networking
            if (msg._code === NOTIFY_NETWORKING.REQUEST) {
                onNetworkRequesHandler(msg, isConversationsPage);
            }

            // node moved
            else if (isNodeMoved(msg)) {
                if (!!selfNotify || (selfNotify && document.hidden == true)) {
                    const page_did = document.getElementById('page_did')?.innerText 
                    if (msg.details._did === page_did) {
                        if (msg._code === HISTORY_NODE.REMOVE) {
                            navigate('/');
                        } else {
                            navigate(msg.details._new_url);
                        }
                    }
                }
            }

            // conversation
            else if (msg?.details?._cid) {
                if (isConversationsPage && msg._code !== NOTIFY_ORDERS.ORDER_UPDATED) {
                    updateConversation(msg?.details?._cid, msg);
                }

                // if (msg?.details?._oid && msg._code === NOTIFY_ORDERS.ORDER_UPDATED && isOrderPage(msg.details._oid)) {
                //     console.log('updateOrderStatus')
                //     updateOrderStatus(msg.details._oid, msg.details.status, msg.details.actions);
                // }

                if (isConversationPage(msg.details._cid)) {

                    if (msg._code === NOTIFY_ORDERS.ORDER_UPDATED) { // && msg?.details?._oid && isOrderPage(msg.details._oid)
                        updateOrderStatus(msg.details._oid, msg.details.status, msg.details.actions);
                    }

                    // update conversation readed number
                    if (msg._seen_by) {
                        addMessage(msg, timeZone); //  userState?.user?.id,

                        if (msg._seen_by.includes(userState?.user?.id)) {
                            startScrollTo('end')
                        }
                    }

                    if (msg._code === NOTIFY.SEEN || msg._code === NOTIFY.READALL) {
                        if (msg.details?.messages) {
                            updateSeenStatus(msg.details.user, msg.details.messages, null, msg.details.firstNotReaded, timeZone)
                            // msg.details._not_readed
                        }
                        else if (isConversationsPage) {
                            updateConversationNotReaded(msg?.details?._cid, msg?.details?._not_readed);
                        }
                    }
                }
            }
        })}

        // close connection if no logged user
        if (!getAuthUserID(userState) && isOpenWs(ws)) {
            clearInterval(window._notify_interval);
            ws.close();
            setWS(null)
        }
    }

    function handleNotifyClose() {
        setNotifyMessage(null);
    }

    return <Snackbar
        className='ShortNotifications'
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={Boolean(notifyMessage)}
        message={notifyMessage}
        autoHideDuration={4000}
        onClose={handleNotifyClose}
        action={<CloseIcon onClick={handleNotifyClose} />}
    />
}
