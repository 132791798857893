import {isString} from 'lodash';

import FilterChip from '../FilterChip';

import './index.scss';

export default function FilterMultiChip(props) {
    const {values, config, className} = props;

    if (isString(values)) {
        console.error('should be array')
        return values;
    }

    return values?.map((d, i) => <span className='FilterMultiChip' key={`filter_multi_value_${d}`}>
        <FilterChip className={className} value={d} config={config} />
        {(i + 1) !== values.length && <>, </>}
    </span>)
}

