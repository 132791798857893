import React, {useEffect, useState} from 'react';

import {useLoaderData, useNavigation, useLocation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {hasFullAccess} from 'ultra/helpers/auth';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getCityConfig} from 'ultra/configs/general';

import {attachNodeImage} from '../../Helpers/user';
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {getNetworking} from '../../Helpers/networking';

import {useContentStore} from '../../../../Stores/content';
import {useConfigStore} from '../../../../Stores/config';

import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';

import {TableView} from '../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../Content/Widgets/Title';
// import FiltersLine from '../../../Content/Widgets/FiltersLine';
import {getActions, NodeTableInlineActions} from '../../../Content/Widgets/NodeInlineActions';

// import {NODE_ACTIONS} from '../../../Content/Reducers/node';
import { setPageTitle } from '../../../../Helpers/router';

import './index.scss';

const TITLE = 'Нетворкінг';

export default () => {
    const rawNodes = useLoaderData();

    const navigation = useNavigation();
    const {configs} = useConfigStore();
    const location = useLocation();

    const {nodeState, userState} = useGlobalCustomContext();
    const {content, setContent} = useContentStore();

    const [city] = useState(getClientCity(window) || DEFAULT_CITY);

    const [tableOptions, setTableOptions] = useState();
    const [fields, setFields] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    useEffect(() => {
        const fields = attachNodeImage(configs.content.nodes['networking'].fields);
        fields.title.placeholder = 'Користувач';
        fields.title.type = 'networking-link';

        setFields(fields);

        setTableOptions(isMobile ? {
            visible: ['thumbnail', 'title'],
            hidden: ['_created_by', 'region', 'direction', 'messenger', 'description']
        } : {
            visible: ['thumbnail', 'title', 'direction', 'region'],
            hidden: ['messenger', 'description']
        })

        setPermits({
            children: {
                delete: ['networking'],
                update: ['networking'],
                create: ['networking'],
                read: ['networking'],
                validate: ['networking'],
            }
        })

        setPageTitle(TITLE)
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            getNetworking(city, location.search)
                .promise
                .then(setContent)
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            setContent(rawNodes);
        }
    }, [navigation])

    useEffect(() => {
        let networkingCities = []
        if (configs?.content?.cities) {
            Object.keys(configs.content.cities).map(city => {
                if (!configs.content.cities[city].inProgress) networkingCities.push(city);
            });
        }

        const config = {
            showFilters: {
                filterBy: [
                    {
                        id: 'city',
                        field: {
                            type: "city",
                            list: networkingCities
                        }
                    },
                    {
                        id: 'nodes',
                        field: {
                            default: 'published',
                            type: "complex",
                            placeholder: "Статус",
                            options: {
                                hasNoEmptyValue: true,
                            },
                            values: {
                                values: {
                                        published: "Опубліковано",
                                        not_published: "Не опубліковано",
                                    }
                            }
                        }
                    },
                    {
                        id: 'direction',
                        field: {
                            type: "filter",
                            placeholder: "Тип",
                            source: "networking-directions",
                        }
                    }
                ]
            }
        }

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    const selectClasses = (data) => {}

    const title = {title: TITLE}

    const breadcrumbs = {
        '\\': {
            title: getCityConfig(configs, city).city || 'Головна'
        }
    }

    return <div  className='Content'>
            <div className='NetworkingPage'>
                <TitleWidget permits={permits} content={title} config={config} breadcrumbs={breadcrumbs} />
                {title?.title && <BreadcrumbsWidget links={breadcrumbs} />}

                {!fields && <PreloaderTable/>}

                {fields && content.children && <TableView
                    content={content.children}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    selectClasses={selectClasses}

                    actionsComponent={NodeTableInlineActions}
                    actionsProps={{
                        unfolded: isMobile,
                        city,
                    }}
                    hasActions={(data, id) => getActions({
                        permits: data.permits[id],
                        node: data.list[id],
                        hasFullAccess: hasFullAccess(userState.user)
                    })}
                />}
            </div>
        </div>
}
