import DocumentHistoryLink from '../../../../../Content/Widgets/DocumentHistoryLink'
// import {useGlobalCustomContext} from '../../../../../../Helpers/context';
// import {isYoursAccount} from '../../../../../../Helpers/user';
import DocumentTypeModified from '../DocumentTypeModified';
import DocumentLink from '../DocumentLink';

export default function NodeTextDetails(props) {
    const {node, values, fullAccess} = props;
    // const {userState} = useGlobalCustomContext();
    // const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    if (!values?.details?.title) return <></>;

    return <>
        {!node && <DocumentTypeModified details={values.details} />}
        {node && <> {node} </>}

        {fullAccess && values.details._city && <DocumentHistoryLink onlyText={!fullAccess} details={values.details} city={values.details._city} />}

        {!fullAccess && values?.details?._uri && values.details._city && <DocumentLink details={values.details} city={values.details._city}/>}
        {!fullAccess && !values?.details?._uri && <> {values?.details?.title}</>}
    </>
}