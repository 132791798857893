import {useState} from 'react';

import {isEmptyObj} from 'ultra/helpers/utils';
import {MESSENGER_COLORS} from 'ultra/const/messengers';
import {getMessengerLink, getMessengerType} from 'ultra/helpers/messengers';
import {isEmptyMessengerData} from 'ultra/helpers/utils';
import {isTgID} from "ultra/helpers/telegram";

import Button from '@mui/material/Button';

import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {getTelegramUser} from '../../../../Helpers/telegram';

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {getUserMessenger} from '../../../Profile/Helpers/user';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss'

const MESSENGERS = {
    'telegram': <TelegramIcon sx={{color: MESSENGER_COLORS['telegram']}} />,
    'whatsup': <WhatsAppIcon sx={{color: MESSENGER_COLORS['whatsup']}} />,
}

export default function MessageLink(props) {
    const {variant, size, label, fullWidth, messenger, id, onClick} = props; // contact
    const {userState} = useGlobalCustomContext();

    const {userDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader, showBackdropPreloader} = useBackdropPreloaderStore();

    const {showError, showPromt, closeToaster} = useToasterStore();

    const [telegram] = useState(getTelegramUser());

    const [hasNoMessageLink, setHasNoMessageLink] = useState(false);

    function handlerMessengerClick(messenger) {
        if (hasNoMessageLink) {
            showError({snackbarMessage: 'У користувача не заповнено поле месенджер, зверніться в службу підтримки'})
            return;
        }

        if (isEmptyMessengerData(messenger)) {
            setHasNoMessageLink(true)
            showError({snackbarMessage: 'У користувача не заповнено поле месенджер, зверніться в службу підтримки'})
        } else {
            setHasNoMessageLink(false)
            telegram ?
                window.location.assign(getMessengerLink(messenger))
                : window.open(getMessengerLink(messenger), '_blank');
        }
    }

    async function onMessage(e) {
        e.stopPropagation()

        if (onClick) onClick()

        if (isTgID(id)) {
            telegram ?
                window.location.assign(getMessengerLink(id))
                : window.open(getMessengerLink(id), '_blank');
            return;
        }

        if (userState?.user && !isEmptyObj(userState?.user)) {
            if (isEmptyMessengerData(messenger)) {
                showBackdropPreloader()

                getUserMessenger(id).promise
                    .then(messenger => {
                        handlerMessengerClick(messenger);
                        hideBackdropPreloader();
                    })
                    .catch((e) => {
                        showError({snackbarMessage: 'Виникла помилка під час запиту контактів, зверніться в службу підтримки'})
                        hideBackdropPreloader();
                    })
            }
            else {
                handlerMessengerClick(messenger)
            }
        }
        else {
            showPromt({
                snackbarMessage: 'Щоб мати можливість написати повідомлення вам потрібно авторизуватись',
                onApproveHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();

                    userDispatch({type: USER_ACTIONS.LOGIN})
                },
                onCloseHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();
                }
            })
        }
    }

    if (variant === 'button') {
        if (!MESSENGERS[getMessengerType(messenger)]) {
            return <></>
        }

        return  <Button
                    startIcon={MESSENGERS[getMessengerType(messenger)]}
                    variant="outlined"
                    fullWidth={fullWidth}
                    size={size}
                    className="UserMessageLink"
                    onClick={onMessage}>
                        {label}
                </Button>
    }

    return <span className="UserMessageLink pseudoLink" onClick={onMessage}>
        {label}
    </span>
}
