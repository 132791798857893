import React from 'react';
import {signOut} from "firebase/auth";
import {useLoaderData, useNavigate} from "react-router-dom";
import {sendPasswordResetEmail} from "firebase/auth";
import {useLocation} from "react-router-dom";
import {revertPath} from 'ultra/helpers/route';

import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";
import {getClientCity} from 'ultra/configs/general';

import LogoutIcon from '@mui/icons-material/Logout';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PasswordIcon from '@mui/icons-material/Password';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {useNavigationStore} from '../../../../Stores/navigation';
import {useFirebaseStore} from '../../../../Stores/firebase';

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import PreloaderForm from '../../../../Components/Preloaders/PreloaderForm';
import UserLibraryList from '../../Components/UserLibraryList';
import UserSingeltonesList from '../../Components/UserSingeltonesList';
import UserNodesList from '../../Components/UserNodesList';
import Avatar from '../../../Content/Widgets/Avatar';

import {getNodeContent} from '../../../../Helpers/content';
import {userHasAllNodes} from '../../../../Helpers/user';
// import MessengerValue from '../../../Content/Widgets/MessengerValue';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {saveUserHistoryAndNotify} from '../../../Profile/Helpers/user';
import {userLogout} from '../../../Profile/Helpers/user';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss';

export default () => {
    const {contentLoading} = useNavigationStore()
    const {auth} = useFirebaseStore();

    const navigate = useNavigate();
    const user = useLoaderData();
    const location = useLocation();

    const {userState, userDispatch} = useGlobalCustomContext();

    const {showSuccess, showError} = useToasterStore();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

    if (contentLoading || !user) return <PreloaderForm />

    function onGoBack() {
        navigate('/')
    }

    const onEditProfile = () => {
        userDispatch({type: USER_ACTIONS.PROFILE_EDIT})
    }

    // TODO: make one function / has duplicate
    const onChangePassword = () => {
        if (userState?.user.emailNotVerified) {
            showError({
                snackbarMessage: 'Для зміни паролю, необхідно підтвердити ваш email',
            })
            return;
        }
    
        showBackdropPreloader();
    
        // TODO: make similar to email validation
            // save flag
            // and move logs and history to backend

        if (!userState?.user?.email) return;

        sendPasswordResetEmail(auth, userState.user.email)
            .then(async () => {
                showSuccess({
                    snackbarMessage: 'На вашу електронну адресу було направлено посилання для зміни паролю',
                });
    
                await saveUserHistoryAndNotify(userState.user.email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS);
            })
            .catch(async err => {
                console.log(err)
                if (err.code === 'auth/invalid-email') {
                    showError({
                        snackbarMessage: `${userState.user.email} - не коректний email`
                    });
                } else {
                    showError({
                        snackbarMessage: 'Виникла помилка, спробуйте пізніше'
                    });
                }
    
                await saveUserHistoryAndNotify(userState?.user?.email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_ERROR, err);
            })
    }

    const signOutHandler = () => {
        showBackdropPreloader();
    
        // TODO: make one function / has duplicate
        userLogout()
          .promise
          .then(async () => {
              signOut(auth);
    
              userDispatch({type: USER_ACTIONS.USER_REMOVE});
    
              hideBackdropPreloader();
    
              const isProfilePage = location.pathname.indexOf('/profile') === 0
    
              if (isProfilePage) {
                navigate('/');
              }
              else {
                const city = getClientCity(window);
                
                try {
                  await getNodeContent({
                    city,
                    uri: location.pathname // revertPath()
                  }).promise
    
                  navigate(location.pathname + location.search);
                }
                catch (e) {
                  navigate('/');
                }
              }
    
              window.localStorage.removeItem("verify-email-msg-displayed");
          })
          .catch(() => {
            userDispatch({type: USER_ACTIONS.USER_REMOVE});
          })
    }

    return <div className='ProfilePage'>
        <Avatar image={user?.photoURL} roles={user?.roles} />

        <div className="userDetails">
            <div className="pageTopTitle">
                <div className="pageTopTitleText">                    
                    <ChevronLeftIcon className="goBack" onClick={onGoBack} />

                    <span className='pageTitle'>
                        {user?.displayName}
                    </span>
                </div>
            </div>
            {user?.email && <div className='userEmail'>
                {user.email}
            </div>}

            {/* <div className='actionButtons'> */}
                {/* <NotificationsIcon alt="Сповіщення / Підписка" /> */}
                {/* <BookmarkIcon alt="Обране" /> */}
                {/* <EmailIcon alt="Повідомлення" /> */}
            {/* </div> */}
            
            {/* <div className='userDetails'>
                {user?.facebook && <p className='labelWrap'>
                    <span className="label">Facebook:</span>
                    <span className="labelValue">
                        <Link blank link={getFacebookLink(user?.facebook)} />
                    </span>
                </p>}
                {user?.instagram && <p className='labelWrap'>
                    <span className="label">Instagram:</span>
                    <span className="labelValue">
                        <Link blank link={getInstagramLink(user?.instagram)} />
                    </span>
                </p>}
            </div> */}

            {/* {!isEmptyMessengerData(user?.telegram) && <p className='labelWrap'>
                <span className="label">telegram:</span>
                <span className="labelValue">
                    <Link blank link={getTelegramLink(user?.telegram?.number)} />
                </span>
            </p>}
            {!isEmptyMessengerData(user?.whatsup) && <p className='labelWrap'>
                <span className="label">whatsup:</span>
                <span className="labelValue">
                    <Link blank link={getWhatsupLink(user?.whatsup.number)} />
                </span>
            </p> */}
        </div>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className='UserActions'>
                    <DriveFileRenameOutlineOutlinedIcon  onClick={onEditProfile} />
                    <PasswordIcon onClick={onChangePassword} />
                    <LogoutIcon onClick={signOutHandler} color="error"/>
                </div>
            </Grid>

            {userHasAllNodes(user?.nodes) > 0 && <Grid item xs={12}>
                <div className='labelWrap labelWrapRow'>
                    <span className='label'>Контент:</span>
                    <span className='labelValue'>
                        <UserNodesList list={user?.nodes?.all}/>
                    </span>
                </div>
            </Grid>}
            {Object.keys(user?.nodes?.singleton).length > 0 && <Grid item xs={12}>
                <div className='labelWrap labelWrapRow'>
                    <span className='label'>Singleton:</span>
                    <span className='labelValue'>
                        <UserSingeltonesList list={user?.nodes?.singleton}/>
                    </span>
                </div>
            </Grid>}
            {user?.library?.length > 0 && <Grid item xs={12}>
                <div className='labelWrap labelWrapRow'>
                    <span className='label'>Бібліотека:</span>
                    <span className='labelValue'>
                        <UserLibraryList list={user?.library} />
                    </span>
                </div>
            </Grid>}
        </Grid>
    </div>
}