import Grid from '@mui/material/Grid';
import {getPartnerDetails} from 'ultra/helpers/shop';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import PreloaderThumbnailNode from '../../../../../../../../Components/Preloaders/PreloaderThumbnailNode';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import ProductOptions from '../../../../../../Widgets/ProductOptions'
import ProductAddons from '../../../../../../Widgets/ProductAddons'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
// import ShopProductPrices from '../../../../../../Widgets/ShopProductPrices'
import FilterChip from '../../../../../../Widgets/FilterChip'
import Gallery from '../../../../../../Widgets/Gallery';

import './index.scss'
import { isMobile } from 'react-device-detect';

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore();

    const {node} = getPartnerDetails(content, breadcrumbs)

    return <div className="Product">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {contentLoading && <PreloaderThumbnailNode />}

        {!contentLoading && <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <div className='photoWrap'>
                    <ContentImage src="thumbnail" image={content.thumbnail} className="photo" />
                    {content.status && <div className="photoLabel">
                        <FilterChip value={content.status} config={configs.content.nodes['shop-product'].fields.status} />
                    </div>}
                </div>
                <Gallery className='galleryWrap' content={content.gallery} perView={2} />
            </Grid>
            <Grid item xs={12} md={6}>
                {/* currency={node?.partner?.config?.currency}  */}
                {isMobile && <>
                    <ShopProductPrice product={content} />
                    <ShopingCart
                        item={content}
                        shop={node}
                        fullWidth
                    />
                </>}

                {!isMobile && <div className='productOrder'>
                    <ShopProductPrice product={content} />

                    <ShopingCart
                        item={content}
                        shop={node}
                        hideText={true}
                    />
                </div>}
                
                {!content.available && <div className='notAvailable'>Тимчасово недоступно</div>}

                {/* <ShopProductPrices product={content} /> */}

                <CKEditorContent content={content.description} />

                <ProductOptions item={content}/>

                <ProductAddons item={content}/>
            </Grid>
        </Grid>}
    </div>
}
