import { NavLink as RouterLink } from "react-router-dom";

import Chip from '@mui/material/Chip';
// import {CurrencyFormat} from 'ultra/helpers/utils';
import {totalPrice} from "ultra/helpers/shop";

import OrderActions from '../../../../OrderActions';
import OrderDetails from '../../../Components/OrderDetails';
// import RequestTitle from '../../../Components/RequestTitle';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

import './index.scss'

export default function Template(props) {
    const {code, values, short} = props;

    const {userState} = useGlobalCustomContext();
    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (!values) return <></>

    if (!values?.details?.data) {
        return <>
            <div className='NotificationTitle'>
                Нове замовлення
                &nbsp;
                <RouterLink to={`/profile/history/shop/${values?.details?.partner}/${values?.details?.shop}/${values?.details?._oid}`}>{values?.details?._oid}</RouterLink>

                &nbsp;
                (
                    {values?.details?.partner}
                    :
                    {values?.details?.shop}
                )
            </div>
        </>
    }

    if (initByCurrentUser) {
        if (short) {
            return <>
                <div className='NotificationTitle'>Ви оформили нове замовлення</div>
            </>
        }
        else {
            return <>
                    {values?.details?.data?.order && <div className='OrderTitle'>
                        <div className='text'>
                            🛍️ Нове замовлення на суму {totalPrice(values.details.data.order)}
                        </div>
                    </div>}

                    <div className='orderId'>#{values?.details?._oid}</div>

                    <OrderDetails details={values.details}/>

                    <OrderActions details={values.details} />
                </>
        }
    }

    if (short) {
        return <>
            <div className='NotificationTitle'>Нове замовлення</div>
        </>
    }
    else {
        return <>
            {values?.details?.data?.order && <div className='OrderTitle'>
                <div className='text'>
                    🛍️ Нове замовлення на суму {totalPrice(values.details.data.order)}
                </div>
                {values?.details?.status_label && 
                    <span className="Label"><Chip variant="outlined" label={values?.details?.status_label} /></span>
                }
            </div>}

            <div className='orderId'>#{values?.details?._oid}</div>

            <OrderDetails details={values.details}/>

            <div className="NotificationActions">
                <OrderActions  details={values.details} />
            </div>
        </>
    }
}
