import {useRouteError} from "react-router-dom";

import AppLayout from '../AppLayout';

import GlobalCustomContext from '../../../../Helpers/context'

import PreloaderLinksBlocks from '../../../../Components/Preloaders/PreloaderLinksBlocks';

import {useNavigationStore} from '../../../../Stores/navigation';

import ErrorPageContent from './ErrorPageContent';

import './index.css';
import { useEffect } from "react";

function ErrorPage() {
  let error = useRouteError();

  const providerValue = {
    nodeState: {},
    nodeDispatch: () => {},
    userState: {},
    userDispatch: () => {},
  }

  useEffect(() => {
    document.body.classList.add('error_page');
  }, [])

  return (
    <GlobalCustomContext.Provider value={providerValue}>
    <AppLayout configs={{}}>
      <div className="Content">
        <ErrorPageContent error={error} />
      </div>
    </AppLayout>
    </GlobalCustomContext.Provider>
  );
}

export default ErrorPage;
