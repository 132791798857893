import React, {useEffect, useState} from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {useLoaderData, useNavigation, useParams, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";

import {getFacebookLink, getInstagramLink} from 'ultra/helpers/messengers';

import {setPageTitle} from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';

import {useConfigStore} from '../../../../../Stores/config';
import {useContentStore} from '../../../../../Stores/content';

import PreloaderTable from '../../../../../Components/Preloaders/PreloaderTable';

import UserInlineActions from '../../../Components/UserInlineActions'
import UserNodesList from '../../../Components/UserNodesList'
import UserSingeltonesList from '../../../Components/UserSingeltonesList'
import UserLibraryList from '../../../Components/UserLibraryList'
import UserAdminActions from '../../../Components/UserAdminActions'

import {TableView} from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import UserMessageLink from '../../../../Content/Widgets/UserMessageLink';
import Link from '../../../../Content/Widgets/Link'
import Avatar from '../../../../Content/Widgets/Avatar';

// import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

// import {USER_ACTIONS} from '../../../../Content/Reducers/node';

import {userHasAllNodes} from '../../../../../Helpers/user';
import {getUserHistory} from '../../../Helpers/user';

import './index.scss';

export default () => {
    const rawNodes = useLoaderData();

    let {id} = useParams();

    const navigation = useNavigation();
    const location = useLocation();

    const {configs} = useConfigStore();
    const {content, setContent} = useContentStore();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    // const {hideBackdropPreloader} = useBackdropPreloaderStore();
    const {userState} = useGlobalCustomContext();

    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _code: {
            type: 'notification',
            placeholder: 'Тип'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор'
        },
        details: {
            type: 'history-details',
            placeholder: 'Деталі'
        }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_code'],
            hidden: ['details'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_code'],
            hidden: ['details'],
            noHeaders: true,
            unfolded: true,
        })

        setPermits({})

        setPageTitle('Історія користувача')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            // nodeDispatch({type: USER_ACTIONS.UPDATE_PAGE_CONTENT_END});

            getUserHistory(id, location.search)
                .promise
                .then(setContent)
        }

        // if (nodeState.userRefreshStarted) {
        //     const reqParams = {...nodeState.restoreStart}

        //     showBackdropPreloader();
        //     nodeDispatch({type: USER_ACTIONS.USER_REFRESH_STOP})

        //     restoreDocument(city, did, reqParams)
        //         .then(() => {
        //             nodeDispatch({type: USER_ACTIONS.UPDATE_PAGE_CONTENT_START})
        //         })
        //         .catch((err) => {
        //             showError({snackbarMessage: err.message})
        //         })
        // }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    useEffect(() => {
        if (navigation.state === 'idle') {
            setContent(rawNodes);
        }
    }, [navigation])

    const selectClasses = (data) => {
        return data?._code
            ? 'tr_' + data?._code.replace(':', '_')
            : '';
    }

    const title = {
        title: content?.page?.content?.displayName || id
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\users'] = {title: "Користувачі"}

    return <div  className='Content'>
        <div className='HistoryUserAdminPage'>
            {<TitleWidget permits={permits} content={title} config={config} breadcrumbs={breadcrumbs} />}
            {<BreadcrumbsWidget hideRoot links={breadcrumbs} />}

            {isMobile && <div className='MobileFiltersLine'>
                <FiltersLine
                    size="small"
                    filters={config?.showFilters?.filterByInline}
                />
            </div>}

            {(!fields) && <PreloaderTable/>}

            {!content?.page?.content && <div className='HistoryUserAdminPageDetails'>
                Дані користувача відсутні
            </div>}

            {content?.page?.content && <div className='HistoryUserAdminPageDetails'>

                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <div className='UserCard UserInfo'>
                            <Avatar image={content?.page?.content?.photoURL}/>

                            <div className='Details'>
                                <UserMessageLink variant="button" label="Написати" fullWidth id={id}/>
                            </div>
                        </div>
                        <Grid container spacing={1}>
                            {(content?.page?.content?._blocked || content?.page?.content?._blocked) && <Grid item xs={12}>
                                <div className='labelWrap'>
                                    <span className='labelValue'>
                                        {content?.page?.content?._blocked && <Chip variant='outlined' className='errorLabel' label={content?.page?.content?.__blocked_comment ? `Заблокований: ${content?.page?.content?.__blocked_comment}` : `Заблокований`} />}
                                        {content?.page?.content?._not_checked && <Chip variant='outlined' className='errorLabel' label="Проходить перевірку" />}
                                    </span>
                                </div>
                            </Grid>}
                            <Grid item xs={12}>
                                <div className='labelWrap'>
                                    <span className='label'>ID:</span>
                                    <span className='labelValue'>
                                        {id}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {content?.page?.content?.roles && <div className='labelWrap'>
                                    <span className='label'>Ролі:</span>
                                    <span className='labelValue'>
                                        {content?.page?.content?.roles.map(r => <Chip key={r} variant='outlined' label={r} />)}
                                    </span>
                                </div>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className='labelWrap'>
                                    <span className='label'>email:</span>
                                    <span className='labelValue'>
                                        {content?.page?.content?.email}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='labelWrap'>
                                    <span className='label'>facebook:</span>
                                    <span className='labelValue'>
                                        <Link blank link={getFacebookLink(content?.page?.content?.facebook)} />
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='labelWrap'>
                                    <span className='label'>instagram:</span>
                                    <span className='labelValue'>
                                        <Link blank link={getInstagramLink(content?.page?.content?.instagram)} />
                                    </span>
                                </div>
                            </Grid>
                            {userHasAllNodes(content?.page?.content?.nodes) > 0 && <Grid item xs={12}>
                                <div className='labelWrap labelWrapRow'>
                                    <span className='label'>Контент:</span>
                                    <span className='labelValue'>
                                        <UserNodesList list={content?.page?.content?.nodes?.all}/>
                                    </span>
                                </div>
                            </Grid>}
                            {content?.page?.content?.nodes?.singleton
                                && Object.keys(content.page.content.nodes.singleton).length > 0
                                && <Grid item xs={12}>
                                    <div className='labelWrap labelWrapRow'>
                                        <span className='label'>Singleton:</span>
                                        <span className='labelValue'>
                                            <UserSingeltonesList list={content?.page?.content?.nodes?.singleton}/>
                                        </span>
                                    </div>
                            </Grid>}
                            {content?.page?.content?.library?.length > 0 && <Grid item xs={12}>
                                <div className='labelWrap labelWrapRow'>
                                    <span className='label'>Бібліотека:</span>
                                    <span className='labelValue'>
                                        <UserLibraryList list={content?.page?.content?.library} />
                                    </span>
                                </div>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                {/*                     
                // {!isEmptyMessengerData(userInfo.telegram) && <div className="labelWrap">
                //     <span className="label">Telegram:</span>
                //     <span className="labelValue">
                //         <Link blank link={getTelegramLink(userInfo.telegram.number)} />
                //     </span>
                // </div>}
                // {!isEmptyMessengerData(userInfo.whatsup) && <div className="labelWrap">
                //     <span className="label">WhatsUp:</span>
                //     <span className="labelValue">
                //         <Link blank link={getWhatsupLink(userInfo.whatsup.number)} />
                //     </span>
                // </div>}
                // */}
            </div>}

            {content?.page?.content?.id && <UserInlineActions showCleanHistory user={content?.page?.content} unfolded={!isMobile} className="CurrentPageInlineActions" hideHistory/>}

            {content.children && fields && <TableView
                content={content.children}
                fields={fields}
                showPagination={true}
                tableOptions={tableOptions}
                selectClasses={selectClasses}
            />}
        </div>
        <UserAdminActions />
    </div>
}
