import React, {useState, useEffect} from 'react';

import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';

import {useGlobalCustomContext} from '../../../Helpers/context'

export default function Partner(props) {
    const {content, disabled, field, isEditMode, onChange} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => { setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const {userState} = useGlobalCustomContext();

    const onValueChange = (e) => {
        const parts = e.target.value.split('---')
        const data = {id: parts[1]}

        if (parts[0] === 'form') data.form = parts[2]
        if (parts[0] === 'shop') data.shop = parts[2]

        onChange({target: {value: data}})
    }

    // useEffect(() => {
    // }, [])

    if (!userState?.user?.partnership) return <div>{field?.placeholder}: дані відсутні</div>

    const selectedValue = content[field.id]?.form ?
    `form---${content[field.id]?.id}---${content[field.id]?.form}`
        : content[field.id]?.shop
            ? `shop---${content[field.id]?.id}---${content[field.id]?.shop}` : '';

    return (<Grid key={field?.id} item xs={12}>
        <FormControl disabled={isDisabled} fullWidth className='Partner'>
            <InputLabel>{field?.placeholder}</InputLabel>
            <Select
                defaultValue=''
                value={selectedValue}
                onChange={onValueChange}
            >
                    {userState?.user?.partnership?.forms.map(form => <MenuItem key={`form---${form.partner}---${form.form}`} value={`form---${form.partner}---${form.form}`}>
                        {userState?.user?.partnership?._info[form.partner].title}: Форма: {form.title}
                    </MenuItem>)}
                    {userState?.user?.partnership?.shops.map(shop => <MenuItem key={`shop---${shop.partner}---${shop.shop}`} value={`shop---${shop.partner}---${shop.shop}`}>
                        {userState?.user?.partnership?._info[shop.partner].title}: Магазин: {shop.title}
                    </MenuItem>)}
            </Select>
        </FormControl>
    </Grid>)
}
