import { create } from 'zustand'

export const useActionsStore = create((set) => ({
  actionId: null,
  setActionId: (did) => set(() => {
    return {actionId: did};
  }),
  cleanActionId: () => set(() => {
    return {actionId: null};
  })
}))
