import DOMPurify from "dompurify"

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import PreloaderText from '../../../../../../../../Components/Preloaders/PreloaderText';

import LinksTile from '../../../../../../Widgets/LinksTile'
import LinksTileWithCategories from '../../../../../../Widgets/LinksTileWithCategories'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TitleWidget from '../../../../../../Widgets/Title'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    if (contentLoading) return <div className='Shop'>
        {/* <PreloaderTopPagePicture /> */}
        <LinksTile
            src="thumbnail"
            xs={6} md={3}
            showSkeleton={true} />
        <PreloaderText />
    </div>

    return <div className='ShopsTile'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {(!hasCategories && !config.categories) && children.order.length > 0 && <LinksTile
            imgLoading={config?.templateOptions?.imgLoading}

            showFilters={config?.showFilters || content?.showFilters}
            xs={6} md={3}
            links={children}
            src="thumbnail" />}

        {(hasCategories || config.categories) && children.order.length > 0 && <LinksTileWithCategories
            xs={6} md={3}
            permits={permits}
            
            categoriesConfig={config.categories}
            showFilters={config?.showFilters || content?.showFilters}
            links={children}
            src="thumbnail" />}
        
        {content.description && <div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.description) }} />}
    </div>
}
