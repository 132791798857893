import {isMobile} from "react-device-detect";

import React, {useEffect, useState} from 'react';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react'

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getClientEnv} from 'ultra/configs/general';
import {getClientImageUrl} from 'ultra/configs/general';

import {useToasterStore} from '../../../Stores/toster';
import {useGlobalCustomContext} from '../../../Helpers/context';

import ContentUploadPlugin from './ContentUploadPlugin';

import './index.scss'

export default function CKEditorControl(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText, className, formActions} = props;
    const {showSuccess, showError} = useToasterStore();

    const {userState} = useGlobalCustomContext();

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    // const [preloading, setPreloading] = useState();
    const [value, setValue] = useState();
    const [editorConfiguration, setEditorConfiguration] = useState(null);

    useEffect(() => {
        const env = getClientEnv(window);
        const city = content._city || getClientCity(window) || DEFAULT_CITY;
        const path = getClientImageUrl(env, city, '');
        if (content[field.id]) {
            setValue(content[field.id]?.replaceAll('src="', `src="${path}`))
        }

        const extraPlugins = []

        extraPlugins.push(function FileUpload(editor) {
            if (userState?.user) {
                ContentUploadPlugin(editor, field.id, content._did, content.title, content._type, content._created_by, formActions);    // !!!! , user?.accessToken
            }
        })
    
        extraPlugins.push(function Notifications(editor) {
            const notifications = editor.plugins.get('Notification');
            notifications.on('show:warning', (evt, _data) => {
                const message = {
                    snackbarMessage: _data.title + ': ' + _data.message,
                    snackbarDuration: 10000
                };
    
                if (_data.type === 'warning') {
                    showError(message);
                } else {
                    showSuccess(message);
                };
    
                evt.stop();
            });
        });
    
        let items = [
            'undo', 'redo', 'heading',
            '|', 'bold', 'italic',
            '|', 'bulletedList', 'numberedList',
            '|', 'link',
            '|'
        ]

        if (userState?.user) items.push('imageUpload')
    
        if (field?.options?.extended) {
            items = [
                'undo', 'redo', 'heading',
                '|', 'alignment', 'bold', 'italic',
                'bulletedList', 'numberedList',
                '|', 'link'
            ]
        
            if (userState?.user) items.push('imageUpload')
        
            items.push('insertTable')
            items.push('|')
            items.push('blockQuote')            
        }

        if (field?.options?.noToolbar) {
            items = []
        }

        if (isMobile) {
            items = []
        }

        setEditorConfiguration({
            toolbar: {
                items,
                // shouldNotGroupWhenFull: true
            },
            mediaEmbed: {
                removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook' ]
            },
            image: {
                resizeUnit: 'px'
            },
            placeholder: field.placeholder ? field.placeholder + (field.required ? ' *' : '') : undefined,
            extraPlugins,
        });
    }, [])

    if (!editorConfiguration) {
        return <></>
    }

    return <>
        <div className={'CKEditor' + (className ? ' ' + className : '') + (showErrorText ? ' error' : '') + (isDisabled ? ' disabled' : '') + (editorConfiguration?.toolbar?.items.length === 0 ? ' noPanel' : '')}>
            {/* {preloading && <div className="PreloaderWrap"><Preloader /></div>} */}
            <CKEditor
                editor={Editor}
                disabled={isDisabled}
                config={editorConfiguration}
                data={value}
                onChange={(e, editor ) => {
                    const value = editor.getData();
                    setValue(value);
                    onChange({target: {value}});
                }}
            />
            {isDisabled && !value && <div className='disabledLabel'>{editorConfiguration.placeholder}</div>}
            {showErrorText && <div className='fieldError'>
                {showErrorText}
            </div>}

            {/* {isEmptyObj(user) && <p className='fieldNotes'>* Завантажити картинку може лише <RouterLink to="/profile/login">авторизований користувач</RouterLink></p>} */}
        </div>
    </>
}
