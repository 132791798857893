import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientConfig, getClientImageUrl, getClientCity, getClientEnv} from 'ultra/configs/general';

// import {uploadFile} from '../../../../Helpers/firestore'

// function uploadAdapter(loader, fieldName, path, token) {
//     return {
//       upload: async () => {
//         const file = await loader.file;
//         return uploadFile(file, fieldName, path, token)
//       },
//     };
// }

class UploadAdapter {
  // The file loader instance to use during the upload.
  constructor(loader, fieldName, did, title, type, createdBy, callbacks) { // token
    this.loader = loader;
    this.fieldName = fieldName;
    this.did = did;
    this.type = type;
    this.createdBy = createdBy;
    this.title = title;
    this.callbacks = callbacks;
  }

  upload() {
    return this.loader.file
      .then( file => new Promise((resolve, reject) => {
          if (!this.did) {
            reject('Сторінка має мати вказаний ID');
            return;
          }

          if (this.callbacks.disable) {
            this.callbacks.disable()
          }

          this._initRequest();
          this._initListeners( resolve, reject, file );
          this._sendRequest(file, this.did, this.title, this.fieldName, this.type, this.createdBy);
      }));
  }

  // Aborts the upload process.
  abort() {
    if ( this.xhr ) {
        this.xhr.abort('New upload');
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    const {api} = getClientConfig(window);
    const city = getClientCity(window) || DEFAULT_CITY;
    xhr.open( 'POST', `${api}/content/${city}/utils/upload/ckeditor`, true );
    xhr.withCredentials = true;
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Не можливо завантажити файл: ${file.name}.`;

    xhr.addEventListener('error', () => {
      if (this.callbacks.enable) this.callbacks.enable();
      reject(genericErrorText)
    });

    xhr.addEventListener('abort', () => {
      if (this.callbacks.enable) this.callbacks.enable();
      reject()
    });

    xhr.addEventListener('load', () => {
      if(xhr.status !== 200) {
        if (this.callbacks.enable) this.callbacks.enable();
        reject(xhr?.response?.error?.message);
        return;
      }
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
          if (this.callbacks.enable) this.callbacks.enable();
          return reject(response && response.error ? response.error.message : genericErrorText);
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      const url = response.success[this.fieldName]
      if (!url) {
          if (this.callbacks.enable) this.callbacks.enable();
          reject(response.errors[this.fieldName])
      } else {
          if (this.callbacks.enable) this.callbacks.enable();
          const env = getClientEnv(window);
          const city = getClientCity(window) || DEFAULT_CITY;
          resolve({default: getClientImageUrl(env, city, url)});
      }
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      })
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file, did, title, fieldName, type, createdBy) {
    // Prepare the form data.
    const data = new FormData();

    data.append('title', title);
    data.append('did', did);
    data.append('type', type);
    data.append('createdBy', createdBy);
    // data.append('token', this.token);
    data.append(fieldName, file, file.name);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

export default function ContentUploadPlugin(editor, fieldName, did, title, type, createdBy, callbacks) { // token
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, fieldName, did, title, type, createdBy, callbacks);  // token
    };
}
