import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {DEFAULT_CITY} from 'ultra/const/general';
import {hasFullAccess} from 'ultra/helpers/auth';
import {getClientCity} from 'ultra/configs/general'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {cleanNodeHistory} from '../../../Profile/Helpers/history';
import {NODE_ACTIONS} from '../../Reducers/node';

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useContentStore} from '../../../../Stores/content';
import {useSearchStore} from '../../../../Stores/search';

import InlineActions from '../InlineActions';

import './index.scss';

export function getActions(options) {
    const {permits, node, hideHistory, showCleanHistory, hasFullAccess} = options;

    const hasEditAction = permits?.update;
    const hasValidateAction = permits?.validate;
    const hasDeleteAction = permits?.delete;

    const actions = [];

    if (!node) return actions;

    if (hasEditAction) {
        actions.push('edit')
    }

    if (hasValidateAction && node._published) {
        actions.push('unvalidate')
    }

    if (hasValidateAction && !node._published) {
        actions.push('validate')
    }

    if (hasDeleteAction) {
        actions.push('delete')
    }

    if (!hideHistory && hasFullAccess && node?._did) {
        actions.push('history')
    }

    if (showCleanHistory && hasFullAccess && node._did) {
        actions.push('clean')
    }

    return actions;
}

export function NodeTableInlineActions(props) {
    const {id, data, actionsProps} = props

    const {userState} = useGlobalCustomContext();
    const {content} = useContentStore();
    const {search} = useSearchStore();

    // const [city] = useState(getClientCity(window) || DEFAULT_CITY)
    const [node, setNode] = useState()
    const [permits, setPermits] = useState()

    useEffect(() => {
        // const data = search?.result?.children ? search.result?.children : content?.children
        // if (data) {
            setNode({...data.list[id]})
            setPermits({...data.permits[id]})
        // }
    }, [search, content, userState.user])

    return <NodeInlineActions
        {...actionsProps}
        permits={permits}
        node={node}
        // unfolded = {!isMobile}
        // city={city}
    />
}

export default function NodeInlineActions(props) {
    const {permits, node, hideHistory, className, unfolded, direction, city} = props;

    const navigate = useNavigate();
    const {showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {nodeDispatch, userState} = useGlobalCustomContext();

    const [list, setList] = useState([])

    useEffect(() => {
        const fullAccess = hasFullAccess(userState.user);

        const list = getActions({...props, hasFullAccess: fullAccess})
            .map(id => {
                let result;
                switch(id) {
                    case 'edit':
                        result = {
                            id: 'edit',
                            icon: <EditOutlinedIcon />,
                            onClick: () => {
                                nodeDispatch({type: NODE_ACTIONS.EDIT, data: {city, uri: node._uri}})
                            }
                        }
                        break;

                    case 'unvalidate':
                        result = {
                            id: 'unvalidate',
                            icon: <UnpublishedOutlinedIcon className='Icon errorIcon' />,
                            onClick: () => {
                                nodeDispatch({type: NODE_ACTIONS.UNVALIDATE, data: {city, uri: node._uri}})
                            }
                        }
                        break;

                    case 'validate':
                        result = {
                            id: 'validate',
                            icon: <CheckCircleOutlinedIcon className='Icon successIcon' />,
                            onClick: () => {
                                nodeDispatch({type: NODE_ACTIONS.VALIDATE, data: {city, uri: node._uri}})
                            }
                        }
                        break;

                    case 'delete':
                        result = {
                            id: 'delete',
                            icon: <DeleteOutlineIcon className='Icon errorIcon' />,
                            onClick: () => {
                                nodeDispatch({type: NODE_ACTIONS.DELETE_START, data: {city, uri: node._uri, type: node._type}})
                            }
                        }
                        break;

                    case 'history':
                        result = {
                            id: 'history',
                            icon: <HistoryOutlinedIcon className='Icon' />,
                            onClick: () => {
                                navigate(`/profile/history/node/${city}/${node._did}`)
                            }
                        }
                        break;

                    case 'clean':
                        result = {
                            id: 'clean',
                            icon: <CleaningServicesIcon className='Icon errorIcon' />,
                            onClick: () => {
                                showPromt({
                                    snackbarMessage: 'Ви бажаєте очистити історію і видалити всі файли з корзини?',
                                    snackbarDuration: 10000,
                                    onApproveHandler: () => {
                                        closeToaster();
                
                                        cleanNodeHistory(city, node._did)
                                            .then(() => {
                                                hideBackdropPreloader();
                                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                            })
                                            .catch(() => {
                                                hideBackdropPreloader();
                                            })
                                    },
                                })
                            }
                        }
                        break;
                }
                return result;
            });

        setList(list);
    }, [node, permits, hideHistory, userState])

    if (!node) return null;

    if (list.length === 0) return null;

    return <InlineActions actions={list} className={className} unfolded={unfolded} direction={direction} id={node._did} />
}
