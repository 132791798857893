import { create } from 'zustand'

export const useConversationsStore = create((set) => ({
  // scrollToBottom: false,
  conversations: null,

  setConversations: (conversations) => set(() => {
    return {conversations};
  }),

  addNewConversation: (conversation) => set((state) => {
    const conversations = {...state.conversations};

    const cid = conversation._cid;
    if (cid) {
      conversations.order.unshift(cid);
      conversations.list[cid] = conversation;
      // conversations.list[cid]._last_message = msg;
    }

    return {conversations};
  }),

  updateConversation: (cid, msg) => set((state) => {
    const conversations = {...state.conversations};

    if (msg && conversations?.list?.[cid]) {
      conversations.list[cid]._last_message = msg;
      conversations.list[cid]._updated = msg._timestamp;
      conversations.list[cid]._not_readed = msg.details._not_readed;
    }

    return {conversations};
  }),

  updateConversationNotReaded: (cid, nr) => set((state) => {
    const conversations = {...state.conversations};

    if (conversations?.list?.[cid]) {
      conversations.list[cid]._not_readed = nr;
    }

    return {conversations};
  }),

}))
