import {isMobile} from "react-device-detect";
import {useEffect, useState, useRef} from 'react';

import Button from '@mui/material/Button';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {transliterate} from 'ultra/helpers/utils';

import Preloader from '../../../../Components/Preloaders/Preloader';
import Carousel from '../../../../Components/Carousel';

import ProposalDetails from '../ProposalsDetails';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import './index.scss';

function Details(props) {
  const {price, companyConfig} = props

  const {userState} = useGlobalCustomContext();

  const registeredDiscount = userState?.user && companyConfig?.discounts?.registered
  const childrenDiscount = companyConfig?.discounts?.children

  return (
    <div className='priceBlock'>
      <div className='price'>{price.finalPrice.toFixed(0)}<span>₴</span></div>

      {(price.finalPrice != price.clearPrice) && <div>
        <div className='discount'>{price.clearPrice.toFixed(0)}<span>₴</span></div>
        {registeredDiscount && <div className='details'>* {registeredDiscount}% для зареєестрованого користувача</div>}
        {childrenDiscount && <div className='details'>* {childrenDiscount}% для дитини</div>}
      </div>}
    </div>
  )
}

const Proposal = (props) => {
  const {isActive, pack, company, coverage, price, className, onChooseButton, companyConfig} = props;
  
  const [flipped, setFlipped] = useState(false)

  const handleDetailsClick = () => {
    setFlipped(true)
  }

  const handleDetailsCloseClick = () => {
    setFlipped(false)
  }

  let componentClassName = className + (flipped ? ' flipped ' : '');

  return <div className={componentClassName}>
      <div className='ProposalWrap'>
        <div className='ProposalWrapFront'>
          <div>
            <span className='title'>
              {company}
            </span>
            <span className='package'>
              {pack.label}
            </span>
            <span className='cover'>
              <span>Покритя</span>
              <span className='covarage'>{coverage}</span>
            </span>
            <span className='proposalDetails' onClick={handleDetailsClick}>
              <span className="text">
                Детальніше
              </span>
              <InfoOutlinedIcon />
            </span>
          </div>
          <Details price={price} companyConfig={companyConfig}/>
          <Button className='select' variant="contained" onClick={() => {
              onChooseButton(company, coverage);
          }}>
            {isActive ? 'Обрано' : 'Обрати'}
          </Button>
        </div>
        <div className='ProposalWrapBack'>
          <CloseIcon className='CloseIcon' onClick={handleDetailsCloseClick}/>
          <div className='ProposalWrapBackDetails'>
            <div className='companyName'><span>{company}</span>, {pack.label}:</div>
            
            <div className="detailsWrap">
              <ProposalDetails pack={pack} company={company}/>
            </div>
          </div>
        </div>
      </div>
  </div>
}

export default function Proposals(props) {
  const [cards, setCards] = useState(null);

  const {isPackagesLoading, prices, selected, activeCoverage, insuranceConfig, onChooseButton} = props

  useEffect(() => {
    const proposals = []
    Object.keys(insuranceConfig.options).map(company => {
      if (!prices) return;

      const companyConfig = insuranceConfig.options[company]
      companyConfig.coverages.map(coverage => {
        if (activeCoverage !== 'Всі доступні' && coverage !== activeCoverage) return;

        companyConfig.packages.map(pack => {
          const price = prices[company][coverage][pack.id] 
          
          if (!price.finalPrice) return;

          const isActive = selected && selected.company === company && selected.coverage === coverage && selected.pack.id === pack.id;
          const key = `${company}-${transliterate(pack.label)}-${coverage}`

          proposals.push({
            price,
            component: <Proposal
              className={isActive ? 'Proposal active' : 'Proposal'}
              key={key}
              company={company}
              coverage={coverage}
              pack={pack}
              price={price}
              isActive={isActive}
              companyConfig={insuranceConfig.options[company]}
              onChooseButton={(company, coverage) => {
                onChooseButton({company, coverage, pack, price})
              }}
            />
          })
        })
      })
    })

    function getMinimal() {
      let minimalComponent
      proposals.map(item => {
        // ignore if its exist in result
        if (result.find(p => p === item.component)) {
          return;
        }

        if (!minimalComponent) {
          // first iteration, 
          minimalComponent = item
        } else {
          // get card with minimal price
          if (item.price.finalPrice >= minimalComponent.price.finalPrice) return;

          minimalComponent = item
        }
      })

      return minimalComponent
    }

    const result = [];
    proposals?.map(() => {
      result.push(getMinimal().component)
    });

    if (!isMobile) {
      result?.push(<div></div>)
    }

    setCards(result);
    
  }, [prices, selected, activeCoverage])

  if (isPackagesLoading) return <div className="ProposalsCarousel">
    <Preloader />
  </div>

  if (isMobile && cards) {
    return <div className="ProposalsCarousel">
      <Carousel>{cards}</Carousel>
    </div>
  }

  if (!isMobile) {
    return <Carousel
      slidesPerView={2}
      navigation={true}>
        {cards}
      </Carousel>
  }
}
