import {getClientConfig} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {LOG_TYPES} from 'ultra/const/log';
import {getClientCity} from 'ultra/configs/general';

import {post} from './http';

const {api} = getClientConfig(window)

export function log(code, details) {
    const city = getClientCity(window) || DEFAULT_CITY;
    return post(`${api}/log/log`, {type: LOG_TYPES.INFO, city, code, details});
}

export function error(code, details) {
    console.error(code)
    console.error(details)

    const city = getClientCity(window) || DEFAULT_CITY;
    return post(`${api}/log/log`, {type: LOG_TYPES.ERROR, city, code, details});
}
