import {useState, useEffect} from 'react';
import {isMobile} from "react-device-detect";
import dayjs from 'dayjs';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTime, timestampToDate, getFormatedTime} from 'ultra/helpers/date';
import {getPartnerDetails} from 'ultra/helpers/shop';

import {useConfigStore} from '../../../../../../../../Stores/config';
import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import PreloaderTopPagePicture from '../../../../../../../../Components/Preloaders/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/Preloaders/PreloaderText';

import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
import ShopProductPrices from '../../../../../../Widgets/ShopProductPrices'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import ProductOptions from '../../../../../../Widgets/ProductOptions'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import AddToCalendar from '../../../../../../Widgets/AddToCalendar'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import TourComplexity from '../../../../../../Widgets/TourComplexity'
import Gallery from '../../../../../../Widgets/Gallery';

import './index.scss'

export default function Template(props) {
    const {content, permits, config, form, breadcrumbs} = props

    const {node} = getPartnerDetails(content, breadcrumbs)

    const {contentLoading} = useNavigationStore()

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [days, setDays] = useState()

    useEffect(() => {
        const start = getTime(timestampToDate(content.date), timeZone);
        const end = start.add(content.duration, 'hour').subtract(1, 'second');

        if (start.date() !== end.date()) {
            setDays(1 + end.date() - start.date());
        } else {
            setDays(1);
        }
    }, [])

    if (contentLoading) return <>
        <PreloaderTopPagePicture />
        <PreloaderText />
    </>

    return <div className="Tour">
        <TopPagePicture cutHeightForDesktop image={content.banner}/>

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <div className={(!content?.price?.free && !content?.price?.form) ? "details" : "details detailsOneButton"}>
            {!isMobile && (!content?.price?.free && !content?.price?.form) && <div className="labelWrap price">
                <span className="label">Ціна:</span>
                <span className="labelValue">
                    {/* currency={node?.partner?.config?.currency} */}
                    <ShopProductPrice product={content} />
                </span>
            </div>}

            <ShopingCart
                fullWidth
                item={content}
                shop={node}
                showPrice={isMobile}
            />
        </div>

        {days === 1 && content.date && <div className='date'>
            {content.date && <div className='labelWrap'>
                <span className="label">Дата та час:</span>
                <span className="labelValue">
                    {getFormatedTime(timestampToDate(content.date), timeZone)}
                </span>
            </div>}
        </div>
        }

        {days > 1 && content.date && <div className='date'>
            {content.date && <div className='dates'><CalendarMonthIcon /> {getFormatedTime(timestampToDate(content.date), timeZone)} - {getFormatedTime(dayjs(timestampToDate(content.date)).add(content.duration, 'hour', timeZone), timeZone)}</div>}
        </div>
        }

        {isMobile && <TourComplexity complexity={content.complexity} config={form.fields.complexity}/>}

        {!isMobile && <div className='TourDetails'>
            <div className='complexity'>
                <TourComplexity complexity={content.complexity} config={form.fields.complexity}/>
            </div>

            <div className='calendar'>
                <AddToCalendar
                    title={content.title}
                    date={content.date}
                    duration={content.duration}
                    // details=''
                    location={content.place}
                />
            </div>
        </div>}

        {/* <ShopProductPrices product={content} /> */}

        <CKEditorContent content={content.description} />

        <ProductOptions item={content}/>

        <Gallery content={content.gallery} />

        {isMobile && <AddToCalendar
                    title={content.title}
                    date={content.date}
                    duration={content.duration}
                    // details=''
                    location={content.place}
                />}
    </div>
}
