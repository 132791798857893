import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";

import Switch from '@mui/material/Switch';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormControlLabel from '@mui/material/FormControlLabel';

import {transliterate} from 'ultra/helpers/utils';

import CurrencyFormated from '../../../Components/CurrencyFormated'
import Text from '../Text';
import Number from '../Number';

import './index.scss';

export default function ProductAddonsField(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))

    const [addItem, setAddItem] = useState();
    const [addonTitle, setAddonTitle] = useState();

    const [variantTitle, setVariantTitle] = useState();
    const [variantPrice, setVariantPrice] = useState();
    const [variantCounter, setVariantCounter] = useState(true);
    const [variantFree, setVariantFree] = useState(false);

    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        setClassName('ProductAddonsField inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const [className, setClassName] = useState('ProductAddonsField inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    const onPlaceholderChange = (e) => {
        const value = content[field.id] || {};
        value.placeholder = e.target.value;

        onChange({target: {value}})
    }

    const onAddMultiPriceHandle = (id) => {
        if (!variantTitle || (!variantFree && !variantPrice)) return;

        const value = content[field.id];
        const addonId = transliterate(variantTitle);

        if (content[field.id]?.list?.[id]?.[addonId]) {
            alert('Варіант вже додано')
            return;
        }

        value.list = value.list || {}
        value.list[id].list = value.list[id].list || {}

        value.list[id].list[addonId] = {
            title: variantTitle,
            price: variantPrice,
            counter: variantCounter,
            free: variantFree,
            id
        }

        setVariantTitle()
        setVariantPrice()
        setVariantCounter(true)
        onChange({target: {value}})
    }

    const onRemoveMultiPriceHandle = (id, addonId) => {
        const value = {...content[field.id]}
        const list = {...value.list[id]}
        
        delete list.list[addonId];

        value.list[id] = list;

        onChange({target: {value}});
    }

    const onVariantTitleChange = (e) => {
        setVariantTitle(e.target.value);
    }

    const onVariantPriceChange = (e) => {
        setVariantPrice(e.target.value);
    }

    const onVariantCounterChange = () => {
        setVariantCounter(!variantCounter);
    }

    const onVariantFreeChange = () => {
        setVariantFree(!variantFree);
    }

    const onAddonTitleChange = (e) => {
        setAddonTitle(e.target.value);
    }

    const onAddAddonHandle = () => {
        if (!addonTitle) {
            return;
        }

        const id = transliterate(addonTitle);

        if (content?.[field.id]?.list?.[id]) {
            alert('Опція вже доступна')
            return;
        }

        const value = content[field.id] || {};
        value.list = value.list || {}

        value.list[id] = {
            title: addonTitle,
            list: {}
        };

        setAddonTitle();
        onChange({target: {value}})
    }

    const onRemoveAddonHandle = (id) => {
        const value = content[field.id] || {};
        delete value.list[id];
        onChange({target: {value}});
    }

    return <div className={className}>
        <div className='inputLabel'>
            {field.placeholder} {field.required ? '*' : ''}
        </div>

        <Grid container spacing={2} className='multiPriceBlock'>
            <Grid item xs={12}>
                <Grid container>
                    <Text
                        disabled={isDisabled}
                        field={{id: 'placeholder', placeholder: "Підпис"}}
                        content={content[field.id] || {}}
                        onChange={onPlaceholderChange}
                    />
                </Grid>
            </Grid>
        </Grid>

        {content[field.id] && <>
            {content?.[field.id]?.list && Object.keys(content[field.id]?.list)?.map(id =>
                <div className='addonsTable' key={`addons_list_${field.id}_${id}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={9} md={10} className='addonsTableTitle'>
                            {content[field.id]?.list?.[id]?.title}
                        </Grid>
                        <Grid item xs={3} md={2} className='addonsTableAction'>
                            <Button
                                variant="outlined"
                                component="label"
                                tabIndex={-1}
                                size='small'
                                onClick={() => onRemoveAddonHandle(id)}
                            >
                                <ClearIcon />
                            </Button>
                        </Grid>
                    </Grid>
                        
                    {content?.[field.id]?.list[id]?.list && Object.keys(content[field.id]?.list[id].list)?.map(addonId =>
                        <Grid key={`addons_item_${addonId}`} container spacing={1}>
                            <Grid item xs={12} md={8}>
                                <div>
                                    {content[field.id]?.list[id].list[addonId].title}
                                    {content[field.id]?.list[id].list[addonId].counter && <div className='counter'>Можливість замовити більше однієї одиниці</div>}
                                </div>
                            </Grid>
                            {content[field.id]?.list[id].list[addonId].free && <Grid item xs={12} md={8}>
                                <div>Безкоштовно</div>
                            </Grid>}
                            <Grid item xs={12} md={3}>
                                <CurrencyFormated value={content[field.id]?.list[id].list[addonId].price} currency={content.price.currency} />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                {!isMobile && <ClearIcon className='removeOption' onClick={() => onRemoveMultiPriceHandle(id, addonId)}/>}

                                {isMobile && <Button
                                    fullWidth
                                    variant="outlined"
                                    component="label"
                                    tabIndex={-1}
                                    size='small'
                                    onClick={() => onRemoveMultiPriceHandle(id, addonId)}
                                >
                                    <ClearIcon />
                                    <span>Видалити</span>
                                </Button>}
                            </Grid>
                        </Grid>
                    )}

                    <div className="inputEmulation">
                        <span className="inputLabel">Додати</span>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Text
                                        disabled={isDisabled}
                                        field={{id: 'title', placeholder: "Варіант"}}
                                        content={{title: variantTitle}}
                                        onChange={onVariantTitleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container>
                                    <Number
                                        disabled={isDisabled || variantFree}
                                        field={{id: 'price', placeholder: "Ціна"}}
                                        content={{price: variantPrice}}
                                        onChange={onVariantPriceChange}
                                    />
                                </Grid>
                            </Grid>

                            {isMobile && <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={variantFree} onChange={onVariantFreeChange} />} label={"Безкоштовно"} />
                            </Grid>}

                            {isMobile && <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={variantCounter} onChange={onVariantCounterChange} />} label={"Можливість замовити більше однієї одиниці"} />
                            </Grid>}

                            <Grid item xs={12} md={2}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    component="label"
                                    tabIndex={-1}
                                    onClick={() => onAddMultiPriceHandle(id)}
                                >
                                    <AddIcon />
                                    {isMobile && <span>Додати</span>}
                                </Button>
                            </Grid>

                            {!isMobile && <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={variantCounter} onChange={onVariantCounterChange} />} label={"Можливість замовити більше однієї одиниці"} />
                            </Grid>}

                            {!isMobile && <Grid item xs={12}>
                                <FormControlLabel control={<Switch checked={variantFree} onChange={onVariantFreeChange} />} label={"Безкоштовно"} />
                            </Grid>}

                        </Grid>
                    </div>
                </div>
            )}
        </>}

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <span className='pseudoLinkWrap' onClick={() => {setAddItem(!addItem) }}>
                    <AddIcon />
                    <span className='pseudoLink'>Додати опцію</span>
                </span>
            </Grid>

            {addItem && <Grid item xs={9} md={10}>
                <Grid container>
                    <Text
                        disabled={isDisabled}
                        field={{id: 'title', placeholder: "Назва"}}
                        content={{title: addonTitle}}
                        onChange={onAddonTitleChange}
                    />
                </Grid>
            </Grid>}
            {addItem && <Grid item xs={3} md={2}>
                <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    tabIndex={-1}
                    onClick={onAddAddonHandle}
                >
                    <AddIcon />
                </Button>
            </Grid>}
        </Grid>

        {field?.notes && <div className='fieldNotes'>
            <p>{field?.notes}</p>
        </div>}
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </div>
}
