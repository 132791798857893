import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import {PreloaderTitle} from '../PreloaderTitleAndBreadcrumbs'

import './index.scss';

export default function PreloaderMessages() {
    return <div className='Conversations ConversationsPreloader'>
        <PreloaderTitle />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PreloaderMessage />
                {/* <Skeleton variant="rectangular" width={'100%'} height={92} style={{marginBottom: '.5rem'}} /> */}
            </Grid>
            <Grid item xs={12}>
                <PreloaderMessage />
                {/* <Skeleton variant="rectangular" width={'100%'} height={92} style={{marginBottom: '.5rem'}} /> */}
            </Grid>
            <Grid item xs={12}>
                <PreloaderMessage />
                {/* <Skeleton variant="rectangular" width={'100%'} height={92} style={{marginBottom: '.5rem'}} /> */}
            </Grid>
            <Grid item xs={12}>
                <PreloaderMessage />
                {/* <Skeleton variant="rectangular" width={'100%'} height={92} style={{marginBottom: '.5rem'}} /> */}
            </Grid>
        </Grid>
    </div>
}

export function PreloaderMessage(props) {
    const {hideAvatar} = props;

    return <div className={`PreloaderMessage ${hideAvatar ? 'HideAvatar' : ''}`}>
        {!hideAvatar && <Skeleton variant="circular" width={66} height={66} className='avatar' />}
        <div className='Text'>
            <div style={{marginBottom: '1rem'}}><Skeleton variant="rectangular" width={'60%'} height={16} /></div>
            <div><Skeleton variant="rectangular" width={'40%'} height={16} /></div>
        </div>
    </div>
}
