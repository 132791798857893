import {isRole} from "ultra/helpers/auth";

import {isYoursAccount} from './user';

export function getConversationTitle(conversation, userState, users) {
    let title;
    if (conversation.channel) {
        if (conversation.topic.partner) {
            title = conversation.partner_title;
        }
        else {
            title = conversation.topic.title;
        }
    }

    else if (conversation.conversation) {
        if (conversation.private) {
            title = conversation.participants
                .filter(i => !isYoursAccount(userState, i) && !isRole(i))
                .map(u => users[u].displayName).join(', ')
        }
        else {
            title = conversation.participants
                .filter(i => !isRole(i))
                .map(u => users[u].displayName).join(', ')
        }
    }

    return title;
}
