import React, {useEffect, useState} from 'react';

import {useSearchParams} from "react-router-dom"; // useLocation

// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import {getClientCity} from 'ultra/configs/general';
// import {DEFAULT_CITY} from 'ultra/const/general';
import {normalizeRoute} from "ultra/helpers/route"; // restorePath
import {revertPath} from 'ultra/helpers/route';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useVirtualUriStore} from '../../../../../../../../Stores/url';
import {useContentStore} from '../../../../../../../../Stores/content'
import {useSearchStore} from '../../../../../../../../Stores/search';

import PreloaderText from '../../../../../../../../Components/Preloaders/PreloaderText';
import PreloaderShop from '../../../../../../../../Components/Preloaders/PreloaderShop';

import ShopProductsList from '../../../../../../Widgets/ShopProductsList'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import LinksLines from '../../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TitleWidget from '../../../../../../Widgets/Title'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture';

import {getVirtualUri} from '../../../../../../../../Helpers/content';

import './index.scss'

export default function Template(props) {
    const {config, permits, breadcrumbs} = props;

    const {content} = useContentStore();
    const {virtualUri, setVirtualUri, virtualUriLoading} = useVirtualUriStore();

    let [searchParams, setSearchParams] = useSearchParams();

    const {contentLoading} = useNavigationStore()

    const {search} = useSearchStore();

    const [items, setItems] = useState();
    const [hasProducts, setHasProducts] = useState();
    const [categories, setCategories] = useState();
    const [categoriesSearch, setCategoriesSearch] = useState();

    useEffect(() => {
        // it should NOT be updated when virtual uri data loading // because old data without categories will be showed
        if (!virtualUriLoading) {
            let hasProducts = false;
            const list = {};
            const permits = {};
            const order = [];

            const categoriesWithSearch = {}

            content.children.order.map(item => {
                if (content.children.list[item]._type === 'shop-product' || content.children.list[item]._type === 'shop-alias') {
                    hasProducts = true;
                }
        
                if (content.children.list[item]._type === 'shop-section') {
                    if (search?.result) {
                        let sectionPresent = 0

                        let childrenAmount = search?.result?.children?.order?.filter(
                            uri => uri.indexOf(item) === 0
                                && (
                                    search?.result?.children?.list[uri]._type === 'shop-product'
                                    || search?.result?.children?.list[uri]._type === 'shop-alias'
                                )
                                && search?.result?.children?.list[uri]._type !== 'shop-section'
                        )?.length;
                        if (childrenAmount > 0) sectionPresent += childrenAmount;

                        let globalAmount = search?.result?.global?.order?.filter(
                            uri => uri.indexOf(item) === 0
                                && (
                                    search?.result?.global?.list[uri]._type === 'shop-product'
                                    || search?.result?.global?.list[uri]._type === 'shop-alias'
                                )
                                && search?.result?.global?.list[uri]._type !== 'shop-section'
                        )?.length;
                        if (globalAmount > 0) sectionPresent += globalAmount;

                        if (sectionPresent) {
                            categoriesWithSearch[item] = sectionPresent
                        }
                    }

                    list[item] = content.children.list[item];
                    permits[item] = content.children.permits[item];
                    order.push(item);
                }
            })

            if (content?.page?.content?.virtualCatalogs) {
                const uri = content?.page?.content._uri;

                if (search?.result) {
                    let sectionPresent = 0

                    let childrenAmount = search?.result?.children?.order?.filter(
                        i => search?.result?.children?.list[i]._parent === uri
                            && (
                                search?.result?.children?.list[i]._type === 'shop-product'
                                || search?.result?.children?.list[i]._type === 'shop-alias'
                            )
                            && search?.result?.children?.list[i]._type !== 'shop-section'
                    )?.length;
                    if (childrenAmount > 0) {
                        sectionPresent += childrenAmount;
                    }

                    let parentAmount = search?.result?.global?.order?.filter(
                        i => search?.result?.global?.list[i]._parent === uri
                            && (
                                search?.result?.global?.list[i]._type === 'shop-product'
                                || search?.result?.global?.list[i]._type === 'shop-alias'
                            )
                            && search?.result?.global?.list[i]._type !== 'shop-section'
                    )?.length;
                    if (parentAmount > 0) {
                        sectionPresent += parentAmount;
                    }

                    if (sectionPresent) {
                        categoriesWithSearch[uri] = sectionPresent
                    }
                }

                // add main page to list
                list[uri] = {...content?.page?.content}
                list[uri].title = content?.page?.content?.virtualCatalogs?.root
                order.unshift(content?.page?.content?._uri)
            }

            setCategoriesSearch(categoriesWithSearch)

            setHasProducts(hasProducts)

            setCategories({
                list,
                order,
                permits,
            })
        }

        if (search?.uri === revertPath(normalizeRoute(getVirtualUri() || window.location.pathname))) {
            setItems(search.result.children)
        }
        else if (
            search?.uri !== revertPath(normalizeRoute(getVirtualUri() || window.location.pathname))
            &&
            search?.result?.global?.order?.length > 0
        ) {
            let result = {
                list: {},
                order: [],
                permits: {}
            }
            search?.result?.global?.order.map(i => {
                const node = search?.result?.global?.list[i];
                const permits = search?.result?.global?.permits[i];
                if (node._parent === revertPath(normalizeRoute(getVirtualUri() || window.location.pathname))) {
                    result.list[i] = node;
                    result.permits[i] = permits;
                    result.order.push(i);
                }
            })

            setItems(result)
        }
        else if (content?._virtual_uri?.children) {
            setItems(content?._virtual_uri?.children)
        }
        else if (content.children) {
            setItems(content.children)
        }
    }, [content._virtual_uri, content.children, content.page, search.query])

    const virtualLinkHandler = (uri) => {
        if (!uri) return;

        if (virtualUri === uri) return;

        setVirtualUri(uri);

        if (uri === content?.page?.content?._uri) { // normalizeRoute
            setSearchParams({});
        }
        else {
            setSearchParams({virtual_uri: uri});
        }
    }

    return <div className="Shop">
        {content?.page?.content?.banner
            && (
                !content?.page?.content?.showPagination
                ||
                (content?.page?.content?.showPagination && content.children?.pagination?.current === 1)
            )
            && <TopPagePicture cutHeightForDesktop showSkeleton={contentLoading} image={content?.page?.content?.banner}/>}

        <TitleWidget permits={permits} content={content?.page?.content} config={config} breadcrumbs={breadcrumbs} hide={content?.page?.content?.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content?.page?.content?.hideBreadcrumbs}/>

        {contentLoading && <>
            <PreloaderShop  />
            <PreloaderText />
        </>}

        {/* CATEGORIES */}
        {/* not filter categories */}
        {!contentLoading && content?.page?.content?.virtualCatalogs && categories?.order?.length > 0 && <LinksLines
            config={config}
            links={categories}
            className="VirtualCatalogs"
            linkHandler={virtualLinkHandler}
            badges={categoriesSearch}
            xs={5}
            md={2}
            src="thumbnail"
            autoScrollToActive
        />}

        {!contentLoading && !content?.page?.content?.virtualCatalogs && categories?.order?.length > 0 && <LinksLines
            config={config}
            links={categories}
        />}

        {/* PRODUCTS */}
        {!contentLoading && hasProducts && <ShopProductsList
            currency={content?.partner?.config?.currency}
            config={config}

            skeletonHideBreadcrumbs={true}
            showSkeleton={virtualUriLoading}

            breadcrumbs={content?._virtual_uri?.breadcrumbs || breadcrumbs}
            content={content?._virtual_uri?.page?.content || content?.page?.content}
            items={items}
        />}

        {!contentLoading && content._virtual_uri && <div>
            <CKEditorContent content={content?._virtual_uri?.page?.content?.description} />
        </div>}

        {!contentLoading && !content._virtual_uri && content?.page?.content.description && <div className='ShopDescription'>
            <ContentImage src="thumbnail" image={content?.page?.content.thumbnail} className="thumbnail" />
            <CKEditorContent content={content?.page?.content.description} />
        </div>}
    </div>
}
