import DOMPurify from "dompurify"

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getClientEnv, getClientImageUrl} from 'ultra/configs/general';

import PreloaderText from '../../../../Components/Preloaders/PreloaderText';

import './index.scss';

export default function CKEditorContent(props) {
    const {content, className, showSkeleton} = props

    if (showSkeleton) return <div className="CKEditorContent">
        <PreloaderText/>
    </div>

    if (!content) return <></>

    const env = getClientEnv(window);
    const city = getClientCity(window) || DEFAULT_CITY;
    const path = getClientImageUrl(env, city, '');
    const sanitazed = DOMPurify.sanitize(content?.replaceAll('src="', `src="${path}`))

    return <div className={`CKEditorContent ${className ? className : ''}`}>
        <div dangerouslySetInnerHTML={{ __html: sanitazed }} />
    </div>
}
