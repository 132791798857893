import Skeleton from '@mui/material/Skeleton';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'

import LocationWidget from '../../../../../../Widgets/LocationWidget'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import MessengerValue from '../../../../../../Widgets/MessengerValue'
import FilterChip from '../../../../../../Widgets/FilterChip'
import ContentImage from '../../../../../../Widgets/ContentImage'
import PreloaderText from '../../../../../../../../Components/Preloaders/PreloaderText';

import './index.scss'

export default function Template(props) {
    const {content, form, permits, config, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    return <div className="Doctor">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {contentLoading && <div className='details' style={{height: '210px'}}>
            <Skeleton variant="rectangular" height="200px" className="photo"/>
            <div style={{marginLeft: '190px', marginBottom: '2rem'}}>
                <PreloaderText />
            </div>
        </div>}

        {!contentLoading && <div className='details'>
                <ContentImage src="poster" image={content.poster} className="photo"/>
                <div className='content'>
                    {content.description && <CKEditorContent content={content.description} />}

                    {content.region && <p className='labelWrap region'>
                        <span className="label">Район:</span>
                        <span className="labelValue">
                            <FilterChip value={content.region} config={form.fields.region} />
                        </span>
                    </p>}

                    {content.messenger && <MessengerValue messenger={content.messenger} />}

                    {content.departments && <p className='labelWrap region'>
                        <span className="label">Віділлення:</span>
                        <span className="labelValue">
                            {content.departments.join(', ')}
                        </span>
                    </p>}
                </div>
            </div>
        }

        {content.location && <LocationWidget showSkeleton={contentLoading} location={content.location} />}
    </div>
}
