import {useNavigate} from "react-router-dom";

import OrderActions from '../../../../OrderActions';
import {makeUrlsClickable} from '../../../../../../../Helpers/format';
import {useFullHeightContainerStore} from '../../../../../../../Stores/fullHeightContainer';
import {useBackdropPreloaderStore} from '../../../../../../../Stores/backdropPreloader';

export default function Template(props) {
    const {values, short} = props;

    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

    const {startScrollTo} = useFullHeightContainerStore();
    const navigate = useNavigate();

    const onOrderClick = (mid) => {
        if (short) return;

        const query = '.item_' + mid;
        if (document.querySelector(query)) {
            startScrollTo(query);
        }
        else {
            showBackdropPreloader();
            let url = `/profile/messages/${values?.details?._cid}?mid=${mid}`;
            return navigate(url);
        }
    }

    return <>
        {/* <div className="NotificationTitle"> */}
            <pre className='ActionText' dangerouslySetInnerHTML={{
                __html: makeUrlsClickable(
                    (short && values.details.message.replace(/\n/g, " ").length > 128)
                    ? `${values.details.message.replace(/\n/g, " ").substring(0, 128)}...`
                        : values.details.message
                )
            }}></pre>
            {values?.details?._oid && <div className='orderId' onClick={() => onOrderClick(values?.details?._oid_mid)}>#{values?.details?._oid}</div>}
        {/* </div> */}

        <OrderActions details={values.details} />
    </>
}
