import React, {useEffect, useState} from 'react';

import {useLoaderData, useNavigation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {hasFullAccess} from 'ultra/helpers/auth';
import {DEFAULT_CITY, NO_CITY} from 'ultra/const/general';

import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';

import {getHistory, removeAllHistory} from '../../Helpers/history';
import {flushNodesHistory, flushUsersHistory, flushOrdersHistory} from '../../Helpers/history';
import {removeAllMessages} from '../../Helpers/messages';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {useConfigStore} from '../../../../Stores/config';
import {useToasterStore} from '../../../../Stores/toster';

import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';

import InlineActions from '../../../Content/Widgets/InlineActions';
import {TableView} from '../../../Content/Widgets/Table';
import TitleWidget from '../../../Content/Widgets/Title';
import FiltersLine from '../../../Content/Widgets/FiltersLine';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../Content/Reducers/node';
import {useContentStore} from '../../../../Stores/content';

import {setPageTitle} from '../../../../Helpers/router';

import './index.scss';

const TITLE = 'Історія';

export default () => {
    const rawNodes = useLoaderData();

    const navigation = useNavigation();
    const {configs} = useConfigStore();    
    const {content, setContent} = useContentStore();

    const {userState, nodeState, nodeDispatch, userDispatch} = useGlobalCustomContext();

    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _code: {
            type: 'notification',
            placeholder: 'Тип'
        },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор'
        },
        id: {
            type: 'user',
            placeholder: 'Користувач'
        },
        access: {
            type: 'roles',
            placeholder: 'Ролі'
        },
        details: {
            type: 'json',
            placeholder: 'Деталі'
        },
    }

    const [fields] = useState(baseFields);

    const MobileConfig = {
        visible: ['_code'],
        hidden: ['_city'], // , '_init_by'
        noHeaders: true
    }

    const DesktopConfig = {
        visible: ['_timestamp', '_city', '_code'], // '_init_by', 
        hidden: [],
    }

    useEffect(() => {
        setTableOptions(isMobile ? MobileConfig : DesktopConfig)
        setPermits({})
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage();
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            setContent(rawNodes);
        }
        setPageTitle(TITLE)
    }, [navigation])

    useEffect(() => {
        if (hasFullAccess(userState.state)) {
            MobileConfig.hidden.push('access')
            DesktopConfig.hidden.push('access')
        }

        setTableOptions(isMobile ? MobileConfig : DesktopConfig)

        if (configs) {
            const values = {
                [NO_CITY]: 'Не вказано',
                [DEFAULT_CITY]: 'Головна'
            }

            const order = [
                NO_CITY,
                DEFAULT_CITY
            ]
 
            Object.keys(configs.content.cities).sort().map(city => {
                if (configs.content.cities[city].city) {
                    values[city] = configs.content.cities[city].city;
                    order.push(city);
                }
            });

            const config = {
                // templateOptions: {},
                showFilters: {
                    filterBy: [
                        {
                            id: '_city',
                            field: {
                                placeholder: 'Місто',
                                type: 'filter',
                                values: {values, order},
                            }
                        },
                    ]
                }
            }

            setConfig({...config})
        }
    }, [userState?.user, configs])

    const updatePage = () => {
        const url = new URL(window.location.href);

        // setContent({});
        getHistory(url.search)
            .promise
            .then(setContent)
    }

    const selectClasses = (data) => {
        const t = data?._type
            ? 'tr_' + data?._type
            : '';

        const c = data?._code
            ? 'tr_' + data?._code
            : '';

        return `${t ? t: ''} ${c ? c : ''}`
    }

    const title = {
        title: TITLE,
    }

    const breadcrumbs = {}

    return <div  className='Content'>
            <div className='HistoryAdminPage'>
                <TitleWidget permits={permits} content={title} config={config} breadcrumbs={breadcrumbs} />

                {isMobile && config?.showFilters?.filterByInline && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {hasFullAccess(userState?.user) && <HistoryLineActions />}

                {fields && content.children && <TableView
                    content={content.children}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    selectClasses={selectClasses}
                />}
            </div>
        </div>
}

function HistoryLineActions() {
    const [actions, setActions] = useState([])
    const {showPromt, closeToaster} = useToasterStore();
    // const {hideBackdropPreloader} = useBackdropPreloaderStore()

    const {nodeDispatch} = useGlobalCustomContext();

    useEffect(() => {
        const actions = []
        actions.push({
            id: 'clean',
            icon: <CleaningServicesOutlinedIcon className='Icon errorIcon' />,
            onClick: () => {
                showPromt({
                    snackbarMessage: 'Бажаєте видалити всю історію?',
                    snackbarDuration: 10000,
                    onApproveHandler: () => {
                        closeToaster();

                        removeAllHistory()
                            .then(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                            .catch(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                    },
                })
            }
        })

        actions.push({
            id: 'nodes',
            icon: <CommentsDisabledOutlinedIcon className='Icon errorIcon' />,
            onClick: () => {
                showPromt({
                    snackbarMessage: 'Бажаєте видалити історію сторінок та файли в корзині?',
                    snackbarDuration: 10000,
                    onApproveHandler: () => {
                        closeToaster();

                        flushNodesHistory()
                            .then(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                            .catch(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                    },
                })
            }
        })

        actions.push({
            id: 'users',
            icon: <PersonOffOutlinedIcon className='Icon errorIcon' />,
            onClick: () => {
                showPromt({
                    snackbarMessage: 'Бажаєте видалити історію користувачів?',
                    snackbarDuration: 10000,
                    onApproveHandler: () => {
                        closeToaster();

                        flushUsersHistory()
                            .then(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                            .catch(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                    },
                })
            }
        })

        actions.push({
            id: 'orders',
            icon: <RemoveShoppingCartOutlinedIcon className='Icon errorIcon' />,
            onClick: () => {
                showPromt({
                    snackbarMessage: 'Бажаєте видалити всі замовлення з магазинів та форм?',
                    snackbarDuration: 10000,
                    onApproveHandler: () => {
                        closeToaster();

                        flushOrdersHistory()
                            .then(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                            .catch(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                    },
                })
            }
        })

        actions.push({
            id: 'messages',
            icon: <SpeakerNotesOffOutlinedIcon className='Icon errorIcon' />,
            onClick: () => {
                showPromt({
                    snackbarMessage: 'Бажаєте видалити всі переписки?',
                    snackbarDuration: 10000,
                    onApproveHandler: () => {
                        closeToaster();

                        removeAllMessages()
                            .then(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                            .catch(() => {
                                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                // hideBackdropPreloader();
                            })
                    },
                })
            }
        })

        setActions(actions)
    }, [])

    return <InlineActions actions={actions} className="CurrentPageInlineActions" id={'all_history_actions'} />
}
