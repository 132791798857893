import {Badge} from '@mui/material';

import {isRole} from "ultra/helpers/auth";

import {useGlobalCustomContext} from '../../../../Helpers/context';

import Avatar from '../../../Content/Widgets/Avatar';
import TopicAvatar from '../../../Content/Widgets/TopicAvatar';
import PartnerImage from '../../../Content/Widgets/PartnerImage';

import {isYoursAccount} from '../../../../Helpers/user';

import './index.scss';

export default function ConversationAvatar(props) {
    const {conversation, users, showBadge} = props;

    const {userState} = useGlobalCustomContext();

    if (conversation.channel) {
        const {topic} = conversation;

        let img = <></>
        if (topic?.partner?.id && topic?.partner?.shop) {
            img = <PartnerImage id={topic.partner.id} shop={topic.partner.shop} className="TopicAvatar"/>
        }
        else if (topic?.partner?.id && topic?.partner?.form) {
            img = <PartnerImage id={topic.partner.id} form={topic.partner.form} className="TopicAvatar"/>
        }
        else if (topic?.icon) {
            img = <TopicAvatar icon={topic.icon} />
        }

        if (showBadge) {
            return <Badge
                    color='primary'
                    className='NotificationBadge'
                    badgeContent={conversation._not_readed}
                >{img}</Badge>
        }
        else {
            return img
        }
    }

    else if (conversation.conversation) {
        const images = conversation.participants
            .filter(i => {
                if (conversation.private) {
                    return !isYoursAccount(userState, i) && !isRole(i)
                }
                else {
                    return !isRole(i)
                }
            })
            .map(u => users[u].photoURL)

        if (showBadge) {
            return <Badge
                    color='primary'
                    badgeContent={conversation._not_readed}
                    className='ConversationBadge'
                >
                    {/* className={`${conversation.dialog ? '' : 'TopicAvatar'}`} */}
                    <Avatar image={images[0]} />
                </Badge>
        }
        else {
            // className={`${conversation.dialog ? '' : 'TopicAvatar'}`}
            return <Avatar image={images[0]}/>
        }
    }
}
