import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getFormatedDate, timestampToDate, isTimestamp} from 'ultra/helpers/date'

import {ukLocale} from '../../../Helpers/locale';
import {useConfigStore} from '../../../Stores/config';

import './index.scss'

export default function DateField(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [className, setClassName] = useState('DateField' + (showErrorText ? ' error' : ''))
    
    const [placeholder] = useState((field?.placeholder ? field?.placeholder : '') + (field?.required ? ' *' : ''))

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = content._city || getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [value, setValue] = useState(null)

    useEffect(() => {
        setClassName('DateField' + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    useEffect(() => {
        const v = content[field.id];

        if (v) {
            if (isTimestamp(v)) {
                setValue(dayjs(timestampToDate(v)))
            } else {
                setValue(dayjs(v, 'DD.MM.YYYY'))
            }
        }
    }, [content[field.id]])

    const onDateChange = (e) => {
        onChange({target: { value: getFormatedDate(e, timeZone) }})
    }

    return (<Grid className={className} key={field.id} item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukLocale}>
            <DesktopDatePicker
                closeOnSelect
                label={placeholder}
                disabled={isDisabled}
                value={value}
                onChange={onDateChange}
                timezone={timeZone}
            />
        </LocalizationProvider>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </Grid>)
}
