import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";
import { NavLink as RouterLink } from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {useLoaderData, useNavigation, useParams, useLocation} from "react-router-dom";
import {normalizeRoute, restorePath} from "ultra/helpers/route";
// import {getClientCity} from 'ultra/configs/general';
// import {hasFullAccess} from 'ultra/helpers/auth';
import {getClientUrl} from 'ultra/configs/general'

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getDocumentHistory, restoreDocument} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';
import {useContentStore} from '../../../../../Stores/content'

import PreloaderTable from '../../../../../Components/Preloaders/PreloaderTable';

import NotPublishedIcon from '../../../../Content/Widgets/NotPublishedIcon';
import {TableView} from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import NodeInlineActions from '../../../../Content/Widgets/NodeInlineActions';
import DateValue from '../../../../Content/Widgets/DateValue';
import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

import {getActions, NodeTableHistoryInlineActions} from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    let {city, did} = useParams();

    const rawNodes = useLoaderData();

    const location = useLocation();

    const {content, setContent} = useContentStore();
    const navigation = useNavigation();
    const {configs} = useConfigStore();

    const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState} = useGlobalCustomContext();

    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор',
        },
        _code: {
            type: 'notification',
            placeholder: 'Тип'
        },
        patch: {
            type: 'history-details',
            placeholder: 'Дані'
        }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_code'],
            hidden: ['patch', '_timestamp'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_code'],
            hidden: ['patch'],
            noHeaders: true,
            unfolded: true,
        })

        // setPermits({})

        setPageTitle('Історія сторінки')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            getDocumentHistory(city, did, location.search)
                .promise
                .then(updateNodes)
        }

        if (nodeState.restoreStart) {
            const reqParams = {...nodeState.restoreStart}

            showBackdropPreloader();
            nodeDispatch({type: NODE_ACTIONS.RESTORE_CLEANED})

            restoreDocument(city, did, reqParams)
                .then(() => {
                    nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                })
                .catch((err) => {
                    showError({snackbarMessage: err.message})
                })
        }

    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateNodes(rawNodes);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateNodes(nodes) {
        setContent(nodes);
        // hideBackdropPreloader()
    }

    const selectClasses = (data) => {
        return data?._code
            ? 'tr_' + data?._code.replace(':', '_')
            : '';
    }

    const title = {
        title: content?.page?.content?.title
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\content'] = {title: "Контент"}

    return <div  className='Content'>
        <div className='HistoryNodesAdminPage'>
            {<TitleWidget permits={permits} content={title} config={config} breadcrumbs={breadcrumbs} />}
            {<BreadcrumbsWidget hideRoot links={breadcrumbs}/>}

            {isMobile && <div className='MobileFiltersLine'>
                <FiltersLine
                    size="small"
                    filters={config?.showFilters?.filterByInline}
                />
            </div>}

            {(!fields) && <PreloaderTable/>}

            {content?.page?.content && <div className='HistoryNodeAdminPageDetails'>

                {!content?.page?.content?._published && <div className='labelWrap'>
                    <span className='label'>Публікація:</span>
                    <span className='labelValue'>
                        <NotPublishedIcon />
                        <span>не опубліковано</span>
                    </span>
                </div>}

                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className='labelWrap'>
                            <span className='label'>Місто:</span>
                            <span className='labelValue'>
                                {city !== DEFAULT_CITY && <Chip className='cityLabel' variant="outlined" label={configs.content.cities[city].city} />}
                                {city === DEFAULT_CITY && <Chip className='cityLabel' variant="outlined" label="Головна" />}
                            </span>
                        </div>

                        <div className='labelWrap'>
                            <span className='label'>dID:</span>
                            <span className='labelValue'>
                                ${did}
                            </span>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {content?.parent && <div className='labelWrap path'>
                            <span className='label'>Посилання:</span>
                            <span className='labelValue'>
                                <RouterLink to={getClientUrl(city, restorePath(normalizeRoute(content.parent._uri)))}>{content.parent.title}</RouterLink>
                                {content?.page?.content?.title && <>
                                    &nbsp;
                                    /
                                    &nbsp;
                                </>}
                                {content?.page?.content?.title && <RouterLink to={getClientUrl(city, restorePath(normalizeRoute(content.page.content._uri)))}>{content?.page?.content?.title}</RouterLink>}
                            </span>
                        </div>}
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <div className='labelWrap'>
                            <span className='label'>Створено:</span>
                            <span className='labelValue'>
                                <UserHistoryLinkByID id={content?.page?.content?._created_by} />
                            </span>
                        </div>

                        <div className='labelWrap'>
                            <span className='label'>Дата створення:</span>
                            <span className='labelValue'>
                                <DateValue value={content?.page?.content?._created} />
                            </span>
                        </div>

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div className='labelWrap'>
                            <span className='label'>Оновлено:</span>
                            <span className='labelValue'>
                                <UserHistoryLinkByID id={content?.page?.content?._updated_by} />
                            </span>
                        </div>

                        <div className='labelWrap'>
                            <span className='label'>Дата оновлення:</span>
                            <span className='labelValue'>
                                <DateValue value={content?.page?.content?._updated} />
                            </span>
                        </div>                        
                    </Grid>
                </Grid>

            </div>}

            {fields && content.children && <TableView
                content={content.children}
                fields={fields}
                showPagination={true}
                tableOptions={tableOptions}
                selectClasses={selectClasses}

                actionsComponent={NodeTableHistoryInlineActions}
                actionsProps={{
                    unfolded: true
                }}
                hasActions={(data, id) => getActions({
                    content: data?.list?.[id],
                    isRemoved: !data?.page?.content?._uri
                })}
            />}

            {content?.page?.content && <NodeInlineActions className="CurrentPageInlineActions" permits={content?.permits} node={content?.page?.content} hideHistory={true} showCleanHistory unfolded={!isMobile} city={city} />}
        </div>
    </div>
}
