import React, {useEffect, useState, useRef} from 'react';
import {isMobile} from "react-device-detect";

import {getTelegramUserId} from '../../../../Helpers/telegram';

import Preloader from '../../../../Components/Preloaders/Preloader';
import {useFullHeightContainerStore} from '../../../../Stores/fullHeightContainer';

import './index.scss';

let timeout

export default function FullHeightContentBlock(props) {
    const {
        preloader = <div className="BlockPreloader"><Preloader /></div>,
        children,
        bottomPadding,
        showPreloader,
        className
    } = props;
    const [height, setHeight] = useState(100);

    const {scrollTo, stopScrollTo} = useFullHeightContainerStore();

    const blockRef = useRef(null);
    const contentRef = useRef(null);

    const innerShowPreloaderRef = React.useRef(showPreloader);
    const [innerShowPreloader, _setInnerShowPreloader] = useState(false)
    const setInnerShowPreloader = data => {
        innerShowPreloaderRef.current = data;
        _setInnerShowPreloader(data);
    };

    // ACCEPT FOR REVESED
    function updateHeight() {
        if (!bottomPadding) return;

        let height = window.innerHeight;
        const contentTopPaddingToBlock = 56;

        const headerHeight = 50;
        const footerHeight = document.querySelector('.Footer') ? 18 : 0;
        const verticalPaddings = isMobile ? 0 : 32;
        const magicalNumber = 38;

        height -= contentTopPaddingToBlock;
        height -= headerHeight;
        height -= footerHeight;
        height -= verticalPaddings;
        height -= magicalNumber;

        height -= bottomPadding;

        if (height < 140 || isNaN(height)) height = 140
        console.log(height)
        if (height) setHeight(height);
    }

    useEffect(() => {
        if (!getTelegramUserId()) {
            window.visualViewport.addEventListener('resize', updateHeight);
        }

        return () => {
            if (!getTelegramUserId()) {
                window.visualViewport.removeEventListener('resize', updateHeight);
            }
        };
    }, [])

    function scrollToStart() {
        contentRef?.current?.scrollTo(0, 72);
    }

    function scrollToEnd() {
        const random_number = 500
        contentRef?.current?.scrollTo(0, contentRef.current?.scrollHeight + random_number);
    }

    function scroll() {
        if (scrollTo === 'start') {
            scrollToStart();
        }
        else if (scrollTo === 'end') {
            scrollToEnd()
        }
        else {
            const el = contentRef?.current?.querySelector(scrollTo);
            if (el) {
                contentRef?.current?.scrollTo(0, el?.offsetTop);
            }
            else {
                scrollToStart();
            }
        }
        stopScrollTo();
    }

    const startScroll = () => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            if (innerShowPreloaderRef.current) {
                startScroll()
            }
            else {
                scroll()
            }
        }, 100)
    }

    useEffect(() => {
        setInnerShowPreloader(showPreloader)
    }, [showPreloader])

    useEffect(() => {
        if (scrollTo) startScroll()
    }, [scrollTo])

    useEffect(() => {
        console.log(bottomPadding)
        updateHeight()
    }, [bottomPadding])

    return <div className={`FullHeightContentBlockWrap ${className}`}>
        <div className={'FullHeightContentBlock'}
            style={{height: `${height}px`}}
            ref={blockRef}
        >
            {showPreloader && preloader}

            <div className="FullHeightContentBlockContent" ref={contentRef}>            
                {children}
            </div>
        </div>
    </div>
}
