import {isEqual} from 'lodash'
import {isMobile} from "react-device-detect";

import {useState, useEffect} from 'react';
import dayjs from 'dayjs';

import {isEmptyObj} from 'ultra/helpers/utils';
import {timestampToDate} from 'ultra/helpers/date';
import Button from '@mui/material/Button';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

// import Grid from '@mui/material/Grid';

import ShopProductPrice from '../ShopProductPrice'
import CurrencyFormated from '../../../../Components/CurrencyFormated'
import {useShoppingStore} from '../../../../Stores/shopping';
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {SHOP_ACTIONS} from '../../Reducers/shop';

import './index.scss'

export default function ShopingCart(props) {
    const {showPrice, item, shop, hideText, fullWidth, submitButton} = props

    const {cart, addProduct} = useShoppingStore()
    
    const [isDisabled, setIsDisabled] = useState(false)

    const {shopDispatch} = useGlobalCustomContext();

    useEffect(() => {
        if (item._type === 'shop-product') {
            if (!item._published || !item.available) {
                setIsDisabled(true)
            }
            else {
                setIsDisabled(false)
            }
        }

        if (item._type === 'tour' || item._type === 'retreat') {
            if (dayjs().isAfter(dayjs(timestampToDate(item.date)))) setIsDisabled(true)
        }
    }, [cart, item]);

    const isSelected = (shop, item, selectedPrice, options) => {
        let i

        // if (item?.price?.form) {
        //     i = cart.findIndex(i => {
        //         return (
        //             (i.product.id === item.did || i.product.id === item._did)
        //             && i.shop.id === shop?.partner?.shop
        //             && isEqual(i.product.price.form, item.price.form)
        //         )
        //     }
        //     );
        //     return i > -1;
        // }

        if (item?.price?.free) {
            i = cart.findIndex(i => {
                return (
                    (i.product.id === item.did || i.product.id === item._did)
                    && i.shop.id === shop?.partner?.shop
                    && i.product.price.free === item.price.free
                    // && i.selectedPrice === selectedPrice
                    // && isEqual(i.options, options)
                )
            }
            );
            return i > -1;
        }

        if (selectedPrice) {
            if (options) {
                i = cart.findIndex(i => 
                    (i.product.id === item.did || i.product.id === item._did)
                    && i.shop.id === shop?.partner?.shop
                    && i.selectedPrice === selectedPrice
                    && isEqual(i.options, options)
                );
            }
            else {
                i = cart.findIndex(i => 
                    (i.product.id === item.did || i.product.id === item._did)
                    && i.shop.id === shop?.partner?.shop
                    && i.selectedPrice === selectedPrice
                );
            }
            return i > -1;
        }

        // i = cart.findIndex(i => 
        //     (i.product.id === item.did || i.product.id === item._did)
        //     && i.shop.id === shop?.partner?.shop

        //     && (i.product.price.form && isEqual(i.product.price.form, item.price.form))
        //     && (i.product.price.options && isEqual(i.product.price.options, item.price.options))
        // )


        if (options) {
            i = cart.findIndex(i => 
                (i.product.id === item.did || i.product.id === item._did)
                && i.shop.id === shop?.partner?.shop
                && isEqual(i.options, options)
            )
        }
        else {
            i = cart.findIndex(i => 
                (i.product.id === item.did || i.product.id === item._did)
                && i.shop.id === shop?.partner?.shop
            )
        }

        return i > -1;
    }

    const onClickHandler = (e, selectedPrice) => {
        e.stopPropagation()

        if (!isEmptyObj(item.options) || !isEmptyObj(item?.addons) || item?.price?.form) {
            shopDispatch({type: SHOP_ACTIONS.OPEN_PRODUCT_OPTIONS, data: {
                product: item,
                shop,
                selectedPrice
            }});
        }
        else {
            addProduct(item, shop, selectedPrice);
            // shopDispatch({type: SHOP_ACTIONS.OPEN_CART});
        }
    }

    if (item?.price?.free) {
        return <div className="ShopingCartButton free">
            <Button
                variant="contained"
                fullWidth
                disabled={isDisabled}
                className={isSelected(shop, item) ? "selected" : ""}
                onClick={onClickHandler}>
                    <AddShoppingCartIcon />
                    {/* &nbsp; */}
                    <span className='text'>
                        Безкоштовно
                    </span>
            </Button>
        </div>
    }

    if (item?.price?.noPrice) {
        return <div className="ShopingCartButton noPrice">
             <Button
                 variant="contained"
                 fullWidth
                 disabled={isDisabled}
                 className={isSelected(shop, item) ? "selected" : ""}
                 onClick={onClickHandler}>
                    {item?.price?.noPricePlaceholder}
                    {!item?.price?.noPricePlaceholder && <>
                        <AddShoppingCartIcon />
                        <span className='text'>Замовити</span>
                        {/* <SupportAgentIcon />
                        &nbsp;
                        <span>Додати в корзину</span> */}
                    </>}
             </Button>
        </div>
    }

    if (item?.price?.prices?.length > 0) {
        return <div className="ShopingCartButton multi">
            {item.price.pricesPlaceholder && <div className='label labelPrices'>{item.price.pricesPlaceholder}:</div>}
            {item.price.prices?.map(value =>
                <div className={isMobile ? `multiPriceItem multiPriceItemMobile` : `multiPriceItem`} key={`shoping-cart-button-${value.id}`}>
                    {isMobile && <>
                        <span className='priceWrap'>
                            <span className='title'>
                                {value.title}
                            </span>
                            {/* <span className='sep'>-</span> */}
                            <span className='price'>
                                {value.free && <span>Безкоштовно</span>}
                                {!value.free && <>
                                        <span className='priceValue'><CurrencyFormated value={value.price} currency={item.price.currency} /></span>
                                        {item.price.prefix && <span className='pricePrefix'>/ {item.price.prefix}</span>}
                                    </>
                                }
                            </span>
                        </span>

                        <Button
                            variant="contained"
                            disabled={isDisabled}
                            className={isSelected(shop, item, value.id) ? "selected" : ""}
                            onClick={(e) => onClickHandler(e, value.id)}>
                                <AddShoppingCartIcon />
                        </Button>
                    </>}

                    {!isMobile && <>
                        <span className='priceWrap'>
                            <span>{value.title} </span>
                            {/* &nbsp; */}
                            <span className='price'>
                                {value.free && <span>Безкоштовно</span>}
                                {!value.free && <>
                                        <span className='priceValue'><CurrencyFormated value={value.price} currency={item.price.currency} /></span>
                                        {item.price.prefix && <span className='pricePrefix'>/ {item.price.prefix}</span>}
                                    </>
                                }
                            </span>
                        </span>
                    
                        <Button
                            variant="contained"
                            disabled={isDisabled}
                            className={isSelected(shop, item, value.id) ? "selected" : ""}
                            onClick={(e) => onClickHandler(e, value.id)}>
                                <AddShoppingCartIcon />
                        </Button>
                    </>}

                </div>
            )}
        </div>
    }

    if (!item?.price?.price) {
        return <></>
    }

    return <div className="ShopingCartButton">
        <Button
            variant="contained"
            fullWidth={fullWidth}
            disabled={isDisabled}
            className={isSelected(shop, item) ? "selected" : ""}
            onClick={onClickHandler}>
                {showPrice ? <>
                    {isSelected(shop, item) ? <AddShoppingCartIcon /> : <ShoppingCartOutlinedIcon />}
                    {/* &nbsp; */}
                    <span className='text'>
                        <ShopProductPrice product={item} />
                    </span>
                </>
                : <>
                    {isSelected(shop, item) ? <AddShoppingCartIcon /> : <ShoppingCartOutlinedIcon />}
                    {!hideText && <span className='text'>{submitButton || isSelected(shop, item) ? "Додати" : "Замовити"}</span>}
                </>
            }
        </Button>
    </div>
}
