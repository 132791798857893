import Button from '@mui/material/Button';
import {useState} from "react";

import {setOrderStatus, getOrderStatusLink} from '../../../../Helpers/order';

import FormContainer from '../../../../Components/Form/FormContainer';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';
import Preloader from '../../../../Components/Preloaders/Preloader';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import './index.scss';

export default function OrderActions(props) {
    const {details} = props;

    const [form, setForm] = useState({})
    const [open, setOpen] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [title, setTitle] = useState()

    const {hideBackdropPreloader} = useBackdropPreloaderStore()

    const actionHandler = (action) => {
        if (setInProgress(true)) return;

        const config = actions[action].config;
        if (config) {
            const link = getOrderStatusLink(details.partner, 'shop', details.shop, details._oid, action);
            // ...actions[action].form
            const f = {
                fields: {},
                fieldsOrder: [],
                form: {
                    submitText: 'Зберегти'
                }
            };

            if (config?.comment) {
                f.fields.comment = {
                    required: true,
                    placeholder: 'Коментар',
                    type: 'textarea'
                }
                f.fieldsOrder.push('comment')
            }

            if (config?.image) {
                f.fields.image = {
                    required: true,
                    placeholder: 'Завантажити картинку',
                    type: 'image'
                }
                f.fieldsOrder.push('image')
            }

            f.form.endpoint = link;

            setForm(f);
            setTitle(actions[action].label);
            setOpen(true);
        }
        else {
            setInProgress(true);
            setOrderStatus(details.partner, 'shop', details.shop, details._oid, action)
                .then(() => {
                    setInProgress(false);
                })
        }
    }

    const afterSubmit = () => {
        setOpen(false);
        hideBackdropPreloader();
    }

    if (!details) return <></>

    const {actions} = details;

    if (!actions) return <></>

    if (details?.status !== details?.status_actual) return <></>

    if (!Object.keys(actions).length) return <></>

    return <div className={`${Object.keys(actions).length === 1 ? 'OrderActions OrderActionsMono' : 'OrderActions'} ${inProgress ? 'inProgress' : ''}`}>
        {inProgress && <div className='preloader'><Preloader /></div>}
        {Object.keys(actions).map(action =>
            <Button
                disabled={inProgress}
                key={`notification_action_${action}`}
                onClick={() => actionHandler(action)}
                fullWidth
                variant='outlined'
                size='small'
            >
                {actions[action].label}
            </Button>
        )}

        <AdaptiveDialog open={open} onClose={() => setOpen(!open)} title={title}>
            <FormContainer
                    config={form}
                    afterSubmit={afterSubmit}
                />
        </AdaptiveDialog>
    </div>
}
