import LinksTile from '../LinksTile';

import './index.scss'

export default function LinksLines(props) {
    const {config, linkHandler, className, showSkeleton, showFilters, autoScrollToActive, showPagination, links, order, badges, label, spacing, src, xs, md, title} = props;

    return <div className={className ? `LinksLinesWrap ${className}Wrap` : `LinksLinesWrap`}>
        <LinksTile
            className={className ? `LinksLines ${className}` : `LinksLines`}
            imgLoading={config?.templateOptions?.imgLoading}
            showSkeleton={showSkeleton}
            showFilters={showFilters}
            showPagination={showPagination}
            title={title}
            linkHandler={linkHandler}
            badges={badges}
            links={links}
            order={order}
            spacing={spacing}
            xs={xs}
            md={md}
            label={label}
            src={src || "banner"}
            autoScrollToActive={autoScrollToActive}
        />
    </div>
}
