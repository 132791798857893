import {isMobile} from "react-device-detect";

import React, {useEffect, useState} from 'react';
import { NavLink as RouterLink } from "react-router-dom";
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {getClientUrl} from 'ultra/configs/general'
import { hasFullAccess } from "ultra/helpers/auth";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getShopHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/Preloaders/PreloaderTable';

import UserHistoryLink from '../../../../Content/Widgets/UserHistoryLink'

import {TableView} from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import OrderInlineActions from '../../../../Content/Widgets/OrderInlineActions';
// import DateValue from '../../../../Content/Widgets/DateValue';
// import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

// import NodeHistoryInlineActions from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initHistory = useLoaderData();

    let {partner, shop} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    // const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore(); // showBackdropPreloader
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _oid: {
            type: 'order',
            placeholder: 'Номер заявки',
            extraData: initHistory
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Замовник',
        },
        data_text: {
            type: 'request',
            placeholder: 'Заявка'
        },
        total: {
            type: 'shop-total',
            placeholder: "До сплати"
        },
        status: {
            type: 'order-status',
            placeholder: "Статус"
        },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_oid'],
            hidden: ['total', 'status', '_init_by', '_timestamp'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_oid', '_init_by', 'total', 'status'],
            hidden: [],
            noHeaders: true,
        })

        setPermits({})

        setPageTitle('Історія замовлень')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initHistory);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateHistory(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    // const selectClasses = (data) => {
    //     return data?._code
    //         ? 'tr_' + data?._code.replace(':', '_')
    //         : '';
    // }

    const content = {
        title: `${history?.partner?.title}`
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\partnership'] = {title: "Партнерська панель"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getShopHistory(partner, shop, url.search)
            .promise
            .then(updateHistory)
    }

    return <div  className='Content'>
            <div className='HistoryShopAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs} />}

                {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {fields && history && <TableView
                    content={history}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    // actions={(id) => OrderInlineActions({
                        // content: history?.list?.[id],
                        // city: getClientCity(window) || DEFAULT_CITY
                    // }})}
                    // selectClasses={selectClasses}
                />}

                <Grid container spacing={2} className='HistoryShopAdminPageDetails'>
                    {history?.link && <Grid item xs={12} className='labelWrap'>
                        <span className='label'>Посилання:</span>
                        <span className='labelValue'>
                            <RouterLink to={getClientUrl(history?.partner.city, restorePath(normalizeRoute(history?.link._uri)))}>{history?.link.title}</RouterLink>
                        </span>
                    </Grid>}
                    {history?.access && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Доступ:</span>
                        <span className='labelValue'>
                            {history.access.map(i => 
                                <Chip label={i.displayName ? (hasFullAccess(userState.user) ? <UserHistoryLink id={i.id} name={i.displayName} /> : i.displayName) : i} variant="outlined" />
                            )}
                            {/* <RouterLink to={restorePath(normalizeRoute(history.form._uri))}>{history.form.title}</RouterLink> */}
                        </span>
                    </Grid>}
                    {history?.manager && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Менеджер:</span>
                        <span className='labelValue'>
                        <Chip label={<>
                                {history.manager.conversation && <span>
                                    {history.manager?.conversation?.private && 'Приватний чат '}
                                    {history.manager?.channel?.private && 'Приватний канал'}

                                    {!history.manager?.conversation?.private && 'Публічний чат '}
                                    {!history.manager?.channel?.private && 'Публічний канал'}
                                </span>}

                                {history?.manager?.channel && <span>Канал сповіщень {history.manager.channel}</span>}
                            </>} variant="outlined" />
                            {history.manager.contact && <Chip label={hasFullAccess(userState.user) ? <UserHistoryLink id={history.manager.contact.id} name={history.manager.contact.displayName} /> : history.manager.contact.displayName} variant="outlined" />}
                        </span>
                    </Grid>}
                    {history?.owners && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Власник:</span>
                        <span className='labelValue'>
                            {history.owners.map(i => <Chip label={i.displayName || i} variant="outlined" />)}
                            {/* <RouterLink to={restorePath(normalizeRoute(history.form._uri))}>{history.form.title}</RouterLink> */}
                        </span>
                    </Grid>}
                </Grid>
            </div>
        </div>
}
