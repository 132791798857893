var _a = require('./utils'), isEmptyObj = _a.isEmptyObj, CurrencyFormat = _a.CurrencyFormat;
export function getPartnerDetails(page, breadcrumbs) {
    var node;
    var uri;
    if (!page || !breadcrumbs)
        return null;
    if (page === null || page === void 0 ? void 0 : page.partner) {
        return {
            uri: page._uri,
            node: page
        };
    }
    Object.keys(breadcrumbs).map(function (i) {
        var _a;
        if (!isEmptyObj(node))
            return false;
        if ((_a = breadcrumbs[i]) === null || _a === void 0 ? void 0 : _a.partner) {
            node = breadcrumbs[i];
            uri = i;
        }
    });
    return { uri: uri, node: node };
}
export function getNodeCatalogs(page, breadcrumbs) {
    var categories;
    if (!page || !breadcrumbs)
        return null;
    if (page === null || page === void 0 ? void 0 : page.categories) {
        return page === null || page === void 0 ? void 0 : page.categories;
    }
    Object.keys(breadcrumbs)
        .sort()
        .map(function (i) {
        var _a, _b;
        if ((_a = breadcrumbs[i]) === null || _a === void 0 ? void 0 : _a.categories) {
            categories = (_b = breadcrumbs[i]) === null || _b === void 0 ? void 0 : _b.categories;
        }
    });
    return categories;
}
export function totalPrice(items) {
    var sum = {};
    items.map(function (item) {
        var _a, _b, _c, _d;
        var optionsAdditionalPrice = 0;
        if (item.options) {
            Object.keys(item.options).map(function (option) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                if ((_e = (_d = (_c = (_b = (_a = item.details) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.list) === null || _c === void 0 ? void 0 : _c[option]) === null || _d === void 0 ? void 0 : _d.list) === null || _e === void 0 ? void 0 : _e[item.options[option]].correction)
                    optionsAdditionalPrice += (_k = (_j = (_h = (_g = (_f = item.details) === null || _f === void 0 ? void 0 : _f.options) === null || _g === void 0 ? void 0 : _g.list) === null || _h === void 0 ? void 0 : _h[option]) === null || _j === void 0 ? void 0 : _j.list) === null || _k === void 0 ? void 0 : _k[item.options[option]].correction;
            });
        }
        if (item.addons) {
            Object.keys(item.addons).map(function (addon) {
                Object.keys(item.addons[addon]).map(function (id) {
                    var p = item.details.addons.list[addon].list[id];
                    if (!p.free)
                        optionsAdditionalPrice += item.addons[addon][id] * p.price;
                });
            });
        }
        if ((_b = (_a = item === null || item === void 0 ? void 0 : item.details) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency) {
            var currency = item.details.price.currency.toLowerCase();
            sum[currency] = sum[currency] || 0;
            if (item.selectedPrice) {
                var price = item.details.price.prices.find(function (i) { return i.id === item.selectedPrice; }) || {};
                if (!price.free) {
                    sum[currency] += item.amount * (Number(price.price) + optionsAdditionalPrice);
                }
                else {
                    sum[currency] += item.amount * optionsAdditionalPrice;
                }
            }
            else {
                var price = item.details.price.price - (((_d = (_c = item.details) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.discount) || 0) || 0;
                if (!price.free) {
                    sum[currency] += item.amount * (Number(price) + optionsAdditionalPrice);
                }
                else {
                    sum[currency] += item.amount * optionsAdditionalPrice;
                }
            }
        }
    });
    var result = [];
    if (sum) {
        Object.keys(sum).map(function (currency) {
            if (sum[currency]) {
                result.push(CurrencyFormat(sum[currency], currency));
            }
        });
    }
    return result.join(" + ");
}
