export default function ShopReducer(state, action) {
    switch (action.type) {
        case ACTIONS.OPEN_CART:
            return {
                ...state,
                openCard: true
            };

        case ACTIONS.CLOSE_CART:
            return {
                ...state,
                openCard: false
            };

        case ACTIONS.OPEN_PRODUCT_OPTIONS:
            return {
                ...state,
                openProductOptions: action.data
            };

        case ACTIONS.CLOSE_PRODUCT_OPTIONS:
            return {
                ...state,
                openProductOptions: null
            };

        case ACTIONS.OPEN_EDIT_PRODUCT_OPTIONS:
            return {
                ...state,
                openEditProductOptions: action.data
            };

        case ACTIONS.CLOSE_EDIT_PRODUCT_OPTIONS:
            return {
                ...state,
                openEditProductOptions: null
            };
    
        default:
            return state
    }
}

const ACTIONS = {
    OPEN_CART: 'shop:open:cart',
    CLOSE_CART: 'shop:close:cart',
    OPEN_PRODUCT_OPTIONS: 'shop:open:product:options',
    CLOSE_PRODUCT_OPTIONS: 'shop:close:product:options',
    OPEN_EDIT_PRODUCT_OPTIONS: 'shop:open:edit:product:options',
    CLOSE_EDIT_PRODUCT_OPTIONS: 'shop:close:edit:product:options',
}

export const SHOP_ACTIONS = ACTIONS

export const createShopInitialState = () => {
    return {
        openCard: null,
        openProductOptions: null,
        openEditProductOptions: null,
    }
}
