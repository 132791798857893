import React from 'react';
import {redirect} from "react-router-dom";

import ErrorPage from './Modules/Content/Pages/ErrorPage';
import AppWrap from './Modules/Content/Pages/AppWrap';

import {getConfigs} from './Helpers/config'

import {checkRedirect} from './Helpers/redirect';

export const routes = [
  {
    path: "/",
    element: <AppWrap />,
    loader: async ({request}) => {
      const redirectURL = await checkRedirect(request);

      if (redirectURL) {
        return redirect(new URL(redirectURL).pathname);
      }

      const data = await getConfigs().promise;
      return new Promise(resolve => resolve(data));
    },
    errorElement: <ErrorPage />,
    children: []
  },    
]
