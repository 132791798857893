// import {hasFullAccess} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
// import NodeDetails from '../../../Components/NodeDetails'
import OrderActions from '../../../../OrderActions';

export default function Template(props) {
    const {values, short} = props;

    const {userState} = useGlobalCustomContext();

    // const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);
    // const fullAccess = hasFullAccess(userState?.user);

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    // if (initByCurrentUser) {
    //     if (!values.details) {
    //         return <div className="NotificationTitle">
    //             Опубліковано
    //         </div>
    //     }
    //     if (values.details) {
    //         return <div className="NotificationTitle">
    //             <>Ви опубліковали </>
    //             <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
    //         </div>
    //     }
    // } 

    if (!initByCurrentUser) {
        if (short) {
            return <div className="NotificationTitle">
                Запит на оформлення фідбеку
            </div>
        }

        return <>
            <div className="NotificationTitle">
                Форма для оформлення фідбеку
            </div>
            {/* <OrderActions details={values.details} /> */}
        </>
    }
    else {
        if (short) {
            return <div className="NotificationTitle">
                Запит на оформлення фідбеку
            </div>
        }

        return <>
            <div className="NotificationTitle">
                Запит на фідбек
            </div>
            {/* <OrderActions details={values.details} /> */}
        </>
    }
}
