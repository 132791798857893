import {isString} from 'lodash';
import React, {useEffect, useState} from 'react';

import {getClientCity} from 'ultra/configs/general';

import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {useToasterStore} from '../../../Stores/toster';
import {getTags} from '../../../Helpers/content'

export default function Tags(props) {
    const {content, disabled, field, isEditMode, error, onChange, showErrorText} = props
    const multiple = !Boolean(field?.options?.single);
    const defaultValue = multiple ? [] : '';

    const {showError} = useToasterStore();

    const [value, setValue] = useState(defaultValue)
    const [list, setList] = useState([])

    const [regexpHighlight, setRegexpHighlight] = useState(false)

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        const city = content._city || getClientCity(window)

        if (content._type) {
            getTags(city, content._type, field.id)
                .promise
                .then(res => {
                    setList(res.tags || [])
                })
        }
    }, [content._type])

    useEffect(() => {
        if (content[field.id]) {
            setValue(content[field.id] || defaultValue)
        } else {
            setValue(defaultValue)
        }

    }, [content[field.id]])

    const onKeyDownHandler = (e) => {
        if (!field?.options?.regexp) return false;

        const regex = new RegExp(field.options.regexp);
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;

        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
            setRegexpHighlight(true);
        }
        else {
            setRegexpHighlight(false);
        }
    }

    return (<>
        <Autocomplete
            multiple={multiple}
            freeSolo

            fullWidth
            
            options={list.map((option) => option)}
            
            autoSelect={true}

            value={value}

            getOptionLabel={(option) => {
                if (isString(option)) {
                    return option
                }
                else {
                    return ''
                }
            }}

            onChange={(e, value) => {
                if (multiple) {
                    if (field?.options?.max && value.length > field.options.max) {
                        showError({snackbarMessage: `Для поля "${field.placeholder}" ви можете обрати не більше ${field.options.max} значень`})
                        return;
                    }

                    onChange({target: {value: value.map(i => i.trim())}})
                }
                else {
                    onChange({target: {value}})
                }
            }}

            renderTags={(value, get) => {
                    if (multiple) {
                        return value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...get({index})} />
                        ))
                    }
                    else {
                        return <span>{value}</span>;
                    }
                }
            }

            disabled={isDisabled}

            renderInput={(params) => (
                <TextField
                    {...params}

                    onKeyDown={onKeyDownHandler}

                    variant='outlined'
                    label={field.placeholder}
                    required={field.required}

                    placeholder='Введіть значення та натисніть Enter'

                    error={Boolean(showErrorText)}
                    helperText={showErrorText && error}
                />
                
            )}
        />
        {field?.details && <div className={`fieldNotes ${regexpHighlight ? 'fieldNotesRegexpError' : 'fieldNotes'}`}>
            <p>{field?.details}</p>
        </div>}
    </>)
}
