import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom"
// import { isMobile } from 'react-device-detect';
import {debounce, isNull} from 'lodash';
import DOMPurify from "dompurify"

import {revertPath, restorePath, normalizeRoute} from 'ultra/helpers/route';

import {getClientCity, getClientCityTimezone} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {removeHTML} from 'ultra/helpers/utils';
import {timestampToDate, getFormatedTime} from 'ultra/helpers/date'

import Badge from '@mui/material/Badge';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import SearchIcon from '@mui/icons-material/Search';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Fab from '@mui/material/Fab';

import {useConfigStore} from '../../../../../../Stores/config';
import {useSearchStore} from '../../../../../../Stores/search';
import {useContentStore} from '../../../../../../Stores/content';
// import {useHistoryStore} from "../../../../Stores/history";
import NodeImage from '../../../../Widgets/NodeImage';
import ContentImage from '../../../../Widgets/ContentImage';
import FilterChip from '../../../../Widgets/FilterChip'
// import CKEditorContent from '../../../../Widgets/CKEditorContent'
import MessengerValue from '../../../MessengerValue'
import URLValue from '../../../../Widgets/URLValue'

import Preloader from '../../../../../../Components/Preloaders/Preloader';

import {getVirtualUri} from '../../../../../../Helpers/content';
import {searchNode} from '../../../../../../Helpers/search';
// import {filterContent} from '../../../../Helpers/content';

import './index.scss';

function SearchResultNodeTags(props) {
    const {node} = props;

    let result = <></>

    const {configs} = useConfigStore();

    switch (node.type) {
        case 'book':
            if (node.genre?.length > 0) {
                result = <div className='searchResultItemTags'>
                    {node.genre && <div>
                        {node.genre.map(genre => <FilterChip key={`search_book_genre_${genre}`} value={genre} config={configs.content.nodes['book'].fields.genre} />)}
                    </div>}
                </div>
            }
            break;
    }
    
    return result;
}

function SearchResultNodeRows(props) {
    const {node} = props;

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()

    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    let result = <></>

    switch (node.type) {
        case 'recommendation':
            if (!node.region && !node.messenger && !node.url) {
                result = <></>
            }
            else {
                result = <div className='searchResultItemDetails recommendation'>
                    {/* {node.category && <div className='labelWrap'>
                        <div className="label">Категорія:</div>
                        <FilterChip className="textOnly" value={node.category} config={configs.content.nodes[node.type]?.fields?.category} />
                    </div>} */}

                    {node.region && <div className='labelWrap'>
                        <div className="label">Район:</div>
                        {node.region && <FilterChip className="textOnly" value={node.region} config={configs.content.nodes[node.type]?.fields?.region} />}
                    </div>}
                    {node.messenger && <div className='labelWrap'>
                        <span className="label">Месенджер:</span>
                        <span className="labelValue">
                            <MessengerValue messenger={node.messenger} />
                        </span>
                    </div>}
                    {node.url && <div className='labelWrap'>
                        <span className="label">Посилання:</span>
                        <span className="labelValue">
                            <URLValue value={node.url} />
                        </span>
                    </div>}
                </div>
            }
            break;

        case 'book':
            result = <div className='searchResultItemDetails book'>
                {node.author && <div className='labelWrap'>
                    <span className="label">Автор:</span>
                    <span className="labelValue">
                        {node.author.join(', ')}
                    </span>
                </div>}
            </div>
            break;

        case 'event':
        case 'travel':
        case 'retreat':
            result = <div className={`searchResultItemDetails ${node.type}`}>
                {node.date && <div className='labelWrap'>
                    <span className="label">Дата та час:</span>
                    <span className='labelValue'>
                        {getFormatedTime(timestampToDate(node.date), timeZone)}
                    </span>
                </div>}
            </div>
            break;

        case 'analogue':
            result = <div className='searchResultItemDetails analogue'>
                {node.analogue && <div className='labelWrap'>
                    <span className="label">Аналоги:</span>
                    <span className="labelValue">
                        {node.analogue.join(', ')}
                    </span>
                </div>}
                {node.active_substances && <div className='labelWrap'>
                    <span className="label">Діючі речовини:</span>
                    <span className="labelValue">
                        {node.active_substances.join(', ')}
                    </span>
                </div>}
                {node.international && <div className='labelWrap'>
                    <span className="label">Міжнародна назва:</span>
                    <span className="labelValue">
                        {node.international}
                    </span>
                </div>}
            </div>
            break;
    }

    return result;
}

function SearchResultNode(props) {
    const {className, highlights, node, closeSearchResults} = props;

    const {configs} = useConfigStore();

    const navigate = useNavigate();

    const searchResultHandler = (uri) => {
        closeSearchResults();
        navigate(restorePath(uri))
    }

    const goToPage = (path) => {
        closeSearchResults();
        navigate(path);
    }

    return <div className={className}>
        <div className='searchResultItemContent'>
            {(node.thumbnail || node.poster) && <NodeImage values={node} className='thumbnail'/>}

            <div className='content'>
                <div className='searchResultItemParent'>
                    <span className='pseudoLink' onClick={() => goToPage(restorePath(node?._parent))}>
                        {node?._parent_title}
                    </span>
                </div>
                <div className='searchResultItemTitle'>{node.title}</div>

                {node.banner && !node.thumbnail && !node.poster && <ContentImage src="banner" className="searchResultItemBanner" image={node.banner} />}

                {node.category && <div className='category'><FilterChip className="textOnly" value={node.category} config={configs.content.nodes[node.type]?.fields?.category} /></div>}

                {node.description && highlights?.filter(highlight => highlight?.field !== 'title')?.length === 0 && <div className='description'>{removeHTML(node.description).substring(1, 120)} {removeHTML(node.description).length > 120 && <>...</>}</div>}

                <SearchResultNodeRows node={node} />

                {highlights?.filter(highlight => highlight?.field !== 'title')?.length > 0 && <div className='searchResultItemHighlights'>
                    {highlights
                        ?.filter(highlight => highlight?.field !== 'title')
                        ?.map(highlight => highlight?.snippet && <div className='searchResultItemHighlight' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('...' + highlight.snippet + '...')}} />)}
                </div>}

                <SearchResultNodeTags node={node} />

                <div className='searchResultItemLink'>
                    <span className="emulateButton small" onClick={() => {closeSearchResults(); searchResultHandler(node._uri)}}>Детальніше</span>
                </div>
            </div>
        </div>
    </div>
}

let controllers = []

export default function GlobalSearchNodeButton(props) {
    const {showActions} = props

    const location = useLocation();
    const {displaySearch, search, cleanSearch, setSearch, setDisplaySearch} = useSearchStore();
    const {content} = useContentStore();

    // const [searchResult, setSearchResult] = useState([]);

    // const [showSearch, _setShowSearch] = useState(false)

    // const showSearchRef = React.useRef(showSearch);
    // const setShowSearch = data => {
    //     showSearchRef.current = data;
    //     _setShowSearch(data);
    // };

    const [requestInProgress, setRequestInProgress] = useState(false)
    const [showAdditionalResults, setShowAdditionalResults] = useState(false)

    const onSearchTextChange = async (e) => {
        try {
            controllers.map(c => {c?.abort('New request')});
        }
        catch(e) {}

        setRequestInProgress(false)

        const value = e.target.value;

        if (value?.length < 4) {
            cleaneSearchResults()
            return;
        }

        if (!value?.length) {
            cleaneSearchResults()
            return;
        }

        const city = getClientCity(window) || DEFAULT_CITY;

        setRequestInProgress(true)

        const uri = revertPath(normalizeRoute(getVirtualUri() || location.pathname));
        const {promise, controller} = await searchNode(city, value, uri)
        controllers.push(controller)

        promise.then(result => {
            if (displaySearch) { // showSearchRef.current
                setSearch(value, uri, result);

                setRequestInProgress(false);

                window.scrollTo(0, 0);
            }
        })
    }

    const onSearchToggle = () => {
        if (displaySearch) { // showSearchRef.current
            controllers.map(c => {c?.abort('Hide search')})
            cleaneSearchResults()

            document.body.classList.remove('search')
        }
        else {
            document.body.classList.add('search')
        }

        setDisplaySearch(!displaySearch); // showSearchRef.current
    }

    const cleaneSearchResults = () => {
        cleanSearch()
    }

    const closeSearchResults = () => {
        cleaneSearchResults();
        setDisplaySearch(false);
    }

    // useEffect(() => {
    //     const keyDownHandler = event => {
    //         if (event.key === 'Escape') {
    //             event.preventDefault();

    //             if (displaySearch) onSearchToggle(); // showSearchRef.current
    //             // document.body.classList.remove('search')
    //             // closeSearchResults();
    //         }
    //     };

    //     document.addEventListener('keydown', keyDownHandler);

    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };
    // }, [])

    const filterVirtualCatalogsResults = (i) => {
        const node = search?.result?.global?.list[i];

        if (node._parent && content.children.list[node._parent] && content.page.content.virtualCatalogs) return false;
        if (node._parent === content.page.content._uri) return false;

        return true;
    }

    return <>
        <Slide direction="left" in={!showActions && !displaySearch}>
            <div className='SearchNodeButton'>
                <Fab color="contrast" onClick={onSearchToggle}><SearchIcon /></Fab>
            </div>
        </Slide>

        <Slide direction='left' in={displaySearch} mountOnEnter unmountOnExit>
            <div className="SearchWrap">
                <div className="SearchWrapContainer">
                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        onChange={debounce(onSearchTextChange, 500)}
                        className='SearchWrapInput'
                    />

                    <SearchIcon className='SearchWrapIcon'/>

                    {<span className="SearchWrapClose" onClick={onSearchToggle}>
                        <CancelIcon />
                    </span>}
                </div>
                <div className='searchResultItemWrap'>
                    {requestInProgress && <div className='searchResultPreloader'>
                        <Preloader />
                    </div>}

                    { (search?.result?.children?.order?.length > 0 || search?.result?.global?.order?.filter(i => !filterVirtualCatalogsResults(i)).length > 0)
                        && search?.result?.global?.order?.filter(filterVirtualCatalogsResults)?.length > 0
                        && <div className='searchResultItemWrapResults' onClick={() => {setShowAdditionalResults(!showAdditionalResults)}}>
                            <span className='results'>
                                <Badge
                                    color='error'
                                    badgeContent={search?.result?.global?.order?.filter(filterVirtualCatalogsResults)?.length}
                                >
                                    Результати пошуку на інших сторінках
                                </Badge>
                            </span>

                            {showAdditionalResults && <KeyboardArrowUpIcon />}
                            {!showAdditionalResults && <KeyboardArrowDownIcon />}
                        </div>
                    }

                    {(
                        (
                            search?.result?.children?.order?.length === 0
                            && search?.result?.global?.order?.filter(i => !filterVirtualCatalogsResults(i)).length === 0
                            && search?.result?.global?.order?.filter(filterVirtualCatalogsResults)?.length > 0
                        )
                        || showAdditionalResults
                        )
                        &&
                        <div className='searchResultSectionResult'>
                            {search?.result?.global?.order
                                ?.filter(filterVirtualCatalogsResults)
                                ?.map(node => <SearchResultNode
                                    className='searchResultItem'
                                    key={`search_global_${node}`}
                                    node={search.result.global.list[node]}
                                    highlights={search.result.global.highlights[node]}
                                    closeSearchResults={closeSearchResults}
                                />)
                            }
                        </div>
                    }
                </div>
            </div>
        </Slide>

        {(
            (
                displaySearch
                && 
                (
                    isNull(search?.query)
                    ||
                    (
                        search?.result?.children?.order?.length === 0
                        && search?.result?.global?.order?.filter(i => !filterVirtualCatalogsResults(i)).length === 0
                    )
                )
            )
            || requestInProgress
            || showAdditionalResults
        ) && <Backdrop
            onClick={onSearchToggle}
            sx={{zIndex: (theme) => theme.zIndex.modal + 1}}
            open={displaySearch}
        ></Backdrop>}
    </>
}
