import {isMobile} from "react-device-detect";

import React, {useEffect, useState} from 'react';
// import { NavLink as RouterLink } from "react-router-dom";
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

// import {normalizeRoute, restorePath} from "ultra/helpers/route";
// import {getClientUrl} from 'ultra/configs/general'
// import { hasFullAccess } from "ultra/helpers/auth";

// import Grid from '@mui/material/Grid';
import {DEFAULT_CITY} from 'ultra/const/general';
import Chip from '@mui/material/Chip';
import {Button} from "@mui/material";
import {getClientCity} from 'ultra/configs/general';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getShopOrderHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/Preloaders/PreloaderTable';

// import UserHistoryLink from '../../../../Content/Widgets/UserHistoryLink'

import OrderActions from '../../../../Content/Widgets/OrderActions';
import {TableView} from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import OrderInlineActions from '../../../../Content/Widgets/OrderInlineActions';
import OrderDetails from '../../../../Content/Widgets/Notification/Components/OrderDetails';
// import DateValue from '../../../../Content/Widgets/DateValue';
// import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

// import NodeHistoryInlineActions from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
// import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import {makeUrlsClickable} from '../../../../../Helpers/format';

import './index.scss';

export default () => {
    const initData = useLoaderData();

    let {partner, shop, order} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    // const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore(); // showBackdropPreloader
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();
    const [orderData, setOrderData] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор',
        },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        },
        _action: {
            type: 'order-status-action',
            placeholder: "Статус",
            extraData: initData
        },
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_action'],
            hidden: ['_init_by', '_timestamp'],
            noHeaders: true

        } : {
            visible: ['_timestamp',  '_init_by', '_action'],
            hidden: [],
            noHeaders: true
        })

        // updateHistory(initData);

        setPermits({})

        setPageTitle('Історія замовленя')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initData);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateHistory(data) {
        setHistory({...data})
        setOrderData(data._order)
        hideBackdropPreloader()
    }

    // const selectClasses = (data) => {
    //     return data?._code
    //         ? 'tr_' + data?._code.replace(':', '_')
    //         : '';
    // }

    const content = {
        // title: `${history?.partner?.title}`
        title: order
    }

    const breadcrumbs = {}
    // breadcrumbs['\\profile\\partnership\\'] = {title: "Партнерська панель"}
    breadcrumbs[`\\profile\\history\\shop\\${partner}\\${shop}`] = {title: history?._partner?.title}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getShopOrderHistory(partner, shop, order, url.search)
            .promise
            .then(data => {
                updateHistory(data);
            })
    }

    return <div  className='Content'>
            <div className='HistoryShopOrderAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs} />}

                {orderData && <div className="orderStatus">
                    <div className="labelWrap">
                        <span className="label">Статус:</span>
                        <span className="labelValue">
                            <Chip variant="outlined" label={orderData?.status_label} />
                        </span>
                    </div>
                </div>}

                {/* {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>} */}

                {(!fields) && <PreloaderTable/>}

                {orderData && fields && history && <>
                        <div className="orderDetailsAction">
                            <pre dangerouslySetInnerHTML={{ __html: makeUrlsClickable(orderData.data_text) }}></pre>
                        </div>

                        <OrderActions details={orderData} />

                        <TableView
                            content={history}
                            fields={fields}
                            showPagination={true}
                            tableOptions={tableOptions}
                            noDataText="Історія відсутня"
                            // actions={(id) => OrderInlineActions({
                            //     content: history?.list?.[id],
                            //     city: getClientCity(window) || DEFAULT_CITY
                            // })}
                            // selectClasses={selectClasses}
                        />
                    </>
                }

            </div>
        </div>
}
