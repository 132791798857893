import {DEFAULT_CITY} from 'ultra/const/general';
import {getUrl, getClientConfig} from 'ultra/configs/general';

import {getPathByDID} from './content';

export const checkRedirect = (request) => new Promise(async resolve => {
    const url = new URL(request.url);

    // GO TO DID
    let params = url.searchParams;

    let goToDid
    let goToCid
    let goToLogin
    let goToOrder
    let goToProfile
    let bot

    if (params.get("tgWebAppStartParam")) {
        const p = params.get("tgWebAppStartParam");

        const options = p?.split('-AND-');
        options.map(option => {
            if (option.includes('telegram=')) {
                const v = option.split('telegram=');
                if (v[1]) bot = v[1]
            }
            else {
                const v = option.split('---');

                // ?tgWebAppStartParam=goToDid---default___l35rkAdRWCasblUdjV8U
                if (v[0] === 'goToDid') {
                    goToDid = v[1].replace('___', ':');
                }
        
                // ?tgWebAppStartParam=goToCid---l35rkAdRWCasblUdjV8U___l35rkAdRWCasblUdjV8U
                else if (v[0] === 'goToCid') {
                    goToCid = v[1].replace('___', ':');
                }
        
                // ?tgWebAppStartParam=goToLogin
                else if (v[0] === 'goToLogin') {
                    goToLogin = true;
                }
            }
        })
    }
    else {
        if (params.get("goToDid")) {
            goToDid = params.get("goToDid");
        }

        else if (params.get("goToCid")) {
            goToCid = params.get("goToCid");
        }

        // ?goToLogin=true
        else if (params.get("goToLogin")) {
            goToLogin = true;
        }

        else if (params.get("goToOrder")) {
            goToOrder = params.get("goToOrder");
        }

        else if (params.get("goToProfile")) {
            goToProfile = params.get("goToProfile");
        }
    }

    const {domain} = getClientConfig(window);

    if (goToDid) {
      const linkDetails = goToDid.split(':');
      if (linkDetails.length !== 2) {
        console.error('Wrong link format');
      }
      else {
        const city = linkDetails[0];
        const did = linkDetails[1];

        try {
            let link = await getPathByDID(city, did).promise;
            if (bot) link += `?telegram=${bot}`;

            return resolve(getUrl(domain, city, link))
        }
        catch(e) {}
      }
    }

    else if (goToCid) {
        const linkDetails = goToCid.split(':');

        const conversation = linkDetails[0];
        const message = linkDetails[1];

        try {
            let url = `/profile/messages/${conversation}`;
            if (message) url += `?mid=${message}`
            if (bot) {
                if (!message) {
                    url += `?telegram=${bot}`;
                }
                else {
                    url += `&telegram=${bot}`;
                }
            }

            return resolve(getUrl(domain, DEFAULT_CITY, url))
        }
        catch(e) {}
    }

    else if (goToLogin) {
        let link = '/profile/login';
        if (bot) link += `?telegram=${bot}`;

        return resolve(getUrl(domain, DEFAULT_CITY, link))
    }

    else if (goToOrder) {}

    else if (goToProfile) {}

    resolve(undefined)
})
