// import {isMobile} from "react-device-detect";
import {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';

import { isAdmin } from "ultra/helpers/auth";

import {useGlobalCustomContext} from '../../../../Helpers/context';

import TitleWidget from '../../../Content/Widgets/Title';
import FormHistoryLink from '../../../Content/Widgets/FormHistoryLink'
import ShopHistoryLink from '../../../Content/Widgets/ShopHistoryLink'
import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';

import './index.scss';

const TITLE = 'Партнерська Панель';

const compact = true; // isMobile

function getLabel(label) {
    if (label === 'admin') return 'Адмін'
    if (label === 'manager') return 'Менеджер'
    // if (label === 'access') return 'Доступ до статистики'
    return ''
}

function Forms(props) {
    const {forms} = props;

    return forms.map(item => <div className='PartnerLinksItem'>
        <FormHistoryLink details={item} title={item.title} />
        {item.access && item.access.map(i => <Chip variant='outlined' label={getLabel(i)} />)}
    </div>)
}

function PartnersForms(props) {
    const {forms} = props;

    const {userState} = useGlobalCustomContext();

    const [partners, setPartners] = useState({});
    const [opened, setOpened] = useState({form: {}});

    const expandSection = (type, partner) => {
        opened[type][partner] = !Boolean(opened[type][partner]);
        setOpened(opened);
    }

    useEffect(() => {
        const list = {};
        userState?.user?.partnership?.forms?.map(item => {
            list[item.partner] = list[item.partner] || [];
            list[item.partner].push(item);
        })
        setPartners(list)
    }, [forms])

    return Object.keys(partners).map(partner => <Accordion key={partner} expanded={compact ? opened?.['form']?.[partner] : true}>
        {/* expandIcon={<ExpandMoreIcon />} */}
        <AccordionSummary expandIcon={<Badge badgeContent={partners[partner].length} />} onClick={() => expandSection('form', partner)}>
            {userState.user.partnership._info[partner].title}
        </AccordionSummary>

        <AccordionDetails>
            <Forms forms={partners[partner]} />
        </AccordionDetails>
    </Accordion>)
}

function Shops(props) {
    // const {userState} = useGlobalCustomContext();
    const {shops} = props;

    return shops.map(item => <div className='PartnerLinksItem'>
        <ShopHistoryLink details={item} title={item.title} />
        {item.access && item.access.map(i => <Chip variant='outlined' label={getLabel(i)} />)}
    </div>)
}

function PartnersShops(props) {
    const {shops} = props;

    const {userState} = useGlobalCustomContext();

    const [partners, setPartners] = useState({});
    const [opened, setOpened] = useState({shop: {}});

    const expandSection = (type, partner) => {
        opened[type][partner] = !Boolean(opened[type][partner]);
        setOpened(opened);
    }

    useEffect(() => {
        const list = {};
        userState?.user?.partnership?.shops?.map(item => {
            list[item.partner] = list[item.partner] || [];
            list[item.partner].push(item);
        })
        setPartners(list)
    }, [shops])

    return Object.keys(partners).map(partner => <Accordion key={partner} expanded={compact ? opened?.['shop']?.[partner] : true}>
        {/* expandIcon={<ExpandMoreIcon />} */}
        <AccordionSummary expandIcon={<Badge badgeContent={partners[partner].length} />} onClick={() => expandSection('shop', partner)}>
            {userState.user.partnership._info[partner].title}
        </AccordionSummary>

        <AccordionDetails>
            <Shops shops={partners[partner]} />
        </AccordionDetails>
    </Accordion>)
}

export default () => {
    const {userState} = useGlobalCustomContext();

    const config = {};
    const permits = {};
    const breadcrumbs = {};
    const content = {
        title: TITLE,
    };

    // const [fullAccess, setFullAccess] = useState(false);
    const [forms, setForms] = useState({});
    const [shops, setShops] = useState({});

    useEffect(() => {
        setForms(userState?.user?.partnership?.forms);
        setShops(userState?.user?.partnership?.shops);

        // setFullAccess(isAdmin(userState.user));
    }, [userState?.user?.partnership])

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // show on natifications changed
    if (!userState?.user?.partnership) {
        return <div  className='Content'>
            <PreloaderTable />
        </div>
    }

    return <div  className='Content'>
        <div className='PartnershipAdminPage'>
            <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />

            <Grid container spacing={2}>
                {forms?.length > 0 && <Grid item xs={12} md={6}>
                    <div className='PartnerLinksSection'>Форми:</div>
                    <div className='PartnerLinks'>
                        {!isAdmin(userState?.user) && <Forms forms={forms} />}
                        {isAdmin(userState?.user) && <PartnersForms forms={forms} />}
                    </div>
                </Grid>}

                {shops?.length > 0 && <Grid item xs={12} md={6}>
                    <div className='PartnerLinksSection'>Магазини:</div>
                    <div className='PartnerLinks'>
                        {!isAdmin(userState?.user) && <Shops shops={shops} />}
                        {isAdmin(userState?.user) && <PartnersShops shops={shops} />}
                    </div>
                </Grid>}
            </Grid>
        </div>
    </div>
}
