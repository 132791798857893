import {useState} from "react";
import {useNavigate} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';

import Collapse from '@mui/material/Collapse';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {restorePath} from "ultra/helpers/route";
import {getUserRoles} from 'ultra/helpers/auth';
import {hasRole} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../../../../../../Helpers/context';
import {transferBook} from '../../../../../../../../../Helpers/library'
import {isYoursAccount} from '../../../../../../../../../Helpers/user'
import {getTelegramUser} from '../../../../../../../../../Helpers/telegram';

import {useToasterStore} from '../../../../../../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../../../../../../Stores/backdropPreloader';

import Preloader from '../../../../../../../../../Components/Preloaders/Preloader';
import AdaptiveDialog from '../../../../../../../../../Components/Adaptive/Dialog';

import {NODE_ACTIONS} from '../../../../../../../Reducers/node';
import {USER_ACTIONS} from '../../../../../../../../Profile/Reducers/user';

import MessageToOwner from '../MessageToOwner';

export default function ShowLinks(props) {
    const {content, belongsToLibrary} = props;

    const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    const [requestInProgress, setRequestInProgress] = useState()
    const [openTransferBook, setOpenTransferBook] = useState(false)

    const {showPromt, showError, closeToaster} = useToasterStore();
    const {hideBackdropPreloader} = useBackdropPreloaderStore()

    const {nodeDispatch, userDispatch, userState} = useGlobalCustomContext();

    const navigate = useNavigate();

    const roles = getUserRoles(userState?.user, getTelegramUser(), content)

    function handleTransferBookClose() {
        setOpenTransferBook(false)

        if (openTransferBook) {
            navigate(restorePath(content._parent))
        }
    }

    function transferBookHandler() {
        showPromt({
            snackbarMessage: 'Бажаєте передати книгу до української бібліотеки на Балі',
            onApproveHandler: () => {
                closeToaster();
                hideBackdropPreloader();

                setOpenTransferBook(true)

                setRequestInProgress(true)

                transferBook(city, content._uri)
                    .then(() => {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
                        setRequestInProgress(false)
                    })
                    .catch((e) => {
                        setRequestInProgress(false)
                        showError({snackbarMessage: e.message})
                    })
            },
            onCloseHandler: () => {
                closeToaster();
                hideBackdropPreloader();
            }
        })

    }

    return <div className='links'>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {/* TODO: add endpoint */}
                <Button size="small" startIcon={<MenuBookOutlinedIcon />} fullWidth variant="outlined" href="https://t.me/+uTViBgJooU00ZDQy" target="_blank">Перейти до бібліотеки</Button>
            </Grid>

            <Grid item xs={12} md={6}>
                <Button size="small" startIcon={<LibraryBooksOutlinedIcon />} fullWidth variant="outlined" onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: 'g0jb7AElHeLpnF7ezk11' }})}>Ознайомитись з правилами</Button>
            </Grid>

            {!hasRole(roles, ["library"]) && isYoursAccount(userState, content._created_by) && <Grid item xs={12} md={6}>
                <Button size="small" startIcon={<CollectionsBookmarkOutlinedIcon />} fullWidth variant="outlined" onClick={transferBookHandler}>Передати книгу до бібліотеки</Button>
            </Grid>}
        </Grid>

        {/* TRANSFER BOOK */}
        <AdaptiveDialog open={openTransferBook} onClose={handleTransferBookClose} title={`Передача книги "${content.title}"`}>
            <Collapse in={requestInProgress}>
                <div className="progressBlock">
                    <Preloader />
                </div>
            </Collapse>
            <Collapse in={!requestInProgress}>
                <p className="titleEmojiText titleEmojiTitle">Книга успішно зареєстрована</p>
                <p className="titleEmoji">🙏</p>
                <p className="titleEmojiText">Зв'яжіться з бібліотекою, щоб передати книгу</p>

                <MessageToOwner belongsToLibrary={belongsToLibrary} book={content} onClick={handleTransferBookClose}/>
            </Collapse>
        </AdaptiveDialog>
    </div>
}
