import React, { useEffect, useState } from 'react';
import {isEqual} from 'lodash';
import {useNavigate} from "react-router-dom";
import {NavLink as RouterLink} from "react-router-dom";
import {isMobile} from "react-device-detect";

import {isEmptyObj} from 'ultra/helpers/utils';
import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {totalPrice} from "ultra/helpers/shop";
import {attachUserData} from 'ultra/helpers/auth';
import {CurrencyFormat} from 'ultra/helpers/utils';

import FormHelperText from '@mui/material/FormHelperText';

import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Fab from '@mui/material/Fab';

import Collapse from '@mui/material/Collapse';

import {useSearchStore} from '../../../../../../Stores/search';
import {useShoppingStore} from '../../../../../../Stores/shopping';
import {useConfigStore} from '../../../../../../Stores/config';
import {useToasterStore} from '../../../../../../Stores/toster';

import {getContentShopsConfig} from '../../../../Helpers/shops';
import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import Preloader from '../../../../../../Components/Preloaders/Preloader';
import FormContainer from '../../../../../../Components/Form/FormContainer';
import CurrencyFormated from '../../../../../../Components/CurrencyFormated';
import AdaptiveDialog from '../../../../../../Components/Adaptive/Dialog';
import Textarea from '../../../../../../Components/Form/Textarea';

import ShopProductPrice from '../../../ShopProductPrice';
// import DefaultImage from '../../../DefaultImage';
import ContentImage from '../../../ContentImage';

import {SHOP_ACTIONS} from '../../../../Reducers/shop';

import PartnerImage from '../../../PartnerImage';

import './index.scss'

const orderByTitle = (a, b) => a.details?.title?.localeCompare(b.details.title);
const orderProducts = (a, b) => {
    let aId = a.details?.title;
    let bId = b.details.title;

    aId += '_' + a.selectedPrice + '_' + (a.options ? JSON.stringify(a.options) : 'no') + '_' + (a.form ? JSON.stringify(a.form) : 'no');
    bId += '_' + b.selectedPrice + '_' + (b.options ? JSON.stringify(b.options) : 'no') + '_' + (b.form ? JSON.stringify(b.form) : 'no');

    return aId?.localeCompare(bId);
};

// function ShortOrderDescription(props) {
//     const {orders} = props;

//     const navigate = useNavigate();

//     const {shopDispatch} = useGlobalCustomContext();

//     const onEditProductOption = (product) => {
//         shopDispatch({type: SHOP_ACTIONS.OPEN_EDIT_PRODUCT_OPTIONS, data: product});
//     }

//     const onClick = (uri) => {
//         shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
//         navigate(restorePath(normalizeRoute(uri)));
//     }

//     if (!orders || orders.length === 0) return null

//     return <div className='ShortOrderDescription'>
//         <div>Ваше замовлення:</div>
//         {orders.map(order => <div className='ShortOrderDescriptionItem' key={order.details.id}>
//             <span>
//                 - <span className='pseudoLink' onClick={() => onClick(order.details._uri)}>{order.details.title}</span>

//                 {order.selectedPrice && <> (
//                     {order.details.price.placeholder && <>{order.details.price.placeholder?.toLowerCase()}:</>}
//                     {order.details.price.prices.find(i => i.id === order.selectedPrice)?.title}
//                 )</>}

//                 &nbsp;- {order.amount}
//             </span>

//             {(!isEmptyObj(order.details?.options?.list) || !isEmptyObj(order?.details?.addons?.list) || !isEmptyObj(order?.details?.price?.form))
//                 &&
//                 <span className='editProductOptions' onClick={() => onEditProductOption(order)}>
//                     <EditOutlinedIcon />
//                 </span>
//             }
//         </div>)}
//     </div>
// }

function EditForm(props) {
    const {errors, values, placeholder, settings, onChange} = props
    
    const [form, setForm] = useState({})

    const [formContent, setFormContent] = useState();

    useEffect(() => {
        const form = {}
        form.fields = {}

        if (settings) {
            Object.keys(settings).map(id => {
                form.fields[id] = {
                    type: "textarea",
                    placeholder: settings[id],
                    required: true,
                    options: {
                        minRows: 1
                    }
                }
            })

            setForm(form);
        }
    }, [])

    useEffect(() => {
        setFormContent({...values});
    }, [values])

    if (isEmptyObj(settings)) return <></>
    if (isEmptyObj(form)) return <></>

    return <>
        {placeholder && <Grid item xs={12}>
            <span className='label'>
                {placeholder}:
            </span>
        </Grid>}

        {Object.keys(settings).map(id => <Grid key={`edit_form_${id}`} item xs={12}><Textarea
            key={`edit_form_${id}`}
            content={formContent}
            showErrorText={errors?.[id] ? 'Поле має буде заповнене' : ''}
            field={{
                id,
                placeholder: settings[id],
                required: true,
                options: {
                    minRows: 1
                }
            }}
            onChange={(e) => {
                const data = {...formContent};
                const v = e.target.value;
                data[id] = v;

                setFormContent(data);
                onChange(data);
            }}
        /></Grid>)}
    </>
}

function EditOptions(props) {
    const {values, errors, product, onChange} = props

    const [editOptions, setEditOptions] = useState()

    useEffect(() => {
        if (values) {
            setEditOptions(values)
        }
        else {
            setEditOptions({})
        }
    }, [])

    const onChangeEditProductOption = (option, value) => {
        const v = {...editOptions};
        v[option] = value;
        setEditOptions(v);
        onChange(v);
    }

    if (isEmptyObj(product?.options?.list) || !editOptions) return <></>

    return <Grid item xs={12} >
      <Grid container spacing={2}>
        {product?.options?.placeholder && <Grid item xs={12}>
            <span className='label'>
                {product.options.placeholder}:
            </span>
        </Grid>}
        {Object.keys(product.options.list)
            .map(option =>
                <Grid item xs={12} key={'product_options_' + option}>
                    <FormControl fullWidth required={true} error={errors?.[option]}>
                        <InputLabel>{product.options.list[option].title}</InputLabel>
                        <Select
                            label={product.options.list[option].title}
                            defaultValue=""
                            value={editOptions[option] || ""}
                            onChange={(e) => onChangeEditProductOption(option, e.target.value)}
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {Object.keys(product.options.list[option].list)
                                .filter(i => product?.options.list[option].list[i].available)
                                .map(i => <MenuItem key={i} value={i}>
                                    <span>{product.options.list[option].list[i].title}</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className='productOptionAdditionalPrice'>
                                        {product.options.list[option].list[i].correction > 0 && <>+</>}
                                        {product.options.list[option].list[i].correction < 0 && <>-</>}
                                        {product.options.list[option].list[i].correction !== 0 ? 
                                            CurrencyFormat(product.options.list[option].list[i].correction, product.price.currency)
                                            : ''
                                        }
                                    </span>
                                </MenuItem>)}
                        </Select>
                        {errors?.[option] && <FormHelperText>Поле має буде заповнене</FormHelperText>}
                    </FormControl>
                </Grid>
            )
        }
      </Grid>
    </Grid>
}

function EditAddons(props) {
    const {values, addons, currency, onChange} = props;

    const [addonsQuantity, setAddonsQuantity] = useState()
    const [addonsChecked, setAddonsChecked] = useState()

    useEffect(() => {
        const checked = {}
        const quantity = {}

        if (addons?.list) {
            Object.keys(addons.list)?.map(addon => {
                Object.keys(addons.list[addon].list).map(id => {
                    checked[addon] = checked[addon] || {}
                    checked[addon][id] = false
                })
            })
        }

        if (values) {
            Object.keys(values).map(addon => {
                Object.keys(values[addon]).map(id => {
                    checked[addon][id] = true

                    quantity[addon] = quantity[addon] || {}
                    quantity[addon][id] = values[addon][id]
                })
            })
        }
        else if (addons?.list) {
            Object.keys(addons.list)?.map(addon => {
                quantity[addon] = {}
            })
        }

        setAddonsChecked(checked)
        setAddonsQuantity(quantity)
    }, [])

    const getAddons = (checked, quantity) => {
        const result = {};

        Object.keys(checked)
            .map(addon => {
                Object.keys(checked[addon])
                .filter(id => checked[addon][id])
                .map(id => {
                    // const item = addons[addon].list[id]

                    // if (item) {
                        result[addon] = result[addon] || {}
                        result[addon][id] = quantity[addon][id]
                    // }
                })
            })

        return result;
    }

    const handleAddOption = (addon, id) => {
        let values = {...addonsQuantity}
        if (!values[addon][id]) {
            values[addon][id] = 1
        }

        values[addon][id] += 1

        setAddonsQuantity(values)

        onChange(getAddons(addonsChecked, values))
    }

    const handleRemoveOption = (addon, id) => {
        let checked = {...addonsChecked}
        let values = {...addonsQuantity}

        if (values[addon][id] === 1) {
            delete checked[addon][id];
            delete values[addon][id];

            setAddonsQuantity(values);
            setAddonsChecked(checked);

            onChange(getAddons(checked, values));
            return;
        }

        if (!values[addon][id]) {
            values[addon][id] = 1
        }

        values[addon][id] = values[addon][id] - 1
        setAddonsQuantity(values)

        onChange(getAddons(checked, values))
    }

    const handleAddonChecked = (addon, id) => {
        let checked = {...addonsChecked}
        let quantity = {...addonsQuantity}

        checked[addon][id] = !Boolean(checked[addon][id])
        setAddonsChecked(checked)

        if (checked[addon][id]) quantity[addon][id] = 1

        setAddonsQuantity(quantity)
        onChange(getAddons(checked, quantity))
    }

    if (!addonsChecked) return <></>

    return <>
        {addons && Object.keys(addons.list).map(addon => <Grid item xs={12} key={'product_addons_' + addon}>
            <Grid container>
                {addons.list[addon]?.title && <Grid item xs={12}>
                    <span className='label'>
                        {addons.list[addon]?.title}:
                    </span>
                </Grid>}
                {addons.list[addon]?.list && Object.keys(addons.list[addon].list).map(id => <Grid item xs={12} className='productAddonsSelectList' key={'product_addons_' + id}>
                    {/* {.map(id => <Grid container key={'product_addons_' + addon + "_" + id}> */}
                    <Grid container>
                        <Grid item xs={6} md={6} className='currencyTitle'>
                            <FormControlLabel
                                onChange={() => handleAddonChecked(addon, id)}
                                control={
                                    <Checkbox checked={addonsChecked[addon][id]} />
                                }
                                label={
                                    <span className="title">{addons.list[addon].list[id].title}</span>
                                }
                            />
                        </Grid>

                        <Grid item xs={6} md={3} className='currencyPrice' onClick={() => handleAddonChecked(addon, id)}>
                            <div className='price'>
                                {!addons.list[addon].list[id].free && <CurrencyFormated value={addons.list[addon].list[id].price} currency={currency} />}
                                {addons.list[addon].list[id].free && <>Безкоштовно</>}
                            </div>
                        </Grid>

                        {<Grid item xs={12} md={3} className='quantityCell'>
                            {addonsChecked[addon][id] && addons.list[addon].list[id].counter && <div className='quantity'>
                                <span className='label'>Кількість:</span>

                                <div className='wrap'>
                                    <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemoveOption(addon, id)}/>
                                    {addonsQuantity[addon][id]}
                                    <AddCircleOutlineIcon className='btn' onClick={() => handleAddOption(addon, id)}/>
                                </div>
                            </div>}
                        </Grid>}

                    </Grid>
                </Grid>)}
            </Grid>
        </Grid>)}
    </>
}

export default function ShoppingCardButton(props) {
    const {disabled, showActions} = props;

    const {cart, clearCard, addProduct, updateProduct} = useShoppingStore()
    const {displaySearch} = useSearchStore();

    const {configs, setConfig} = useConfigStore();
    const [isLoading, setIsLoading] = useState(false)

    const [shops, setShop] = useState([])

    const [formErors, setFormErors] = useState(null)
    const [optionsErors, setOptionsErrors] = useState(null)

    const [options, setOptions] = useState(null)
    const [addons, setAddons] = useState(null)
    const [form, setForm] = useState(null)

    const {showError} = useToasterStore();
    const {shopState, shopDispatch} = useGlobalCustomContext();

    useEffect(() => {
        let updatedShops = []

        // get stores
        cart.map(item => {
            if (updatedShops.find(i => i.details.id === item.shop.id)) return;
            updatedShops.push({details: {...item.shop}})
        })

        // get items
        cart.map(item => {
            const shopIndex = updatedShops.findIndex(i => i.details.id === item.shop.id)
            updatedShops[shopIndex].items = updatedShops[shopIndex].items || []

            const itemIndex = updatedShops[shopIndex].items.findIndex(i =>
                i.details.id === item.product.id
                && i.selectedPrice === item.selectedPrice
                && isEqual(i.options, item.options)
                && isEqual(i.addons, item.addons)
                && isEqual(i.form, item.form)
            )

            if (itemIndex > -1) {
                updatedShops[shopIndex].items[itemIndex].amount += 1
            }
            else {
                updatedShops[shopIndex].items.push({
                    details: {...item.product},
                    selectedPrice: item.selectedPrice,
                    options: item.options,
                    addons: item.addons,
                    form: item.form,
                    amount: 1
                })
            }
        })

        // sort
        updatedShops = updatedShops.sort(orderByTitle)
        updatedShops.map(i => {
            i.items = [...i.items.sort(orderByTitle)]
        })

        setShop(updatedShops)
    }, [cart])

    useEffect(() => {
        // get shops config, if it's not present
        if (!configs.shop && cart.length > 0) {
            setIsLoading(true)
            getContentShopsConfig()
                .promise
                .then(shopConfig => {
                    const newConfig = {...configs};
                    newConfig.shop = shopConfig;
                    setConfig(newConfig);
                    setIsLoading(false);
                })
        }
    }, [shopState?.openCard])

    const handleClose = () => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
        setOptionsErrors(null);
        setFormErors(null);
    };

    const handleOpen = (e) => {
        if (disabled) return;
        shopDispatch({type: SHOP_ACTIONS.OPEN_CART});
    };

    const handleProductOptionsClose = (e) => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});
        setOptionsErrors(null);
        setFormErors(null);
    }

    const isEmpty = () => {
        let emptyOptions = {};
        let emptyForm = {};

        const availableOptions = {};

        const optionsData = options || shopState?.openEditProductOptions?.options;
        const formData = form || shopState?.openEditProductOptions?.form;

        const o = shopState?.openProductOptions?.product?.options?.list || shopState?.openEditProductOptions?.details?.options?.list;
        const f = shopState?.openProductOptions?.product?.price?.form || shopState?.openEditProductOptions?.details?.price?.form;

        let hasEmptyOption = false;
        if (o) {
            Object.keys(o).map(option => {
                if (Object.keys(o[option].list)
                    .filter(i => o[option]?.list[i].available)
                    ?.length > 0
                ) {
                    availableOptions[option] = true
                }
            })

            Object.keys(availableOptions).map(option => {
                emptyOptions[option] = !Boolean(optionsData?.[option]);
            })

            hasEmptyOption = Object.keys(emptyOptions).filter(option => emptyOptions[option]).length > 0
        }


        let hasEmptyForm = false;
        if (f) {
            Object.keys(f).map(field => {
                if (!formData?.[field]) {
                    emptyForm[field] = true
                }
            })

            hasEmptyForm = Object.keys(emptyForm).filter(option => emptyForm[option]).length > 0
        }

        if ((o && hasEmptyOption) || (f && hasEmptyForm)) {
            showError({
                snackbarMessage: "У формі присутні незаповнені дані"
            })

            setOptionsErrors(hasEmptyOption ? emptyOptions : null)
            setFormErors(hasEmptyForm ? emptyForm : null)

            return emptyOptions || emptyForm;
        }
        else {
            return false;
        }
    }

    const onAddProductHandler = () => {
        if (isEmpty()) return;

        addProduct(
            shopState?.openProductOptions?.product,
            shopState?.openProductOptions?.shop,
            shopState?.openProductOptions?.selectedPrice,
            options,
            addons,
            form
        );

        // shopDispatch({type: SHOP_ACTIONS.OPEN_CART});
        shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});
        cleanData();
    }

    const onUpdateProductOptionsHandler = () => {
        if (isEmpty()) return;

        updateProduct(
            shopState?.openEditProductOptions?.details,
            shopState?.openEditProductOptions?.selectedPrice,
            shopState?.openEditProductOptions?.options,
            shopState?.openEditProductOptions?.addons,
            shopState?.openEditProductOptions?.form,
            null,
            options,
            addons,
            form
        );

        cleanSettings();
    }

    const cleanData = () => {
        setForm(null);
        setOptions(null);
        setAddons(null);

        setOptionsErrors(null);
        setFormErors(null);
    }

    const cleanSettings = (e) => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_EDIT_PRODUCT_OPTIONS});
        cleanData();
    }

    const onOptionsChange = (options) => {
        setOptionsErrors(null);
        setOptions(options)
    }

    const onAddonsChange = (addons) => {
        setAddons(addons)
    }

    const onFormChange = (form) => {
        setFormErors(null);
        setForm(form);
    }

    function handleClear() {
        clearCard();
        shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
    }

    return <>
        <AdaptiveDialog
            open={Boolean(shopState?.openCard)}
            onClose={handleClose}
            title={<>
                <ShoppingCartIcon className='icon'/>
                Корзина
                <Button size='small' className='clear' onClick={handleClear} variant="outlined" fullWidth>
                    {isMobile ? 'Очистити' : 'Очистити корзину'}
                </Button>
            </>}
        >
            <Cart
                onClose={handleClose}
                isLoading={isLoading}
                configs={configs}
                shops={shops}
            />
        </AdaptiveDialog>

        <AdaptiveDialog
            open={Boolean(shopState?.openEditProductOptions)}
            onClose={cleanSettings}
            title={<><ShoppingCartIcon className='icon' /> {isMobile ? 'Редагувати' : 'Редагувати замовлення'}</>}
        >
            {shopState?.openEditProductOptions && !shopState?.openEditProductOptions?.form && <ProductDetails
                className="EditProductOptions"
                onClose={handleClose}
                config={shopState?.openEditProductOptions?.details}
                product={shopState?.openEditProductOptions}
                options={options}
                addons={addons}
            />}

            <Grid container spacing={2}>
                <EditForm
                    errors={formErors}
                    values={shopState?.openEditProductOptions?.form}
                    placeholder={shopState?.openEditProductOptions?.details?.price?.formPricePlaceholder}
                    settings={shopState?.openEditProductOptions?.details?.price?.form}
                    onChange={onFormChange}
                />

                <EditOptions
                    className='productOptionsSelectList'
                    values={shopState?.openEditProductOptions?.options}
                    product={shopState?.openEditProductOptions?.details}
                    errors={optionsErors}
                    onChange={onOptionsChange}
                />

                <EditAddons
                    values={shopState?.openEditProductOptions?.addons}
                    addons={shopState?.openEditProductOptions?.details?.addons}
                    currency={shopState?.openEditProductOptions?.details?.price?.currency}
                    onChange={onAddonsChange}
                />

                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        className='button'
                        onClick={cleanSettings}>
                            Відміна
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        className='button'
                        onClick={onUpdateProductOptionsHandler}>
                            {isMobile ? 'Оновити' : 'Оновити замовлення'}
                    </Button>
                </Grid>
            </Grid>
        </AdaptiveDialog>

        <AdaptiveDialog
            open={Boolean(shopState?.openProductOptions)}
            onClose={handleProductOptionsClose}
            title={<><ShoppingCartIcon className='icon' /> Деталі замовлення</>}
        >
            <div className='ProductDetailsWrap'>
                {shopState?.openProductOptions && !shopState?.openProductOptions?.product?.price?.form && <ProductDetails
                    className="AddProductOptions"
                    onClose={handleProductOptionsClose}
                    config={shopState?.openProductOptions?.product}
                    product={shopState?.openProductOptions}
                    options={options}
                    addons={addons}
                />}

                <Grid container spacing={2}>
                    <EditForm
                        errors={formErors}
                        placeholder={shopState?.openProductOptions?.product?.price?.formPricePlaceholder}
                        settings={shopState?.openProductOptions?.product?.price?.form}
                        onChange={onFormChange}
                    />

                    <EditOptions
                        className='productOptionsSelectList'
                        product={shopState?.openProductOptions?.product}
                        errors={optionsErors}
                        onChange={onOptionsChange}
                    />

                    <EditAddons
                        addons={shopState?.openProductOptions?.product?.addons}
                        currency={shopState?.openProductOptions?.product?.price?.currency}
                        onChange={onAddonsChange}
                    />

                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            className='button'
                            onClick={() => {
                                shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});
                                setOptionsErrors(null);
                                setFormErors(null);
                            }}>
                                Відміна
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            className='button'
                            onClick={onAddProductHandler}>
                                {isMobile ? 'В корзину' : 'Додати в корзину'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </AdaptiveDialog>

        {cart?.length > 0 && <Slide direction="left" in={!showActions && !displaySearch}>
            <Badge className='shoppingCartIconBadge' badgeContent={cart.length} color="error">
                <Fab
                    className='shoppingCartIcon'
                    color="contrast"
                    onClick={handleOpen}>
                        <AddShoppingCartIcon />
                </Fab>
            </Badge>
        </Slide>}
    </>
}

function ProductDetails(props) {
    const {className, onClose, product, config, options, addons} = props;

    const navigate = useNavigate();

    if (!product || !config) return <></>

    const navigateToProductPage = (uri) => {
        navigate(restorePath(uri));
        onClose();
    }

    return <div className={`${className ? `ProductDetails ${className}` : `ProductDetails`} ${(config?.price?.noPrice || config?.price?.free) ? 'NoPrice' : ''}`}>
        <ContentImage
            onClick={() => navigateToProductPage(config._uri)}
            src="thumbnail"
            city={config.city}
            image={config.thumbnail}
            className='picture'/>

        <div>
            <div className='title'>
                <span className='wrap' onClick={() => navigateToProductPage(config._uri)}>
                    {config.title}
                </span>
            </div>
            {product.selectedPrice && <div className='subtitle'>
                {config?.price?.placeholder && <>{config?.price?.placeholder}: </>}
                <> {config?.price?.prices?.find(i => i.id === product.selectedPrice)?.title}</>
            </div>}

            {(!config?.price?.form && !config?.price?.noPrice) &&
                <div className='price'>
                    <span className='labelWrap labelWrapRow'>
                        <span className='label'>Ціна:</span>
                        <span className='labelValue'>
                            <ShopProductPrice
                                product={config}
                                selectedPrice={product.selectedPrice}
                                addons={addons || product.addons}
                                options={options || product.options}
                            />
                            {/* {config.price.prefix && <span className='pricePrefix'>/ {config.price.prefix}</span>} */}
                        </span>
                    </span>
                </div>
            }
        </div>
    </div>
}

function Cart(props) {
    const {onClose, isLoading, configs, shops} = props;

    const {cart, addProduct, removeProduct, removeShop} = useShoppingStore()

    const [openOrderForm, setOpenOrderFrom] = React.useState(null);
    const [openOrderShop, setOpenOrderShop] = React.useState();

    const [openedProductDetails, setOpenedProductDetails] = React.useState();

    const {userState, shopDispatch} = useGlobalCustomContext();

    const navigate = useNavigate();
    const moreThenOneShop = shops.length > 1

    function onEditHandler() {
        setOpenOrderFrom(null)
    }

    const handleOrder = (storeId) => {
        setOpenOrderFrom(storeId)
    }

    const handleAdd = (productId, storeId, selectedPrice, options, addons, form) => {
        const item = cart.find(i =>
            i.product.id === productId
            && i.shop.id === storeId
            && i.selectedPrice === selectedPrice
            && isEqual(i.options, options)
            && isEqual(i.addons, addons)
            && isEqual(i.form, form)
        )

        if (item) addProduct(
            item.product,
            item.shop,
            selectedPrice,
            options,
            addons,
            form
        );
    }

    const handleRemove = (productId, storeId, selectedPrice, options, addons, form) => {
        const item = cart.find(i =>
            i.product.id === productId
            && i.shop.id === storeId
            && i.selectedPrice === selectedPrice
            && isEqual(i.options, options)
            && isEqual(i.addons, addons)
            && isEqual(i.form, form)
        )

        if (item) {
            if (cart.length === 1) shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
            removeProduct(item.product, selectedPrice, options, addons, form)
        }
        else {
            if (cart.length === 0) shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
        }
    }

    const shopTitleClickHendler = (storeId) => {
        setOpenOrderFrom(null)

        if (storeId === openOrderShop) {
            setOpenOrderShop(null)
        } else {
            setOpenOrderShop(storeId)
            setOpenOrderFrom(null)
        }
    }

    const navigateToShopPage = (shop) => {
        // const shop = {...shops.find(i => i.details.id === storeId)};
        // custom nodes (like tours), not shop-product, can have custom link to navigate - did
        if (shop.link) {
            const link = `/?goToDid=${shop.city}:${shop.link}`;
            navigate(link);
            onClose();
        }
    }

    const afterOrder = () => new Promise(async resolve => {
        removeShop(openOrderForm, (newCart) => {
            if (newCart.length === 0) {
                onClose();
            }
        });
        setOpenOrderFrom(null);
        resolve();
    })

    function getTotalPrice(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return totalPrice(shop?.items);
    }

    function getItemsForShop(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return shop.items
    }

    function getItemsForOrderField(storeId) {
        const data = {order: getItemsForShop(storeId)};
        return data
    }

    function getFormForShopConfig(storeId) {
        const shop = shops.find(i => i.details.id === storeId)
        let result = {
            form:  {
                endpoint: `/partner/${shop.details.city}/shop/${shop.details.partner}/${shop.details.id}`,
                snackbarDuration: 10000
            }
        }

        // add partner and shop
        console.log(shop.details?.orderForm)
        console.log(configs.shop)
        // result.form.endpoint += shop.details._uri;
        result.form.submitText = shop.details?.orderForm?.form?.submitText || configs.shop.form.submitText;
        result.form.successMessage = shop.details?.orderForm?.form?.successMessage || configs.shop.form.successMessage;

        result.fields = shop.details?.orderForm?.fields || configs.shop.fields
        result.fieldsOrder = shop.details?.orderForm?.fieldsOrder || configs.shop.fieldsOrder

        // add order hidden field
        // TODO: use same code used on server side
        result.fields.order = {
            options: {
                hidden: true,
            },
            placeholder: 'Замовлення',
            required: true,
            type: 'json',
        }

        const defaultValues = {}
        Object.keys(result.fields).map(field => {
          if (result.fields[field].default) {
            const defVal = attachUserData(result.fields[field].default, userState?.user)
    
            if (defVal) {
              defaultValues[field] = defVal
            }
          }
        })
        result.defaultValues = defaultValues;

        if (shop?.details?.orderForm?.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...shop.details.orderForm.defaultValues}
        }
        else if (configs.shop.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...configs.shop.defaultValues}
        }

        return result
    }

    const isShopNotCollapsed = (storeId) => {
        if (openOrderForm) {
            return false
        }

        if (!moreThenOneShop) {
            return true
        }

        return openOrderShop === storeId && openOrderForm !== storeId;
    }

    const onEditProductOption = (product) => {
        shopDispatch({type: SHOP_ACTIONS.OPEN_EDIT_PRODUCT_OPTIONS, data: product});
    }

    const isEqualProduct = (a, b) => {
        // i.details.id === item.product.id
        // && 
        return a?.selectedPrice === b?.selectedPrice
            && isEqual(a?.options, b?.options)
            && isEqual(a?.addons, b?.addons)
            && isEqual(a?.form, b?.form)
        // return isEqual(a, b)
    }

    const displayDetails = (product) => {
        if (isEqualProduct(openedProductDetails, product)) {
            setOpenedProductDetails(null)    
        }
        else {
            setOpenedProductDetails(product)
        }
    }

    if (isLoading) return <div className="Cart">
        <div className='CartPreloaderWrap'><Preloader /></div>
    </div>
console.log(shops)
    return <div className="Cart">
        {shops.map((store) =>
            <div className='shopItem' key={'cart_shop_item_' + store.details.id}>
                <div className={isShopNotCollapsed(store.details.id) ? 'shopTitle active' : 'shopTitle'}>
                    <div className='titleWrap'>
                        <span className='title'>
                            <PartnerImage
                                id={store.details.partner}
                                shop={store.details.id}
                                className={`Logo Logo32 ${store.details.link ? 'Clickable' : ''}`}
                                onClick={() => navigateToShopPage(store.details)}
                            />

                            <span className='titleInfo'>
                                <span>
                                    <span className='name' onClick={() => shopTitleClickHendler(store.details.id)}>
                                        {store.details.title}
                                    </span>
                                </span>


                                <span className='ShortOrderDescriptionPrice'>
                                    {/* <div>Ціна:</div> */}
                                    <span className='price'>{getTotalPrice(store.details.id)}</span>
                                    {!isShopNotCollapsed(store.details.id) && <span className='q'>
                                        <Chip variant="outlined" label={
                                            `${store?.items?.length} товар${(store?.items?.length > 1 && store?.items?.length < 5) ? 'и' : ''}${store?.items?.length > 4 ? 'ів' : ''}`
                                        } />
                                    </span>}
                                </span>
                            </span>
                        </span>
                        {moreThenOneShop && <span onClick={() => shopTitleClickHendler(store.details.id)}>
                            <KeyboardArrowUpIcon className='icon up' />
                            <KeyboardArrowDownIcon className='icon down' />
                        </span>}
                    </div>
                </div>

                <Collapse in={isShopNotCollapsed(store.details.id)}>
                    <div className='productsList'>
                        {store?.items?.sort(orderProducts).map(product =>
                        <div className='product' key={'cart_shop_product_item_' + store.details.id + '_' + product.details.id + '_' + product.selectedPrice + '_' + (product.options ? JSON.stringify(product.options) : 'no') + '_' + (product.form ? JSON.stringify(product.form) : 'no')}>
                            {(!isEmptyObj(product.details?.options?.list) || !isEmptyObj(product?.details?.addons?.list) || !isEmptyObj(product.details?.price.form))
                                && <span className='editProductOptions' onClick={() => onEditProductOption(product)}>
                                    <EditOutlinedIcon />
                                </span>
                            }

                            <ProductDetails
                                onClose={onClose}
                                config={product?.details}
                                product={product}
                            />

                            {/* {product?.details?.price?.form && <div className='actions' onClick={() => handleRemove(
                                    product.details.id,
                                    store.details.id,
                                    product.selectedPrice,
                                    product.options,
                                    product.addons,
                                    product.form
                                )}>
                                    <RemoveCircleOutlineIcon className='btn'/>
                                    <span>Видалити</span>
                            </div>} */}

                            {/* {!product?.details?.price?.form &&  */}
                            <div className='quantity'>
                                <div className='wrap'>
                                    <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemove(
                                        product.details.id,
                                        store.details.id,
                                        product.selectedPrice,
                                        product.options,
                                        product.addons,
                                        product.form
                                    )
                                    }/>
                                    {product.amount}
                                    <AddCircleOutlineIcon className='btn' onClick={() => handleAdd(
                                        product.details.id,
                                        store.details.id,
                                        product.selectedPrice,
                                        product.options,
                                        product.addons,
                                        product.form
                                    )}/>
                                </div>
                            </div>
                            {/* } */}

                            {(!isEmptyObj(product.form) || !isEmptyObj(product.options) || !isEmptyObj(product.addons)) && <div className={isEqualProduct(openedProductDetails, product) ? `details opened` : 'details'} onClick={() => displayDetails(product)}>
                                <span className='pseudoLink'>Деталі замовлення</span> 
                                <KeyboardArrowUpIcon className='icon up' />
                                <KeyboardArrowDownIcon className='icon down' />
                            </div>}

                            <Collapse in={isEqualProduct(openedProductDetails, product)}>
                                {product.form && !isEmptyObj(product.form) && <div className='form'>
                                    {Object.keys(product.form).map(id => <span className='labelWrap labelWrapRow' key={'cart_shop_product_item_flexible' + store.details.id + '_' + product.details.id + '_' + id}>
                                        <span className='label'>{product.details?.price?.form?.[id]}:</span>
                                        <span className='labelValue'>
                                            {product.form[id]}
                                        </span>
                                    </span>)}
                                </div>}

                                {product.options && !isEmptyObj(product.options) && <div className='options'>
                                    <span className='labelWrap labelWrapRow'>
                                        <span className='label'>
                                            {product?.details?.options?.title || 'Додаткові варіанти'}
                                            :
                                        </span>
                                        {Object.keys(product.options).map(option => <span className='labelWrap labelValue' key={'cart_shop_product_item_option' + store.details.id + '_' + product.details.id + '_' + option}>
                                            <span className='label'>{product.details.options?.list?.[option]?.title}:</span>
                                            <span className='labelValue'>
                                                <Chip variant="outlined" label={
                                                    product.details.options?.list?.[option]?.list?.[product.options[option]]?.title
                                                } />
                                            </span>
                                        </span>)}
                                    </span>
                                </div>}

                                {product?.addons && !isEmptyObj(product.addons) && <div className='addons'>
                                    <span className='labelWrap labelWrapRow'>
                                        <span className='label'>
                                            {product?.details?.addons?.title || 'Додаткові опції'}
                                            :
                                        </span>
                                        {Object.keys(product.addons).map(addon => <span className='labelWrap labelValue'>
                                            <span className='label'>{product?.details?.addons?.list[addon]?.title}: </span>
                                            <span className='labelValue'>
                                                {Object.keys(product.addons[addon]).map(id =>
                                                    <Chip variant="outlined"
                                                        key={'cart_shop_product_item_addon_' + store.details.id + '_' + product.details.id + '_' + addon + "_" + id}
                                                        label={
                                                            product?.details?.addons.list[addon].list[id].title
                                                            +  (product.addons[addon][id] > 1 ? ` x${product.addons[addon][id]}` : '')
                                                        }
                                                    />
                                                )}
                                            </span>
                                            </span>
                                        )}
                                    </span>
                                </div>}
                            </Collapse>

                        </div>
                        )}
                    </div>

                    {/* {getTotalPrice(store.details.id) && <div className='finalPrice'>
                        <span className='price'>
                            До сплати:
                            <span className="priceValue">
                                {getTotalPrice(store.details.id)}
                            </span>
                        </span>

                        <Button
                            variant="contained"
                            className='button'
                            fullWidth={!getTotalPrice(store.details.id)}
                            disabled={store.details.id === openOrderForm}
                            onClick={() => handleOrder(store.details.id)}>
                                Оформити
                        </Button>
                    </div>}

                    {!getTotalPrice(store.details.id) && <Button
                        variant="contained"
                        className='button'
                        fullWidth={!getTotalPrice(store.details.id)}
                        disabled={store.details.id === openOrderForm}
                        onClick={() => handleOrder(store.details.id)}>
                            Оформити
                    </Button>} */}
                    <Button
                        variant="contained"
                        className='startOrder'
                        fullWidth
                        disabled={store.details.id === openOrderForm}
                        onClick={() => handleOrder(store.details.id)}>
                            Оформити
                    </Button>
                </Collapse>

                <Collapse in={openOrderForm === store.details.id}>
                    {openOrderForm && <div className='FormContainerWrap'>
                        <FormContainer
                            content={getItemsForOrderField(store.details.id)}
                            config={getFormForShopConfig(store.details.id)}
                            afterSubmit={afterOrder}
                            additionalButtons={<>
                                <Button fullWidth
                                    variant="outlined"
                                    onClick={onEditHandler}>
                                        {isMobile ? "Змінити" : "Змінити замовлення"}
                                </Button>
                            </>}
                        />
                    </div>}
                </Collapse>
            </div>
        )}
    </div>
}
