import {useState} from 'react';
// import Typography from '@mui/material/Typography';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { NavLink as RouterLink } from "react-router-dom";

// import {DEFAULT_CITY} from 'ultra/const/general';
// import {getClientUrl} from 'ultra/configs/general';
import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";
import {useLocation} from 'react-router-dom'; // useNavigate

// inMemoryPersistence, setPersistence, signInWithEmailAndPassword
import {verifyPasswordResetCode, confirmPasswordReset, getAuth} from "firebase/auth";

import FormContainer from '../../../../Components/Form/FormContainer'
import Preloader from '../../../../Components/Preloaders/Preloader';

import {getModule} from '../../../../Helpers/module'
import {error} from '../../../../Helpers/logs'

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {signIn, saveUserHistoryAndNotify} from '../../Helpers/user';
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {setPageTitle} from '../../../../Helpers/router';

import {USER_ACTIONS} from '../../Reducers/user';

import {useEffect} from 'react';

import './index.scss';

export default function UpdatePassword(props) {
    const {onClose} = props;

    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {showSuccess} = useToasterStore(); // showError
    const {userDispatch} = useGlobalCustomContext();

    // const [oobCode, setOOBCode] = useState();
    // const [continueUrl, setContinueUrl] = useState();

    const [inProgress, setInProgress] = useState();
    const [profile, setProfile] = useState();
    const [email, setEmail] = useState();
    const [err, setError] = useState();

    const auth = getAuth();
    // const location = useLocation();

    useEffect(() => {
        setPageTitle('Зміна паролю')
        getModule('profile').then(setProfile)
    }, [])

    useEffect(() => {
        setInProgress(true)
        const url = new URL(window.location.href);
        const oobCode = url.searchParams.get('oobCode');

        verifyPasswordResetCode(auth, oobCode)
            .then(async email => {
                setInProgress(false);
                setEmail(email);
                // await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_CODE_VERIFY_SUCCESS, {oobCode});
            })
            .catch(async err => {
                setInProgress(false);
                let snackbarMessage = 'Виникла помилка, спробуйте пізніше'

                if (err.code === 'auth/invalid-action-code') {
                    snackbarMessage = 'Посилання не валідне, створіть нове'
                }

                if (err.code === 'auth/expired-action-code') {
                    snackbarMessage = 'Посилання застаріло, створіть нове'
                }

                setError(snackbarMessage);

                // no information about email here, so no record for user
                await error(LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_CODE_VERIFY_ERROR, {oobCode, error: err});
            })
    }, [])

    const onUpdatePasswordSubmit = (data) => {
        const url = new URL(window.location.href);
        const oobCode = url.searchParams.get('oobCode');

        confirmPasswordReset(auth, oobCode, data.password)
            .then(async () => {            
                await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_CONFIRM_SUCCESS, {oobCode});

                userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS});
                signIn(email, data.password)
                    .then(async (user) => {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                        // await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_AUTOLOGIN_SUCCESS);

                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                        
                        showSuccess({
                            snackbarMessage: 'Пароль успішно оновлено',
                            onCloseHandler: onClose,
                            // onCloseRedirect: continueUrl || getClientUrl(DEFAULT_CITY, '/'),
                            snackbarDuration: 10000
                        });
                    })
                    .catch(async err => {
                        userDispatch({type: USER_ACTIONS.USER_REMOVE});

                        setError('Помилка авторизації після оновлення пароля');

                        await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_AUTOLOGIN_ERROR, err);
                    })
            }).catch(async (err) => {
                setError('Помилка оновлення пароля');

                await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_CONFIRM_ERROR, {oobCode, error: err});

                hideBackdropPreloader();
            });
    }

    return <div className="UpdatePassword">
        {/* <Typography variant='h4' className="pageTitle">
            <RouterLink to="/" className="goBack">
                <ChevronLeftIcon />
            </RouterLink>
            <span>Зміна паролю</span>
        </Typography> */}

        {inProgress && <div className='preloaderWrap'><Preloader /></div>}

        {!inProgress && err && <div className='informer error'>{err}</div>}

        {!inProgress&& !err  && email && <div className='resetDescription'>Оновлення паролю для аккаунту <i>{email}</i></div>}

        {!inProgress && !err && profile?.restorePasswordUpdateForm && <FormContainer
            config={profile.restorePasswordUpdateForm}
            submitHandler={onUpdatePasswordSubmit}
        />}
    </div>
}
