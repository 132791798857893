import {cloneDeep} from 'lodash';
import {redirect} from "react-router-dom";

import {getPartnerDetails} from "ultra/helpers/shop";
import {normalizeRoute, restorePath} from 'ultra/helpers/route';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import {updateActiveFilters} from '../Modules/Content/Helpers/filters';

export const getNodeContentLoader = (controllers, getContent) => async ({request}) => {
    controllers.map(c => {c?.abort('Another node get')});

    const url = new URL(request.url);
    // GET FILTERS FROM URL AND STORAGE
    let urlFilters = url.searchParams.get("filters");
    if (urlFilters) {
        urlFilters = JSON.parse(urlFilters);
    }

    let savedFilters = sessionStorage.getItem("filters");
    if (savedFilters) {
        savedFilters = JSON.parse(savedFilters);
    }

    // merge filters
    let mergedFilters = savedFilters ? cloneDeep(savedFilters) : [];
    if (urlFilters) {
        urlFilters.map(filter => {
            const i = mergedFilters.findIndex(f => f[0] === filter[0] && f[2] !== filter[2])
            if (i > -1) {
                mergedFilters[i] = filter;
            }
        })
    }

    const actualFilters = [];

    const city = getClientCity(window) || DEFAULT_CITY;

    const path = {
        city: city,
        uri: normalizeRoute(url.pathname)
    }

    const {promise, controller} = await getContent(path, url.search, url.search.includes('virtual_uri=')); //

    controllers.push(controller);

    const pageData = await promise;

    // get config to check is saved filter present in configuration
    // TODO: better to generate link
    let filterBy, filterByInline
    if (pageData) {
        filterBy = pageData.page.content.showFilters?.filterBy || pageData.page.config.showFilter?.filterBy;
        filterByInline = pageData.page.content.showFilters?.filterByInline || pageData.page.config.showFilter?.filterByInline;    

        if (filterBy) {
            filterBy.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }

        if (filterByInline) {
            filterByInline.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }
    }

    const {urlShouldBeUpdated, activeFilters} = updateActiveFilters(actualFilters, request.url);
    if (urlShouldBeUpdated && activeFilters.length > 0) {
        const str = JSON.stringify(activeFilters.filter(f => f[0] && f[1]));
        url.searchParams.set('filters', str);
        return redirect(url.pathname + url.search);
    }
    else {
        if (url.pathname.length > 1 && url.pathname[url.pathname.length - 1] === '/') {
          return redirect(url.pathname + url.search);
        }

        const partner = getPartnerDetails({}, pageData.breadcrumbs)
        if (pageData?.page?.content?._type === 'shop-section' && partner?.node?.virtualCatalogs) {
            return redirect(restorePath(normalizeRoute(partner?.uri)) + '?virtual_uri=' + normalizeRoute(pageData.page.content._uri));
        }

        return pageData;
    }
}
