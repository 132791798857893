import {useState} from "react";
import {useNavigate} from "react-router-dom";

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

import {getClientUrl} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import {restorePath} from "ultra/helpers/route";

import PartnerImage from '../../../../../Content/Widgets/PartnerImage';
import ContentImage from '../../../ContentImage'

import './index.scss';

export default function OrderDetails(props) {
    const {details} = props;

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const goToShop = () => {
        const path = restorePath(details.partner_info._uri);
        const city = getClientCity(window) || DEFAULT_CITY;
        if (city === details._city) {
            navigate(path);
        }
        else {
            window.location.assign(getClientUrl(details._city, path))
        }
    }

    if (!details) return <></>

    return <div className="OrderDetails">
        <Button className='RequestDetailsButton' variant='outlined' size='small' fullWidth onClick={() => setOpen(!open)}>Деталі замовлення</Button>

        <Collapse in={open}>
            {/* Деталі: */}
            <div className="OrderWrap">
                {details.data?.order?.map(item => <Order city={details._city} key={`order_${details._oid}`} item={item} />)}

                {details?.partner_info && <div className="Partner">
                    <PartnerImage className="partnerAvatar" id={details.partner} shop={details.shop} form={details.form}/>
                    <span className="pseudoLink" onClick={goToShop}>
                        {details.partner_info.title}
                    </span>
                </div>}
            </div>
        </Collapse>
    </div>
}

function Order(props) {
    const {item, city} = props;

    const navigate = useNavigate();

    const goToItem = (item) => {
        const path = restorePath(item.details._uri);
        const c = getClientCity(window) || DEFAULT_CITY;
        if (c === city) {
            navigate(path);
        }
        else {
            window.location.assign(getClientUrl(city, path))
        }
    }

    return <div className="Order">
        <div className="Title">
            <ContentImage src="thumbnail" city={city} image={item.details.thumbnail} className="ContentImage" />
            <div>
                {item.amount} x <span className="pseudoLink" onClick={() => goToItem(item)}>{item.details.title}</span>
            </div>
        </div>

        <Addons data={item.addons} product={item.details} />
        <Options data={item.options} product={item.details} />
    </div>
}

function Addons(props) {
    const {data, product} = props;

    if (!data) return <></>

    return <div className="Addons">
        {data && Object.keys(data).length > 0 && <span className="title">{product?.addons?.title || "Додаткові опції"}:</span>}
        {data && Object.keys(data).map(addon => <span className="labelWrap" key={`order_addons_${product.id}_${addon}`}>
            <span className="label">{product.addons.list[addon].title}:</span>
            <span className="labelValue">
            {Object.keys(data[addon]).map(id => 
                <span className="addonItem" key={`order_addons_${product.id}_${addon}_${id}`}>
                    {product.addons.list[addon].list[id].title}
                    <span className="q">
                        &nbsp;x{data[addon][id]}
                    </span>
                </span>
            )}
            </span>
        </span>)}
    </div>
}

function Options(props) {
    const {data, product} = props;

    if (!data) return <></>

    return <div className="Options">
        {data && Object.keys(data).length > 0 && <span className="title">{product?.options?.title || "Додаткові варіанти"}:</span>}
        {data && Object.keys(data).map(option => <span className="labelWrap" key={`order_options_${product.id}_${option}`}>
            <span className="label">{product.options.list[option].title}:</span>
            <span className="labelValue">
                {product.options.list[option].list[data[option]].title}
            </span>
        </span>)}
    </div>
}
