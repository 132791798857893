import {useState} from 'react';
import {getPartnerDetails} from 'ultra/helpers/shop';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import ShopProductsList from '../../../../../../Widgets/ShopProductsList'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs';
import TitleWidget from '../../../../../../Widgets/Title';
import TopPagePicture from '../../../../../../Widgets/TopPagePicture';

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props;
    const [shop] = useState(getPartnerDetails(content, breadcrumbs)?.node);

    const {contentLoading} = useNavigationStore();
    
    return <div className="ShopSectionTemplate">
        {content.banner && (!content.showPagination || (content.showPagination && children?.pagination?.current === 1))
            && <TopPagePicture cutHeightForDesktop showSkeleton={contentLoading}e image={content.banner}/>}

        <TitleWidget
            permits={permits} content={content} config={config}
            breadcrumbs={breadcrumbs}          
            hide={content.hideTitle}  
        />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>
        
        <div className="products">
            <ShopProductsList
                showSkeleton={contentLoading}
                items={children}
                showPagination={content.showPagination}
                
                currency={shop?.partner?.config?.currency}
                breadcrumbs={breadcrumbs}
                content={content}
                config={config}
            />
        </div>

        <CKEditorContent content={content.description} />
    </div>
}
