import { create } from 'zustand'

export const useFullHeightContainerStore = create((set) => ({
  scrollTo: false,

  stopScrollTo: () => set(() => {
    const scrollTo = false;
    return {scrollTo};
  }),

  startScrollTo: (scrollTo) => set(() => {
    return {scrollTo};
  }),
}))
