import {debounce, cloneDeep, isString} from 'lodash';

import React, {useEffect, useState, useRef, useMemo} from 'react';
import {isMobile} from "react-device-detect";

import {DEFAULT_CHILDREN} from "ultra/const/general";
import {isEmptyObj} from 'ultra/helpers/utils';
import {getClientCity} from 'ultra/configs/general';
import {normalizeRoute} from 'ultra/helpers/route';

import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ButtonWithPreloader from '../../../../Components/Form/ButtonWithPreloader';
import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';
import Preloader from '../../../../Components/Preloaders/Preloader';

import {useContentStore} from '../../../../Stores/content';
import {getNodeContent} from '../../../../Helpers/content';

import Pagination from '../../Widgets/Pagination';

import CellContent from './CellContent';

import './index.scss';

function Loader(props) {
    const {attachingInProgress} = props;

    return <div className={attachingInProgress ? `InfinityPreloader InProgress`: `InfinityPreloader`}>
        <Preloader />
    </div>
}

function hasValues(item, tableOptions, fields) {
    if (!item) return false;

    let counter = 0;
    tableOptions?.hidden?.map((field) => {
        const config = fields?.[field];
        if (config?.type === 'node_image') {
            const {banner, poster, thumbnail} = fields;
            if (banner || poster || thumbnail) counter++;
        }
        else if (config?.type === 'location') {
            if (item[field]?.link?.length > 0) counter++;
        }
        else if (config?.type === 'url') {
            if (item[field]?.link?.length > 0) counter++;
        }
        else if (config?.type === 'messenger') {
            if (item[field] && Object.keys(item[field]).length > 0 && (item[field]?.telegram?.number || item[field]?.whatsup?.number)) counter++;
        }
        else if (config?.type === 'tags') {
            if (item[field]?.length > 0) counter++;
        }
        else if (item[field]) counter++;
    })

    return counter > 0;
}

function getFieldsWithValues(hidden, values, fields) {
    const result = [];

    if (!values) return result;

    hidden?.map(field => {
        if (fields[field]?.type === 'location') {
            if (values[field]?.link) result.push(field)
        }
        else if (fields[field]?.type === 'url') {
            if (values[field]?.link) result.push(field)
        }
        else if (fields[field]?.type === 'messenger') {
            if (values[field] && Object.keys(values[field]).length > 0 && (values[field]?.telegram?.number || values[field]?.whatsup?.number)) result.push(field)
        }
        else if (fields[field]?.type === 'tags') {
            if (values[field]?.length) result.push(field)
        }
        else {
            if (values[field]) result.push(field)
        }
    })

    return result;
}

function DetailsPopupWrap(props) {
    if (hasValues(props.content?.list[props.id], props.tableOptions, props.fields)) {
        return <DetailsPopup {...props}/>
    } else {
        return <td></td>
    }
}

function DetailsPopup(props) {
    const {id, values, fields, tableOptions} = props;

    const [visible, setVisible] = useState(tableOptions?.unfolded ?  true : false);

    if (!values) return <td></td>

    const handleClick = () => {
        if (isHidden) return;

        setVisible(!visible);
        document.getElementsByClassName(`item_${id}`)[0].classList.toggle("details_hidden");
        document.getElementsByClassName(`details_${id}`)[0].classList.toggle("visible");
    }

    const fieldsWithValues = getFieldsWithValues(tableOptions?.hidden, values, fields)

    const isHidden = (!tableOptions?.hidden || tableOptions?.hidden.length === 0) || (fieldsWithValues?.length === 0);

    if (isHidden) return <td></td>;

    return <td className="detailsTD" onClick={handleClick}>
        {visible && <KeyboardArrowUpIcon />}
        {!visible && <KeyboardArrowDownIcon />}
    </td>
}

function Details(props) {
    const {id, values, fields, hidden, content, noChildLinks} = props;

    const [fieldsWithValues, setFieldsWithValues] = useState()

    useEffect(() => {
        setFieldsWithValues(getFieldsWithValues(hidden, values, fields))
    }, [])

    if (fieldsWithValues?.length === 0) {
        return <></>
    }

    return <>
        {fieldsWithValues?.map(field => <div className={`labelWrap ${field}`} key={`details_td_${id}_${field}`}>
            {fields[field]?.placeholder && <>
                <CellContent
                    placeholder={fields[field]?.placeholder}
                    published={values._published}
                    noChildLinks={noChildLinks}
                    field={field}
                    fields={fields}
                    values={values}
                    content={content}
                />
            </>}
        </div>)}
    </>
}

export function TableView(props) {
    const {
        content,
        // categories,
        fields,
        noDataText,
        showPagination,
        noChildLinks,
        topAlignVisibleData,
        hasActions,
        actionsComponent,
        actionsProps,
        selectClasses,
        tableOptions,
    } = props;

    const {updateChildren} = useContentStore();

    const [data, setData] = useState(); // content
    const [categoriesData, setCategoriesData] = useState(); // {default: {label: 'default', items: content.order ? [...content.order] : []}}

    useEffect(() => {
        setData(content)
        setCategoriesData({default: {label: 'default', items: content.order ? [...content.order] : []}})
    }, [content])

    const next = () => {
        if (data.pagination.current >= data.pagination.pages) return {
            promise: Promise.resolve({})
        };

        // get data by current uri or alternative function
        const city = getClientCity(window);
        let url = new URL(window.location.href);
        const path = {
            city,
            uri: normalizeRoute(url.pathname)
        }

        url.searchParams.set('page', data.pagination.current + 1);
        url.searchParams.set('perPage', DEFAULT_CHILDREN.PER_PAGE);

        return {
            promise: new Promise(async resolve => {
                resolve((await getNodeContent(path, url.search).promise).children)
            })
        }
    }

    const add = (result) => {
        const updated = cloneDeep(data);

        updated.pagination.current = result.pagination.current;

        result.order.map(uri => {
            updated.order.push(uri);

            // updated.list[uri] = result.list[uri]
            updated.list[uri] = {
                ...result.list[uri],
            //     '__new_attached': true
            }

            // permits for nodes
            if (result?.permits?.[uri]) updated.permits[uri] = result.permits[uri];
        })

        updateChildren(updated)
        setCategoriesData({default: {label: 'default', items: updated.order ? [...updated.order] : []}})
    }

    return <TableViewContainer
        hasNextLoader={(data) => {
            if (data?.amount < DEFAULT_CHILDREN.PER_PAGE) return false;

            return data?.pagination?.current < data?.pagination?.pages
        }}
        getNext={next}
        addNext={add}

        content={data}
        categories={categoriesData}

        fields={fields}
        tableOptions={tableOptions}

        showPreloader={!Boolean(data?.list)}
        showPagination={showPagination}

        selectClasses={selectClasses}

        noDataText={noDataText}
        noChildLinks={noChildLinks}

        topAlignVisibleData={topAlignVisibleData}

        preloader={<PreloaderTable />}

        actionsComponent={actionsComponent}
        hasActions={hasActions}
        actionsProps={actionsProps}
    />
}

function Actions(props) {
    const {id, data, component, actionsProps} = props;

    return React.createElement(
        component,
        {
            actionsProps,
            id,
            data
        }
    )
}

export function TableViewContainer(props) {
    const {
        content,
        categories,
        getNext,
        getPrev,
        addNext,
        addPrev,
        noDataText,
        hasNextLoader,
        hasPrevLoader,
        showPreloader,
        showPagination,
        noChildLinks,
        hasActions,
        actionsProps,
        actionsComponent,
        checkVisible,
        fields,
        preloader,
        preRender,
        bottomPadding,
        tableOptions,
        selectClasses,
        scrollContainer,
        onScrollCallback,
        topAlignVisibleData
    } = props;

    const [attachingNextInProgress, setAttachingNextInProgress] = useState(false)
    const [attachingPrevInProgress, setAttachingPrevInProgress] = useState(false)

    const [container, setContainer] = useState()

    // on table dymanic data
    // attach data

    const itemsRef = useRef({});
    const tableWrapRef = useRef(null);

    const nextLoaderRef = useRef(null)
    const prevLoaderRef = useRef(null)

    useEffect(() => {
        const container = scrollContainer ? scrollContainer() : window;

        setContainer(container);

        // setTimeout(() => {
        //     updateVisible();
        // }, 100)

        container.addEventListener('scroll', onScroll, { passive: true });
        return () => container.removeEventListener('scroll', onScroll);
    }, [])

    function preventScrolling(position) {
        if (position === 'prev') {
            container.scrollTo(0, 60)

            let currentContentHeight = 0            

            // get current block height
            currentContentHeight = container.scrollHeight

            // scroll to hide preloader
            setTimeout(() => {
                let contentHeightUpdated = container.scrollHeight

                container.scrollTo(0, contentHeightUpdated - currentContentHeight);

                setAttachingPrevInProgress(false);
            }, 100)
        }
        else {
            setAttachingNextInProgress(false);
        }
    }

    function attachNext() {
        setAttachingNextInProgress(true);
        getNext()
            .promise
            .then(attach => {
                if (!isEmptyObj(attach)) {
                    addNext(attach)
                    preventScrolling()
                }
            })
    }

    function attachPrev() {
        setAttachingPrevInProgress(true);
        getPrev()
            .promise
            .then(attach => {
                if (!isEmptyObj(attach)) {
                    addPrev(attach)
                    preventScrolling('prev')
                }
                setAttachingPrevInProgress(false);
            })
    }

    function getSelectionClass(data) {
        if (data && selectClasses) {
            return selectClasses(data)
        } else {
            return ''
        }
    }

    function hasDetails(hidden, data, fields) {
        return getFieldsWithValues(hidden, data, fields)?.length > 0;
    }

    function hasControlActions() {
        let result = false;
        content?.order?.map(id => {
            if (result) return;

            if (hasActions && hasActions(content, id)) {
                result = true;
            }
        })

        return result;
    }

    function isVisibleObject(obj, threshold = 0) {
        const HEADER_HEIGHT = isMobile ? 120 : 130;
        const rect = obj?.getBoundingClientRect();

        const top = (HEADER_HEIGHT - threshold);
        const bottom = (window.innerHeight || document.documentElement.clientHeight) - (bottomPadding || 0) + threshold;

        return (
                // block is 100% in wrapper
                Math.floor(rect?.top) >= top
                && Math.floor(rect?.bottom) <= bottom
            )
            ||
            (
                // block is bigger then wrapper
                Math.floor(rect?.top) < top
                && Math.floor(rect?.bottom) > bottom
            )
            ||
            (
                // is bigger then container and bottom line cross bottom container line
                obj?.clientHeight > container?.clientHeight
                && Math.floor(rect?.bottom) <= bottom
            )
    }

    const onScroll = useDebounce(() => {
        if (showPreloader || attachingNextInProgress || attachingPrevInProgress) return;

        if (onScrollCallback) {
            onScrollCallback(tableWrapRef.current.clientHeight, container.clientHeight, container.scrollTop)
        }

        if (tableOptions?.infinityScroll) {
            // only for messengers
            if (isVisibleObject(nextLoaderRef?.current, 30)) { //  && !attachingNextInProgress
                attachNext()
            }

            if (isVisibleObject(prevLoaderRef?.current, 30)) { //  && !attachingPrevInProgress
                attachPrev()
            }

            updateVisible()
        }
    }, 100)

    const handleClick = (id, hidden, values, fields) => {
        const fieldsWithValues = getFieldsWithValues(hidden, values, fields)
        const isHidden = (!hidden || hidden.length === 0) || (fieldsWithValues?.length === 0);

        if (tableOptions?.unfolded) return false;
        if (isHidden) return false;

        document.getElementsByClassName(`item_${id}`)[0].classList.toggle("details_hidden");
        document.getElementsByClassName(`details_${id}`)[0].classList.toggle("visible");
    }

    function updateVisible() {
        if (checkVisible) {
            const visibleItems = [];
            Object.keys(itemsRef.current).map((id) => {
                if (isVisibleObject(itemsRef.current[id])) {
                    visibleItems.push(id);
                }
            })
            checkVisible(visibleItems);
        }
    }

    return <>
        {fields && <div className={`TableWrap ${topAlignVisibleData ? 'topAlign' : ''}`} ref={tableWrapRef}>
            <div className="TableAutoWrap">
                {showPreloader && preloader}

                {hasPrevLoader && content?.amount > content?.order?.length && <div className='preloaderWrapContainer'>
                    {tableOptions?.infinityScroll && hasPrevLoader(content) &&  <>
                        <div ref={prevLoaderRef}>
                            <Loader attachingInProgress={attachingPrevInProgress} />
                        </div>
                    </>}
                </div>}

                {!showPreloader && <table className='Table'>
                    {!tableOptions?.noHeaders && <thead>
                        <tr>
                            {tableOptions?.visible.map((field) =><th key={`th_${field}`} className={`th_${field} th_${fields[field]?.type}`}>
                                {fields[field]?.placeholder}
                            </th>)}
                            {!isMobile && hasControlActions() && !tableOptions?.unfolded && <th></th>}
                            {tableOptions?.hidden?.length > 0 && !tableOptions?.unfolded && <th className='DetailsBtn'></th>}
                        </tr>
                    </thead>}
                    <tbody>
                        {content?.order?.length === 0 && <tr>
                            <td colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )} className="noContent">
                                {!isString(noDataText) && !noDataText && 'Відсутні дані'}
                                {isString(noDataText) && noDataText}
                            </td>
                        </tr>}

                        {categories && Object.keys(categories).map(category => <React.Fragment key={`table_category_${category}`}>

                            {category !== 'default' && !attachingNextInProgress && <tr className={`category category_${category}`}>
                                <td className='categoryWrapTD' colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )}>
                                    <div className='categoryWrap'>
                                        <Chip label={categories[category].label} />
                                    </div>
                                </td>
                            </tr>}

                            {categories[category]
                                ?.items
                                ?.map((id, i) =>
                                    <React.Fragment key={`wrap_${id}`}>
                                        {preRender?.[id] && <tr className={`pre_${preRender[id].id}`}>
                                            <td className='wrap' colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )}>
                                                {preRender[id].label}
                                            </td>
                                        </tr>}

                                        <tr key={`${id}_tr`}
                                            ref={el => itemsRef.current[id] = el} 
                                            className={`
                                                item
                                                item_${id}
                                                ${(tableOptions?.hidden?.length === 0) ? 'no_details' : ''}
                                                ${!hasDetails(tableOptions?.hidden, content?.list?.[id], fields) ? 'no_details' : ''}
                                                ${tableOptions?.hidden?.length > 0 ? 'has_details' : ''}
                                                ${!isMobile && hasActions && hasActions(content, id) ? 'has_actions' : ''}
                                                ${tableOptions?.unfolded ? '' : 'details_hidden'}
                                                ${i % 2 ? 'even ': 'odd '}
                                                ${getSelectionClass(content?.list?.[id])}`}
                                        >
                                            {tableOptions?.visible.map((field) =>
                                                <td key={`td_${id}_${field}`}
                                                    className={`td_${field} td_${fields[field]?.type}`}
                                                    onClick={() => handleClick(id, tableOptions?.hidden, content.list[id], fields)}
                                                >
                                                    <CellContent
                                                        field={field}
                                                        fields={fields}
                                                        published={content?.list?.[id]?._published}
                                                        values={content?.list?.[id]}
                                                        content={content}
                                                        noChildLinks={noChildLinks}
                                                    />
                                                </td>
                                            )}

                                            {!isMobile && hasActions && hasActions(content, id) && tableOptions?.visible && <td className="ActionsCell">
                                                <Actions actionsProps={actionsProps} data={content} id={id} component={actionsComponent} />
                                            </td>}

                                            {tableOptions?.hidden?.length > 0 && !tableOptions?.unfolded && <DetailsPopupWrap
                                                id={id}
                                                content={content}
                                                values={content?.list[id]}
                                                tableOptions={tableOptions}
                                                fields={fields}/>}
                                        </tr>

                                        {hasValues(content.list[id], tableOptions, fields)
                                        && <tr
                                            key={`${id}_details`}
                                            className={`
                                                details_${id} details
                                                ${i % 2 ? 'even': 'odd'}
                                                ${getSelectionClass(content?.list?.[id])}
                                                ${tableOptions?.unfolded ? 'visible' : ''}
                                            `}
                                            >
                                                <td colSpan={(tableOptions?.visible?.length || 0) + (tableOptions?.hidden?.length > 0 ? 1 : 0 )} className='Details'>
                                                    <Details
                                                        id={id}
                                                        values={content?.list?.[id]}
                                                        hidden={tableOptions?.hidden}
                                                        fields={fields}
                                                        content={content}
                                                        noChildLinks={noChildLinks}
                                                    />

                                                    {isMobile && hasActions && hasActions(content, id) && <Actions actionsProps={actionsProps} data={content} id={id} component={actionsComponent} />}
                                                </td>
                                                {!isMobile && hasActions && hasActions(content, id) && !tableOptions?.unfolded && <td></td>}
                                        </tr>}
                                    </React.Fragment>
                                )}
                        </React.Fragment>)}
                    </tbody>
                </table>}

                {showPagination && content?.pagination && !tableOptions?.infinityScroll && !tableOptions?.loadMoreButton && <Pagination pagination={content.pagination} />}

                {hasNextLoader && content?.amount > content?.order?.length && <>
                    {tableOptions?.infinityScroll && hasNextLoader(content) && <>
                        <div ref={nextLoaderRef}>
                            <Loader attachingInProgress={attachingNextInProgress} />
                        </div>
                    </>}
                </>}

                {showPagination && content?.pagination && tableOptions?.loadMoreButton && content.pagination.current < content.pagination.pages && <div className='loadMoreButton'>
                    <ButtonWithPreloader variant="outlined" fullWidth onClick={attachNext} inprogress={attachingNextInProgress.toString()}>
                        Показати ще
                    </ButtonWithPreloader>
                </div>}

            </div>
        </div>}
    </>
}

const useDebounce = (callback, timer) => {
    const ref = useRef();
  
    useEffect(() => {
      ref.current = callback;
    }, [callback]);
  
    const debouncedCallback = useMemo(() => {
      const func = () => {
        ref.current?.();
      };
      return debounce(func, timer);
    }, []);
  
    return debouncedCallback;
};
