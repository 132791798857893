import PreloaderText from '../PreloaderText';

export default function PreloaderRecomendationDescription() {
    return <div className="RecomendationDescription">
        <div style={{
            marginBottom: '2rem',
            paddingTop: '1.5rem',
        }}>
            <PreloaderText />
        </div>
    </div>
}
