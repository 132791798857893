import {DEFAULT_CITY} from "ultra/const/general";
import {getClientConfig, getClientCity} from 'ultra/configs/general';

import {get, cachedGet} from '../Helpers/http';

const {api} = getClientConfig(window)

export function getConfigs() {
    let city = getClientCity(window) || DEFAULT_CITY;
    return {
        promise: new Promise(async (resolve, reject) => {
            try {
                const data = await cachedGet('configs', city, `${api}/config/${city}`).promise;
                resolve(data)
            }
            catch(e) {
                reject(e)
            }
        })
    }
}

export function getNodeForm(type, parent) {
    let city = getClientCity(window) || DEFAULT_CITY;
    let url = `${api}/config/content/nodes/${city}/${type}`

    let search = ''
    if (parent) search = `?parent=${parent}`

    return get(url + search);
}

export function getFilterValue(filters, type, value) {
    if (!filters[type].values[value]) return '-';
    return filters[type].values[value];
}
