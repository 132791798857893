import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import Links from '../../../../../../Widgets/Links'
import LinksTile from '../../../../../../Widgets/LinksTile'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props
    const {contentLoading} = useNavigationStore()

    const [currentPage, setCurrentPage] = useState()

    const location = useLocation();

    useEffect(() => {
        let url = new URL(window.location.href);
        setCurrentPage(url.searchParams.get('page'));
    }, [location])

    return <div className='NodePage'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {!currentPage
            && <CKEditorContent
                showSkeleton={contentLoading}
                content={content.description}
            />
        }

        {!content.showPagination && <Links
            showSkeleton={contentLoading}
            order={config.childrenOrder || content.childrenOrder}
            links={children}
        />}

        {content.showPagination && 
            <LinksTile
                // className="childrensListLinksLines"
                showSkeleton={contentLoading}
                showPagination={content.showPagination}
                // showFilters={content.showFilters}
                imgLoading={config?.templateOptions?.imgLoading}

                links={children}
                xs={6}
                md={3}
            />
        }
    </div>
}
