import {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material";

import {useToasterStore} from '../../Stores/toster';
import {useBackdropPreloaderStore} from '../../Stores/backdropPreloader';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import './index.scss';

export default function () {
    const {toaster, closeToaster} = useToasterStore()
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const [timer, setTimer] = useState(null)
    const [className, setClassName] = useState('Notify')

    const theme = useTheme()

    let {
        type,
        snackbarMessage,
        snackbarDuration,
        onApproveHandler,
        onCloseRedirect,
        onCloseHandler,
    } = toaster

    const navigate = useNavigate();

    const closeAll = () => {
        clearTimeout(timer)

        if (onCloseRedirect) {
            onCloseRedirect.includes('http') ?
                window.location.assign(onCloseRedirect)
                : navigate(onCloseRedirect)
        }

        if (onCloseHandler) {
            onCloseHandler();
        }

        hideBackdropPreloader();

        closeToaster()
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if(!toaster.snackbarMessage) return; // is closed

                if (onCloseHandler) onCloseHandler();
                else hideBackdropPreloader();

                closeToaster();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    });

    useEffect(() => {
        let open = Boolean(snackbarMessage)
        if (open) {
            setClassName(`Notify open ${type}`)
            showBackdropPreloader();
        } else {
            setClassName('Notify')
        }

        if (open) {
            clearTimeout(timer)

            let newTimer = setTimeout(() => {
                console.log('set delay for closeAll')
                closeAll();
            }, snackbarDuration);

            setTimer(newTimer)
        }
    }, [toaster])

    return <>
        <div
            className={className}
            onClick={() => {
                if (!onApproveHandler) closeAll();
            }}
            style={{zIndex: theme.zIndex.modal + 3}}
        >
            <div className="wrapper">
                <div className='message'>
                    {snackbarMessage}
                </div>

                {onApproveHandler && <div className='actions'>
                    <span
                        className='action' 
                        onClick={() => {
                            clearTimeout(timer)
                            onApproveHandler()
                        }}
                    >
                        <DoneIcon />
                    </span>
                    <span
                        className='action'
                        onClick={closeAll}
                    >
                        <CloseIcon/>
                    </span>
                </div>}

                {!onApproveHandler && <span
                    className='action'
                    onClick={closeAll}>
                        {type === 'error' && <DoneIcon/>}
                        {type === 'success' && <DoneIcon/>}
                    </span>
                }
            </div>
        </div>
    </>
}
