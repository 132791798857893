import {LOG_TYPES} from 'ultra/const/log';

import {getClientConfig} from 'ultra/configs/general';
import {HISTORY_USER} from 'ultra/const/log';

import {inMemoryPersistence, getAuth, signOut, setPersistence, sendEmailVerification, signInWithEmailAndPassword} from "firebase/auth";

import {get, cachedGet, post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getUserHistory(id, search) {
    let link = `${api}/history/user/${id}`;
    if (search) link += search
    return get(link);
}

export async function signIn(email, password) {
    const auth = getAuth();
    signOut(auth);

    return new Promise((resolve, reject) => {
        setPersistence(auth, inMemoryPersistence);
        signInWithEmailAndPassword(auth, email, password)
            .then(({user}) => {
                const {accessToken} = user;
                window.scrollTo(0, 0);
                return postIdTokenToSessionLogin(accessToken);  // userId
            })
            .then(async (user) => {
                // send email verification
                if (user.emailNotVerified) {
                    try {
                        await sendEmailVerification(auth.currentUser)
                        await requestValidateEmail(email)

                        await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.EMAIL_VALIDATION_REQUEST_AFTER_LOGIN)

                        signOut(auth)

                        resolve(user)
                    }
                    catch(err) {
                        await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, HISTORY_USER.EMAIL_VALIDATION_REQUEST_AFTER_LOGIN_ERROR, err)


                        reject({snackbarMessage: err.message});
                    }
                }

                signOut(auth);
                resolve(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                // TODO: use google logger here
                console.error(errorCode);
                console.error(errorMessage);

                switch (errorCode) {
                    case 'auth/invalid-email':
                        reject({snackbarMessage: `Користувач зі вказаним email не існує`})
                        break;

                    case 'auth/user-disabled':
                        reject({snackbarMessage: 'Ваш аккаунт заблоковано. Якщо у вас виникли питання, зверніться в службу підтримки'})
                        break;

                    case 'auth/invalid-login-credentials':
                        reject({snackbarMessage: 'Не правильно вказаний пароль'})
                        break;
                    
                    case 'auth/too-many-requests':
                        reject({snackbarMessage: 'Забагато запитів'})
                        break;
                    
                    case 'auth/user-token-expired':
                        reject({snackbarMessage: 'Помилка авторизації. Токен не актуальний'})
                        // Ви нещодавно вийшли з акаунту. Для вашої безпеки, повторна авторизація буде доступна через декілька хвилин
                        break;

                    default:
                        reject({snackbarMessage: errorMessage})
                }
            });  
    });
}

export async function emailValidated(email) {
    return post(`${api}/user/email-validated/${email}`);
}

export async function requestValidateEmail(email) {
    return post(`${api}/user/validate-me/${email}`);
}

export function getUserDetails() {
    return get(`${api}/user`);
}

export function updateUser(id, data) {
    return post(`${api}/user/edit/${id}`, data);
}

function postIdTokenToSessionLogin(accessToken) {
    return post(`${api}/user/login`, {accessToken});    // userId: getCookie('6a7a7b6f5d66626c67')
}

export function userLogout() {
    return get(`${api}/user/logout`);
}

export function saveUserHistoryAndNotify(email, type, action, details) {
    return post(`${api}/user/history/${email}`, {type, action, ...details});
}

export function getUserMessenger(id) {
    return get(`${api}/user/messenger/${id}`);
}

export function getCachedUser(id) {
    return cachedGet('users', id, `${api}/user/get/${id}`).promise;
}

export function getCachedUsersByIDS(list) {
    return new Promise(async (resolve) => {
        const result = {};

        await Promise.all(list.map(async id => {
            const data = await getCachedUser(id)

            if (data) {
                result[id] = data
            } else {
                result[id] = {}
            }
        }))

        resolve(result);
    })
}

// export function attachUsersForFields(content, fields) {
//     return new Promise(async (resolve) => {
//         const ids = []

//         if (!content?.list) {
//             resolve();
//             return;
//         }

//         Object.keys(content?.list)
//             .map((id) => {
//                 fields.map(field => {
//                     const uid = content?.list?.[id]?.[field];
                    
//                     if (uid && !ids.includes(uid)) {
//                         ids.push(uid)
//                     }
//                 })
//             })

//         await getCachedUsersByIDS(ids);
//         resolve()
//     })
// }

export function attachNodeImage(fields) {
    fields._node_image = {
        type: 'node_image',
        placeholder: 'Зображення'
    }

    return fields
}

