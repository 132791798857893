import CurrencyFormated from '../../../../Components/CurrencyFormated'

import './index.scss';

export default function ShopProductPrice(props) {
    const {product, selectedPrice, addons, options} = props;

    if (!product) return <></>

    let optionsAdditionalPrice = 0;
    if (options) {
        Object.keys(options).map(option => {
            if (product?.options?.list?.[option]?.list[options[option]]?.correction) {
                optionsAdditionalPrice += product?.options?.list?.[option]?.list[options[option]]?.correction
            }
        })
    }

    let addonsAdditionalPrice = 0;
    if (addons) {
        Object.keys(addons).map(addon => {
            Object.keys(addons[addon]).map(id => {
                const item = product?.addons.list[addon].list[id];
                if (!item.free) addonsAdditionalPrice += addons[addon][id] * item.price;
            })
        })
    }

    let additionalPrice = Number(optionsAdditionalPrice) + Number(addonsAdditionalPrice)

    if (product?.price?.free) {
        if (addonsAdditionalPrice) {
            return <CurrencyFormated
                value={additionalPrice}
                currency={product.price.currency}
            />
        }
        else {
            return <></>
            // return <>Безкоштовно</>
        }
    }

    if (selectedPrice) {
        let price = product.price.prices.find(i => i.id === selectedPrice) || {};

        if (price?.free) {
            if (addonsAdditionalPrice) {
                return <CurrencyFormated
                    value={additionalPrice}
                    currency={product.price.currency}
                />
            }
            else {
                return <>Безкоштовно</>
            }
        }

        return <CurrencyFormated
            value={Number(price.price) + additionalPrice}
            currency={product.price.currency}
        />
    }
    else if (product?.price?.prices) {
        return <></>
    }
    else if (product?.price?.noPrice) {
        return <></>
    }
    else {
        if (product.price.discount) {
            return <span className="ShopProductPrice">
                <span className='priceValue'>
                    <span className='actualPrice'>
                        <CurrencyFormated value={Number(product.price.price) - Number(product.price.discount) + additionalPrice} currency={product.price.currency} />
                    </span>
                    <span className='oldPrice'>
                        <CurrencyFormated value={Number(product.price.price) + additionalPrice} currency={product.price.currency} />
                    </span>
                </span>

                {product.price.prefix && <span className='pricePrefix'>/ {product.price.prefix}</span>}
            </span>
        }
        else if (product.price?.price) {
            return <span className="ShopProductPrice">
                <span className='priceValue'>
                    <CurrencyFormated value={Number(product.price.price) + additionalPrice} currency={product.price.currency} />
                </span>
                {product.price.prefix && <span className='pricePrefix'>/ {product.price.prefix}</span>}
            </span>
        }
    }
}
