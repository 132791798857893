import {isUndefined} from 'lodash';
import {isEmptyObj} from 'ultra/helpers/utils';

const TELEGRAM_PARAM = 'telegram_user';

export function getTelegramMode() {
    if (sessionStorage.getItem('telegram')) return sessionStorage.getItem('telegram');
    const searchParams = new URLSearchParams(window.location.search);
    let bot = searchParams.get('telegram');

    if (bot) {
        sessionStorage.setItem('telegram', bot);
        return bot;
    }

    return false;
}

export function setTelegramUser(user) {
    if (user && !isEmptyObj(user)) {
        sessionStorage.setItem(TELEGRAM_PARAM, JSON.stringify(user));
    }
}

export function getTelegramUser() {
    const data = sessionStorage.getItem(TELEGRAM_PARAM);

    if (data)
        return JSON.parse(data)
    else
        return undefined;
}

export function getTelegramUserId() {
    return getTelegramUser()?.id;
}

export function getTelegramUserName() {
    return getTelegramUser()?.username
}

export function getTelegramDisplayName() {
    const user = getTelegramUser();
    let displayName = user?.first_name
    if (displayName && user?.last_name) displayName += ' ' + user.last_name

    return displayName
}

export function cleanTelegramUser() {
    sessionStorage.removeItem(TELEGRAM_PARAM);
}
