import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useLoaderData, useNavigation, useNavigate} from "react-router-dom";
import {checkActionCode, getAuth} from "firebase/auth";

import {revertPath} from 'ultra/helpers/route';
import {getClientUrl} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {isEmptyObj} from 'ultra/helpers/utils';
import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {getPartnerDetails} from 'ultra/helpers/shop';
import {normalizeRoute} from 'ultra/helpers/route';
import {isAdmin} from 'ultra/helpers/auth';
import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

// stores
import {useConfigStore} from '../../Stores/config'
import {useContentStore} from '../../Stores/content'
import {useToasterStore} from '../../Stores/toster';
import {useBackdropPreloaderStore} from '../../Stores/backdropPreloader';
import {useVirtualUriStore} from '../../Stores/url';

// containers
import SectionTemplate from './Templates/Containers/Section/base/SectionTemplate'

import NodeLinesTemplate from './Templates/Nodes/System/Node/view/NodeLinesTemplate'
import NodeLinesCompactTemplate from './Templates/Nodes/System/Node/view/NodeLinesCompactTemplate'
import NodeTileTemplate from './Templates/Nodes/System/Node/view/NodeTileTemplate'
import NodeTileSectionsTemplate from './Templates/Nodes/System/Node/view/NodeTileSectionsTemplate'
import NodeTableTemplate from './Templates/Nodes/System/Node/view/NodeTableTemplate'
import AfficheTileTemplate from './Templates/Nodes/System/Node/view/AfficheTileTemplate'

import LibraryTemplate from './Templates/Nodes/System/Node/custom/LibraryTemplate'
import ChatsTemplate from './Templates/Nodes/System/Node/custom/ChatsTemplate'
import ShopsTileTemplate from './Templates/Nodes/System/Node/custom/ShopsTileTemplate'

import LinksTemplate from './Templates/Containers/Links/base/LinksTemplate'
import SectionTileTemplate from './Templates/Containers/Section/view/SectionTileTemplate'

import SectionGalleryTemplate from './Templates/Containers/Section/view/SectionGalleryTemplate'
import SalesTemplate from './Templates/Containers/Section/custom/SalesTemplate'
import ServicesTemplate from './Templates/Containers/Section/custom/ServicesTemplate'

// nodes
import NodeTemplate from './Templates/Nodes/System/Node/base/NodeTemplate'

import AliasTemplate from './Templates/Nodes/System/Alias/base/AliasTemplate'
import FormTemplate from './Templates/Nodes/System/Form/base/FormTemplate'

import SaleTemplate from './Templates/Nodes/Custom/Sale/base/SaleTemplate'
import RecommendationTemplate from './Templates/Nodes/Custom/Recommendation/base/RecommendationTemplate'
import LocationTemplate from './Templates/Nodes/Custom/Location/base/LocationTemplate'
import ActivityTemplate from './Templates/Nodes/Custom/Service/base/ServiceTemplate'
import CitiesTemplate from './Templates/Nodes/Custom/Cities/base/CitiesTemplate'

import NetworkingTemplate from './Templates/Nodes/Custom/Networking/base/NetworkingTemplate'

import AnalogueTemplate from './Templates/Nodes/Custom/Analogue/base/AnalogueTemplate'
import DoctorTemplate from './Templates/Nodes/Custom/Doctor/base/DoctorTemplate'
import ClinicTemplate from './Templates/Nodes/Custom/Clinic/base/ClinicTemplate'

import ChatTemplate from './Templates/Nodes/Custom/Chat/base/ChatTemplate'
import UrlTemplate from './Templates/Nodes/Custom/Url/base/UrlTemplate'

import BookTemplate from './Templates/Nodes/Custom/Book/base/BookTemplate'

// complex
import WikiTemplate from './Templates/Complex/Wiki/Wiki/base/WikiTemplate'
import WikiNoteTemplate from './Templates/Complex/Wiki/Note/base/WikiNoteTemplate'

import NewsTemplate from './Templates/Complex/News/News/base/NewsTemplate'
import NewsListTemplate from './Templates/Complex/News/NewsList/base/NewsListTemplate'

import EventsTemplate from './Templates/Complex/Events/Events/base/EventsTemplate'
import EventTemplate from './Templates/Complex/Events/Event/base/EventTemplate'
import TourTemplate from './Templates/Complex/Events/Tour/base/TourTemplate'
import RetreatTemplate from './Templates/Complex/Events/Retreat/base/RetreatTemplate'


// modules
import ShopTemplate from './Templates/Modules/Shop/Shop/base/ShopTemplate'

import ShopSectionTemplate from './Templates/Modules/Shop/Section/base/ShopSectionTemplate'
import ShopSectionTableTemplate from './Templates/Modules/Shop/Section/custom/ShopSectionTableTemplate'

import ShopProductTemplate from './Templates/Modules/Shop/Product/base/ShopProductTemplate'
import JeweleryProductTemplate from './Templates/Modules/Shop/Product/custom/JeweleryProductTemplate'

import InsuranceTemplate from './Templates/Modules/Insurance/base/InsuranceTemplate'

// widgets
import UpdatePassword from '../Profile/Components/UpdatePassword';

import NodeActions from '../Content/Widgets/NodeActions'

import {useSearchStore} from '../../Stores/search';

import {getVirtualUri} from '../../Helpers/content';
import {useGlobalCustomContext} from '../../Helpers/context';
import {setBodyClass, setPageTitle} from '../../Helpers/router';
import {error} from '../../Helpers/logs';

import {emailValidated, saveUserHistoryAndNotify} from '../../Modules/Profile/Helpers/user'
import {USER_ACTIONS} from '../../Modules/Profile/Reducers/user';

import AdaptiveDialog from '../../Components/Adaptive/Dialog';

import './index.scss';

const Templates = {
    // containers
    section: SectionTemplate,
    links: LinksTemplate,

    // view:
    tile: NodeTileTemplate,
    lines: NodeLinesTemplate,
    linesCompact: NodeLinesCompactTemplate,
    tileSections: NodeTileSectionsTemplate,
    table: NodeTableTemplate,
    affiche: AfficheTileTemplate,

    library: LibraryTemplate,
    chats: ChatsTemplate,

    sectionTile: SectionTileTemplate,
    galleries: SectionGalleryTemplate,

    sales: SalesTemplate,
    services: ServicesTemplate,

    // nodes
    node: NodeTemplate,
    alias: AliasTemplate,
    form: FormTemplate,

    newsList: NewsListTemplate,
    news: NewsTemplate,

    networking: NetworkingTemplate,
    sale: SaleTemplate,
    location: LocationTemplate,
    recommendation: RecommendationTemplate,

    service: ActivityTemplate,

    cities: CitiesTemplate,
    shopsTile: ShopsTileTemplate,

    analogue: AnalogueTemplate,
    doctor: DoctorTemplate,
    clinic: ClinicTemplate,

    url: UrlTemplate,
    chat: ChatTemplate,

    // modules
    insurance: InsuranceTemplate,

    shop: ShopTemplate,
    shopSection: ShopSectionTemplate,
    shopProduct: ShopProductTemplate,

    shopSectionTable: ShopSectionTableTemplate,

    jewelery: JeweleryProductTemplate,

    // complex
    wiki: WikiTemplate,
    wikiNote: WikiNoteTemplate,

    topic: NodeTemplate,
    conversation: NodeTemplate,

    events: EventsTemplate,
    event: EventTemplate,
    tour: TourTemplate,
    retreat: RetreatTemplate,

    book: BookTemplate,
};

function AdminNodeInfo() {
    const {content} = useContentStore();

    const {userState} = useGlobalCustomContext();

    if (!isAdmin(userState?.user)) {
        return <></>
    }

    return <div className='AdminNodeInfo'>
        <div className='labelWrap'>
            {content?.page?.content?._did && <>
                <span className="label">DID:</span>
                <span className='labelValue'>
                    {content?.page?.content._did}
                </span>
            </>}
            {content?.page?.content?.originalDid && <>
                <span className="label">Alias:</span>
                <span className='labelValue'>
                    <a href={`?goToDid=${content?.page?.content.city}:${content?.page?.content.did}`} target='_blank'>{content?.page?.content.did}</a>
                </span>
            </>}
        </div>
    </div>
}

export default function Content() {
    // TODO: do not use useLoaderData one more time ! no reload page
    const loaderPageConfig = useLoaderData();

    const navigation = useNavigation();
    const {setVirtualUri, removeVirtualUri} = useVirtualUriStore();

    const {nodeState, nodeDispatch, userDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {showSuccess, showError} = useToasterStore();
    const {content, setContent} = useContentStore();
    const {search, setSearch} = useSearchStore();
    const {configs} = useConfigStore();

    const [editPassPopup, setEditPassPopup] = useState(false);
    const [Node, setNode] = useState(null);

    const location = useLocation();
    const lastHash = useRef('');
    const navigate = useNavigate();

    const city = getClientCity(window);

    const auth = getAuth();

    useEffect(() => {
        if (location.hash) {
            // safe hash for further use after navigation
            lastHash.current = location.hash.slice(1);
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
        setTimeout(() => {
            document
            .getElementById(lastHash.current)
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

            lastHash.current = '';
        }, 100);
        }
    })

    // loading value for skeletons
    useEffect(() => {
        setContent(loaderPageConfig);

        const url = new URL(window.location.href);
        const mode = url.searchParams.get('mode');
        const code = url.searchParams.get('oobCode');

        if (getVirtualUri()) {
            setVirtualUri(getVirtualUri());
        }
        else if (loaderPageConfig?.page?.content?.virtualCatalogs) {
            setVirtualUri(loaderPageConfig?.page?.content?._uri);
        }
        else {
            removeVirtualUri();
        }

        if (navigation.state === 'idle') {
            if (mode === 'verifyEmail') {
                const continueUrl = url.searchParams.get('continueUrl');

                const errorHandler = async (action, err, oobCode, email) => {
                    hideBackdropPreloader()
            
                    let snackbarMessage = err.message || 'Виникла помилка, спробуйте пізніше'
            
                    if (err.code === 'auth/invalid-action-code') {
                        snackbarMessage = 'Посилання не валідне'
                    }
            
                    if (err.code === 'auth/expired-action-code') {
                        snackbarMessage = 'Посилання застаріло'
                    }
            
                    showError({
                        snackbarMessage,
                        onCloseRedirect: continueUrl || getClientUrl(DEFAULT_CITY, '/'),
                    });

                    const details = {error: err, oobCode};

                    // if no email => no record for user
                    if (email) {
                        await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, action, details);
                    } else {
                        await error(LOG_TYPES.ERROR, action, details);
                    }
                }

                showBackdropPreloader();
                checkActionCode(auth, code)
                    .then((info) => {
                        const restoredEmail = info['data']['email'];

                        // ADD ANIMATION TO LOGIN AVATAR
                        emailValidated(restoredEmail)
                            .then(async user => {
                                userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                                await saveUserHistoryAndNotify(restoredEmail, LOG_TYPES.INFO, HISTORY_USER.EMAIL_VALIDATION_SUCCESS);

                                showSuccess({
                                    snackbarMessage: 'Email успішно підтвердженою',
                                    onCloseRedirect: continueUrl || getClientUrl(DEFAULT_CITY, '/'),
                                    snackbarDuration: 10000
                                });

                                hideBackdropPreloader()
                            })
                            .catch(async (err) => {
                                // https://local-miy-prostir.online/?mode=verifyEmail&oobCode=7ROTvo_UQZ_tS3QhyOcpag69JJp1w2WNpyS6-arFNyAAAAGSD4D1xA&apiKey=AIzaSyC8JZNofHOXCCLItHbgkXeMgIX_j4PuVpM&lang=uk
                                errorHandler(HISTORY_USER.EMAIL_VALIDATION_ERROR, err, code, restoredEmail)
                            })
                    })
                    .catch(e => { errorHandler(HISTORY_USER.EMAIL_VALIDATION_CODE_ERROR, e, code) })
            }
            else if (mode === 'resetPassword') {
                setEditPassPopup(true)
            }
        }
        else if (navigation.state === 'loading') {
            // this code added for mobile
            // on dom changes, search field move down without this code
            const ContentDOM = window.document.getElementById('Content');
            ContentDOM.style.minHeight = ''
        }
    }, [navigation])

    useEffect(() => {
        if (content && !isEmptyObj(content?.page?.content) && !isEmptyObj(configs.content.nodes)) {
            updateDocumentTitle();
            updateBodyStyles();

            let template = content.page.config?.template || configs.content.nodes[content.page.content._type]?.template

            if (content.page.content._type === 'shop-product') {
                const partner = getPartnerDetails(content.page.content, content.breadcrumbs);
                if (!partner?.node) return;

                if (partner.node?.partner?.config) template = partner.node.partner.config.productTemplate || template
            } else if (content.page.content._type === 'shop-section') {
                const partner = getPartnerDetails(content.page.content, content.breadcrumbs);
                if (!partner?.node) return;

                if (partner.node?.partner?.config) template = partner.node.partner.config.productSectionTemplate || template        
            }

            if (typeof Templates[template] !== 'undefined') {
                setNode(() => React.createElement(
                    Templates[template],
                    {
                        children: search?.result?.children?.order?.length > 0 ? search.result?.children : content?.children,
                        content: {
                            ...content.page.content,
                            ...content?.page?.config?.values?.[content.page.content._type]
                        },
                        config: {...content?.page?.config},
                        form: {...content.form},
                        breadcrumbs: {...content.breadcrumbs},
                        permits: {...content.permits},
                        key: content.page.content._did,
                    }
                ));
            } else {
                // component doesn't exist yet
                setNode(React.createElement(
                    () => <div>{template} template has not been created yet</div>
                ));
            }
        }
    }, [content, search])

    // updage titles
    const updateDocumentTitle = () => {
        let title = ''

        const cityConfig = getCityConfig(configs, city);
        if (cityConfig) {
            title = cityConfig.city + ' | '
        }

        setPageTitle(content.page.content.title);

        const classes = []

        classes.push(`node_${content?.page?.config?.template || configs?.content?.nodes?.[content?.page?.content?._type]?.template}`)

        if (content.page.content._uri && content.page.content._uri.length > 1)
            classes.push(`uri${content.page.content._uri.replaceAll('\\', '_')}`);

        setBodyClass(classes)
    }

    const updateBodyStyles = () => {
        const url = new URL(window.location.href);
        const viewList = url.searchParams.get('view') === 'list';
        if (viewList) document.body.classList.add('view_list');
    }

    const hideEditPassPopup = () => {
        navigate(window.location.pathname);
        setEditPassPopup(false)
    }

    return <div id='Content' className='Content'>
        {!content?.page?.content?._published && <div className='globalInformer error'>
            <ErrorOutlineOutlinedIcon /> <span>Сторінка знаходиться на премодерації. Її бачите лише автор та модератори</span>
        </div>}

        {Node}

        <span id='page_did'>{content?.page?.content?._did}</span>

        <AdminNodeInfo />

        <NodeActions />

        <AdaptiveDialog open={editPassPopup} onClose={hideEditPassPopup} title="Зміна паролю">
            <UpdatePassword onClose={hideEditPassPopup} />
        </AdaptiveDialog>
    </div>
}
