import React, {useEffect, useState} from 'react'; // useRef

import {isMobile} from "react-device-detect";

import {hashCode} from "ultra/helpers/utils";
import {NOTIFICATION} from "ultra/const/log";

import {useLoaderData, useParams} from "react-router-dom";

import {getClientCity} from 'ultra/configs/general';
import {CONVERSATION} from "ultra/const/general";
import {DEFAULT_CITY} from 'ultra/const/general';

import Skeleton from '@mui/material/Skeleton';
import { Badge, Button } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';

import {getClientCityTimezone} from 'ultra/configs/general';

import {USER_ACTIONS} from '../../../../Modules/Profile/Reducers/user';
import {getConversationMessages, sendConversationMessage, updateMessagesSeenStatus, readAllConversationMessages} from '../../Helpers/conversations'; // getLastMessages
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {getTelegramUserId} from '../../../../Helpers/telegram';
import {getCurrentUrl} from '../../../../Helpers/content';

import {PreloaderTitle} from '../../../../Components/Preloaders/PreloaderTitleAndBreadcrumbs'

import {useConfigStore} from '../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useConversationStore} from '../../../../Stores/conversation';
import {useFullHeightContainerStore} from '../../../../Stores/fullHeightContainer';
import {useToasterStore} from '../../../../Stores/toster';

import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';

import FullHeightContentBlock from '../../../Content/Widgets/FullHeightContentBlock';
import {TableViewContainer} from '../../../Content/Widgets/Table';
import TitleWidget from '../../../Content/Widgets/Title';

import Textarea from '../../../../Components/Form/Textarea';
import UploadImage from '../../../../Components/Form/UploadImage';

import ConversationAvatar from '../../../Content/Widgets/ConversationAvatar';

import {setPageTitle} from '../../../../Helpers/router';
import {getAuthUserID, isYoursAccount} from '../../../../Helpers/user';
import {getConversationTitle} from '../../../../Helpers/conversation';

import './index.scss';

const MESSAGE_BLOCK_HEIGHT = 148;

function Topic() {
    // const {configs} = useConfigStore();
    const {userState} = useGlobalCustomContext();
    const {conversation} = useConversationStore();

    if (!conversation) return <></>

    const title = getConversationTitle(conversation.conversation, userState, conversation.users)

    return <>
        <ConversationAvatar conversation={conversation.conversation} users={conversation.users} />
        <span className='titleText'>{title}</span>
    </>
}

const MobileConfig = {
    visible: ['message'],
    hidden: [],
    infinityScroll: true,
    noHeaders: true
}
const DesktopConfig = {
    visible: ['message'],
    hidden: [],
    infinityScroll: true,
    noHeaders: true
}

export default () => {
    const initData = useLoaderData();

    let {cid} = useParams();

    const {configs} = useConfigStore();
    const {conversation, updateSeenStatus, setConversation, addMessage, attachPrevMessages, attachNextMessages, cleanSendedMessages, setAllReadedInConversation} = useConversationStore();

    const {hideBackdropPreloader} = useBackdropPreloaderStore();

    const {userState, userDispatch} = useGlobalCustomContext();
    const {startScrollTo} = useFullHeightContainerStore();
    const {showError} = useToasterStore();
    const [fields, setFields] = useState();
    const [timeout, setupTimeout] = useState();
    const [preloading, setPreloading] = useState();
    const [getLastMessagesAndMarkReaded, setGetLastMessagesAndMarkReaded] = useState();
    const [getInProgress, setGetInProgress] = useState();
    const [postInProgress, setPostInProgress] = useState();
    const [tableOptions] = useState(isMobile ? MobileConfig : DesktopConfig);
    const [isScrollToBottomVisible, setScrollToBottomVisible] = useState(false);

    const [hasDialog] = useState(checkHasDialog(initData));
    const [bottomPadding, setBottomPadding] = useState();

    const [message, setMessage] = useState({message: undefined, gallery: undefined});

    const [notReadedMessagesStack, setNotReadedMessagesStack] = useState([]);

    const [preRender] = useState(initData?.conversation?.firstNotReaded ? {
        [initData?.conversation?.firstNotReaded] : {
            id: 'not_readed',
            label: 'Не прочитані'
        }
        }
        : {}
    );

    useEffect(() => {
        return () => {
            setConversation(null);
        }
    }, [])

    useEffect(() => {
        // !preventContentUpdate && 
        if ((!conversation || !conversation?.order?.includes(initData.order[0]))) {

            // const updated = {...users};
            // Object.keys(initData.users).map(user => {
            //     if (!updated[user]) {
            //         updated[user] = initData.users[user]
            //     }
            // })

            // setUsers(updated);

            setFields({
                message: {
                    type: 'message',
                    placeholder: 'Повідомлення',
                    extraData: initData.users,
                    conversation: initData.conversation,
                },
            });

            setConversation(initData, timeZone);
            const url = new URL(getCurrentUrl());
            const mid = url.searchParams.get('mid')

            autoScroll(mid);
            // if (mid) {
                // setPreventContentUpdate(true)

                window.history.replaceState(
                    null,
                    '',
                    window.location.pathname,
                );
            // }
        }
        // else {
        //     setPreventContentUpdate(false)
        // }
    }, [initData])

    useEffect(() => {
        setPageTitle(getConversationTitle(initData.conversation, userState, initData.users))

        setBottomPadding(getBottomPadding() + (document.body.classList.contains('virtual-keyboard-attached') ? 200 : 0))

        if (conversation?.scrollToBottom) {
            setTimeout(() => {
                startScrollTo('end')
            }, 100)
        }

        setPreloading(!(fields && userState?.user && conversation));

    }, [fields, userState?.user, conversation])

    const city = getClientCity(window) || DEFAULT_CITY;
    const timeZone = getClientCityTimezone(configs, city);


    const autoScroll = (mid) => {
        if (mid) {
            startScrollTo('.item_' + mid);
            hideBackdropPreloader();
        }
        else if (initData?.conversation?._not_readed > 0) {
            startScrollTo('.pre_not_readed');
        }
        else {
            startScrollTo('end');
        }
    }

    function checkHasDialog(data) {
        return data?.conversation?.conversation
            || (
                data?.conversation?.channel
                && !data?.conversation?.private
                && data?.conversation?.topic?.admins?.includes(userState?.user?.id)
            )
    }

    function getBottomPadding() {
        return checkHasDialog(conversation) ? MESSAGE_BLOCK_HEIGHT : 0;
    }

    const selectClasses = (data) => {
        let s;

        if (userState?.user) {
            if (data && !data?._seen_by) {
                s = 'tr_unseen';
            } else if (data && data?._seen_by && !data._seen_by.includes(getAuthUserID(userState))) {
                s = 'tr_unseen';
            }

            if (isYoursAccount(userState, data?._init_by)) {
                s += ' tr_sender'
            } else {
                s += ' tr_recipient'
            }
        }

        return `${s ? s : ''}`
    }

    const onMessageSendHandler = () => {
        setPostInProgress(true)
        setMessage({})

        const _timestamp = (new Date()).getTime()
        const hash = Math.floor(Math.random() * Math.abs(hashCode(userState?.user?.id)));

        const msg = {
            details: {
                _mid: _timestamp.toString() + "_" + hash,
                _cid: cid,
                message: message.message,
            },
            sending: true,
            _seen_by: [userState?.user?.id],
            _code: NOTIFICATION.MESSAGE,
            _init_by: userState?.user?.id,
            _init_by_name: userState?.user?.displayName,
            _timestamp
        }

        addMessage(msg, timeZone) // userState?.user?.id, 

        startScrollTo('end')

        sendConversationMessage(cid, msg)
            .then(() => { setPostInProgress(false)})
            .catch((e) => {
                showError({
                    snackbarMessage: e.message,
                    onCloseHandler: () => {
                        cleanSendedMessages()
                        setPostInProgress(false)

                        startScrollTo('end')
                    }
                })
            })
    }

    const onUploadImageChanged = (e) => {
        console.log(e.target.value)
    }

    const notReadedMessagesHandler = () => {
        if (conversation?.conversation?._not_readed) {
            if (conversation?.conversation?._not_readed > CONVERSATION.PER_PAGE
                // && (!conversation.order.includes(conversation?.conversation?._last_message?.details?._mid))
            ) {
                // it will change get next request to get
                setGetLastMessagesAndMarkReaded(true);
            }
            else {
                // run read all in background, it's not async to make UI quick
                readAllConversationMessages(conversation.conversation._cid)
            }
        
            // remove badge number in ui
            setAllReadedInConversation(userState?.user?.id, timeZone)
        }

        startScrollTo('end');
    }

    const checkVisible = (visible) => {
        // check visible messages for conversation
        const uid = userState?.user?.id;
        const notReadedMessages = [];

        visible.map(mid => {
            if (uid && !conversation.list[mid]?._seen_by?.includes(uid)) {
            // && !notReadedMessagesStack.includes(mid)
                notReadedMessages.push(mid);
            }
        });

        const lasVisible = visible[visible.length - 1];
        const lastVisiblePositionInDataList = conversation.order.findIndex(i => i === lasVisible);

        setScrollToBottomVisible(lastVisiblePositionInDataList < (conversation.order.length - 1));

        debouncedRunSeen(uid, conversation.conversation, notReadedMessages);
    }

    const debouncedRunSeen = (uid, conversation, notReadedMessages) => {
        if (notReadedMessages.length === 0) return;

        // get new messages
        const newMessages = notReadedMessages.filter(mid => !notReadedMessagesStack.includes(mid))

        // list of messages to update
        const messages = notReadedMessagesStack.concat(newMessages)
        setNotReadedMessagesStack(messages)

        // update on backend
        clearTimeout(timeout)

        const t = setTimeout(() => {
            // run
            let leng = conversation._not_readed - messages.length;
            const notReaded = leng >= 0 ? leng : 0;

            // update visual before request
            const lastReaded = messages[messages.length - 1];
            updateSeenStatus(uid, messages, notReaded, lastReaded, timeZone);

            updateMessagesSeenStatus(conversation._cid, messages)
                .then((result) => {
                    const updated = notReadedMessagesStack.filter(mid => !result.includes(mid));
                    setNotReadedMessagesStack(updated);
                })
                .catch((e) => {
                    showError({
                        snackbarMessage: e.message
                    })
                })
        }, 100);
        setupTimeout(t);
    }

    const getPrevMessages = () => {
        if (getLastMessagesAndMarkReaded) {
            return {
                promise: Promise.resolve({})
            }
        }

        if (getInProgress || !conversation) {
            return {
                promise: Promise.resolve({})
            }
        }

        setGetInProgress(true)
        return {
            promise: new Promise(async resolve => {
                const before = conversation.order[0]
                const data = await getConversationMessages(conversation.conversation._cid, false, before).promise

                setGetInProgress(false)
                resolve(data)
            })
        }
    }

    const getNextMessages = () => {
        if (getLastMessagesAndMarkReaded) {
            // setGetInProgress(true)
            return {
                promise: new Promise(async resolve => {
                    // get last data, like usual
                    console.log(3)
                    const data = await getConversationMessages(conversation.conversation._cid, null, null, null, true).promise

                    // read all will be run in endpoint, so user should be refreshed after
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START})

                    // maker all data readable
                    // data.list = makeReaded(conversation, data.list userState?.user?.id)

                    // setGetInProgress(false)
                    setGetLastMessagesAndMarkReaded(false)

                    data.resetData = true;

                    resolve(data)
                })
            }
        }

        if (getInProgress || !conversation) {
            return {
                promise: Promise.resolve({})
            }
        }

        setGetInProgress(true)
        return {
            promise: new Promise(async resolve => {
                const after = conversation.order[conversation.order.length - 1]
                console.log(4)
                const data = await getConversationMessages(conversation.conversation._cid, after).promise

                setGetInProgress(false)
                resolve(data)
            })
        }
    }

    const hasNextLoader = (data) => {
        if (getLastMessagesAndMarkReaded) return true;
        if (conversation?.amount < CONVERSATION.PER_PAGE) return false;
        // if (conversation?.amount <= data.order.length) return false;

        return !data.order.includes(data?.conversation?._last_message?.details?._mid)
    }

    const hasPrevLoader = (data) => {
        if (getLastMessagesAndMarkReaded) return false;
        if (conversation?.amount < CONVERSATION.PER_PAGE) return false;
        // if (conversation?.amount <= data.order.length) return false;

        return !data.order.includes(data?.conversation?._first_message?.details?._mid)
    }

    const breadcrumbs = {
        // '\\': {
        //     title: getCityConfig(configs, city).city || 'Головна'
        // },
        '\\profile\\messages': {
            title: 'Сповіщення'
        }
    }

    // const actions = [
    //     {
    //         id: 'read_all',
    //         icon: <MarkChatReadOutlinedIcon />,
    //         onClick: () => {
    //             notReadedMessagesHandler()
    //             // readAllConversationMessages(conversation.conversation._cid)
    //             // nodeDispatch({type: NODE_ACTIONS.EDIT, data: {city, uri: node._uri}})
    //         }
    //     }
    // ]
    
    return <div className='Content'>
            <div className={`ConversationPage ${hasDialog ? '' : 'noMessanger'} ${conversation?.conversation?.participants?.length > 2 && 'Multi'}`}>
                {preloading ? <PreloaderTitle /> : <TitleWidget permits={{}} content={{title: <Topic />}} breadcrumbs={breadcrumbs} />}

                {conversation?.order.length === 0 && <div>
                    У вас немає сповіщень
                </div>}

                {(isScrollToBottomVisible || conversation?.conversation?._not_readed > 0) &&
                    <div className='notReadedMessages' onClick={notReadedMessagesHandler}>
                        <Badge
                            color='primary'
                            badgeContent={conversation.conversation._not_readed}
                            ><KeyboardArrowDownIcon />
                        </Badge>
                    </div>
                }

                <FullHeightContentBlock
                    bottomPadding={bottomPadding}
                    scrollContainer={() => document.querySelector('.FullHeightContentBlockContent')}
                    className={hasDialog ? 'hasPadding' : ''}
                    showPreloader={preloading}
                    preloader={<PreloaderTable rows={[1,2,3,4,5]} />}
                >
                    {fields && userState?.user && conversation && <TableViewContainer
                        content={conversation}
                        categories={conversation.categories}

                        fields={fields}
                        tableOptions={tableOptions}

                        showPagination={true}

                        selectClasses={selectClasses}

                        bottomPadding={bottomPadding + (isMobile ? (12) : (56))}

                        preRender={preRender}

                        scrollContainer={() => document.querySelector('.FullHeightContentBlockContent')}

                        checkVisible={checkVisible}

                        showPreloaderBlock={true}
                        hasNextLoader={hasNextLoader}
                        getNext={getNextMessages}
                        addNext={data => {
                            if (data.resetData) {
                                data.scrollToBottom = true;
                                setConversation(data, timeZone)
                                delete data.resetData;
                            }
                            else attachNextMessages(data, timeZone)
                        }}

                        hasPrevLoader={hasPrevLoader}
                        getPrev={getPrevMessages}
                        addPrev={data => {
                            if (data.resetData) {
                                setConversation(data, timeZone)
                                delete data.resetData
                            }
                            else attachPrevMessages(data, timeZone)
                        }}

                        preloader={<PreloaderTable rows={[1,2,3,4,5]} />}
                    />}
                </FullHeightContentBlock>

                {hasDialog && preloading && <div className='ConversationMessenger'>
                    <Skeleton variant="rectangular" width={'100%'} height={120} style={{marginBottom: '1rem'}} />
                </div>}

                {hasDialog && !preloading && <div className='ConversationMessenger'>
                    <div className='textName'>
                        <Textarea
                            disabled={postInProgress}
                            content={message}
                            field={{id: 'message', placeholder: 'Ваше повідомлення...', options: {maxRows: 4}}}
                            onChange={(e) => {
                                setMessage({message: e.target.value})
                            }}
                            onFocus={(e) => {
                                console.log(getTelegramUserId())
                                if (getTelegramUserId()) {
                                    console.log(getBottomPadding() + 200);
                                    setBottomPadding(getBottomPadding() + 200);
                                    
                                    setTimeout(() => {
                                        startScrollTo('end')
                                        window.scrollTo(0, 0);
                                    }, 0)
                                }
                            }}
                            onBlur={(e) => {
                                console.log(getTelegramUserId())
                                if (getTelegramUserId()) {
                                    console.log(getBottomPadding())
                                    setBottomPadding(getBottomPadding())
                                }
                            }}
                            
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            size='small'
                            className='sendButton'
                            variant='outlined'
                            disabled={postInProgress || !message?.message?.length}
                            onClick={onMessageSendHandler}
                        >
                            <SendIcon />
                        </Button>

                        <UploadImage
                            size='small'
                            minimal
                            content={message}
                            field={{id: 'gallery', placeholder: 'Завантажити фото...'}}
                            onChange={onUploadImageChanged}
                        />
                    </div>
                </div>}
            </div>

            {/* TODO: add - read all / settings / remove */}
            {/* {conversation?.conversation?._not_readed > 0 && <div className='CurrentPageInlineActions'><InlineActions actions={actions} unfolded id="conversation_actions" /></div>} */}
        </div>
}
