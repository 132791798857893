import {getClientConfig} from 'ultra/configs/general';
import {post} from './http';

const {api} = getClientConfig(window)

export function getOrderStatusLink(partner, type, id, order, action) {
    return `${api}/partner/order/${partner}/${type}/${id}/${order}/${action}`;
}

export function setOrderStatus(partner, type, id, order, action) {
    let link = getOrderStatusLink(partner, type, id, order, action)
    return post(link);
}
