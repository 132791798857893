import { create } from 'zustand'

export const useSearchStore = create((set) => ({
  search: {
    query: null,
    uri: null,
    result: null,
  },

  displaySearch: false,

  setDisplaySearch: (search) => set((state) => {
    return {displaySearch: search, search: state.search}
  }),

  setSearch: (query, uri, result) => set((state) => {
    return {search: {query, uri, result}, displaySearch: state.displaySearch};
  }),

  cleanSearch: () => set((state) => {
    return {
      search: {
        query: null,
        uri: null,
        result: null,
      },
      displaySearch: state.displaySearch
    };
  }),

  updateSearchResult: (page) => set((state) => {
    function update (source) {
      let wasUpdated = false;

      const updated = {...source};
      if (updated?.order) {
        const list = {...updated.list};
  
        updated.order.map(uri => {
          if (list[uri]._did === page._did) {
            wasUpdated = true;
            list[uri] = {...page};
          }
        })
  
        updated.list = list;
      }

      return wasUpdated ? updated : source;
    }

    const search = {...state.search};
    search.result.children = update(search.result.children);
    search.result.global = update(search.result.global);

    return {search, displaySearch: state.displaySearch}
  }),

  renameSearchResult: (renamed) => set((state) => {
    function update (source) {
      let wasUpdated = false;

      const updated = {...source};

      const list = {...updated.list};
      const permits = {...updated.permits};
      const order = [...updated.order];
  
      // TODO: highlights are losed
      Object.keys(renamed).map(oldUri => {
        const newUri = renamed[oldUri]._uri;
        list[newUri] = renamed[oldUri];
        permits[newUri] = permits[oldUri];
  
        delete list[oldUri];
  
        const i = order?.findIndex(i => i === oldUri);
        order[i] = newUri;

        wasUpdated = true;
      })
  
      updated.list = list;
      updated.order = order;
      updated.permits = permits;

      return wasUpdated ? updated : source;
    }

    const search = {...state.search};
    search.result.children = update(search.result.children);
    search.result.global = update(search.result.global);

    return {search, displaySearch: state.displaySearch}
  }),

  validatePageSearchResult: (_published, path) => set((state) => {
    function update (source) {
      let wasUpdated = false;

      const updated = {...source};
      if (updated?.order) {
        updated.order.map(uri => {
          if (updated.list[uri]._uri === path.uri) {
            const node = {...updated.list[uri]}
            node._published = _published;
    
            updated.list[uri] = node;

            wasUpdated = true
          }
        })
      }

      return wasUpdated ? updated : source;
    }

    const search = {...state.search};
    search.result.children = update(search.result.children)
    search.result.global = update(search.result.global)

    return {search}
  }),

  deleteSearchResult: (uri) => set((state) => {
    function update (source) {
      let wasUpdated = false;

      const updated = {...source};

      if (updated?.order) {
        if (updated.list[uri]) {
          delete updated.list[uri];
          wasUpdated = true;
        }

        const i = updated.order?.findIndex(i => i === uri);
        if (i > -1) {
          updated.order.splice(i, 1);
          wasUpdated = true;
        }
      }

      return wasUpdated ? updated : source;
    }

    const search = {...state.search};
    search.result.children = update(search.result.children)
    search.result.global = update(search.result.global)

    return {search, displaySearch: state.displaySearch}
  })
}))
