import {getClientConfig} from 'ultra/configs/general';
import {post, get} from './http';

const {api} = getClientConfig(window)

export async function searchReindex() {
    return post(`${api}/search/reindex`, {});
}

export async function searchNode(city, query, uri) {
    return get(`${api}/search/${city}/${query}?uri=${uri}`);
}
