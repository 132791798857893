import React, {useState, useEffect} from 'react'; // useEffect

// import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// import CKEditorControl from '../CKEditor';

import './index.scss';

export default function Textarea(props) {
    const {content, disabled, field, isEditMode, onChange, onFocus, onBlur, showErrorText} = props;
    // const field = props.field || {}
    // field.options = field.options || {}
    // field.options.noToolbar = true
    // return <CKEditorControl {...props} field={field} className="Textarea" />

    // // const [value, setValue] = useState('')
    // // useEffect(() => {
    // //     setValue()
    // // }, [content[field.id]])

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    return (<>
        <TextField
          fullWidth
          error={Boolean(showErrorText)}
          helperText={showErrorText}
          multiline
          value={content[field.id] || ''}
          required={field.required}
          label={field.placeholder}
          variant="outlined"
          minRows={field?.options?.minRows || 4}
          maxRows={field?.options?.maxRows}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isDisabled}
          inputProps={{maxLength: field?.options?.maxLength}}
        />
        <div className='fieldNotes'>
            {field?.details && <p>{field?.details}</p>}
            {field?.options?.maxLength && <p>* максимальна кількість символів - {field?.options?.maxLength}</p>}
        </div>
    </>)
}
