import { create } from 'zustand'

export const useContentStore = create((set) => ({
  content: {},

  setContent: (content) => set(() => {
    return {content};
  }),

  updateChildren: (children) => set((state) => {
    const content = {...state.content};

    content.children = children;

    return {content}
  }),

  validatePage: (_published, path) => set((state) => {
    const content = {...state.content};

    // node page
    if (content.page?.content) {
      if (content.page.content._uri === path.uri) {
        content.page.content = validatePage(content.page.content, _published)
      }
    }

    // childrens
    if (content.children) {
      const children = {...content.children};

      children.order.map(uri => {
        if (children.list[uri]._uri === path.uri) {
          children.list[uri] = validatePage(children.list[uri], _published)
        }
      })

      content.children = children;
    }

    return {content}
  }),

  validatePageVirualUri: (_published, path) => set((state) => {
    const content = {...state.content};

    const _virtual_uri = {...content._virtual_uri};
    const children = {..._virtual_uri.children};

    children.order.map(uri => {
      if (children.list[uri]._uri === path.uri) {
        children.list[uri] = validatePage(children.list[uri], _published)
      }
    })

    _virtual_uri.children = children;
    content._virtual_uri = _virtual_uri;

    return {content}
  }),

  updatePage: (page) => set((state) => {
    const content = {...state.content};

    // node page
    if (content.page?.content) {
      if (content.page.content._did === page._did) {
        content.page.content = page;
      }
    }

    // childrens
    if (content.children) {
      const children = {...content.children};

      children.order.map(uri => {
        if (children.list[uri]._did === page._did) {
          children.list[uri] = page;                            
        }
      })

      content.children = children;
    }

    return {content}
  }),

  updatePageVirtualUri: (virtualData) => set((state) => {
    const content = {...state.content};

    const _virtual_uri = {...content._virtual_uri};
    const children = {..._virtual_uri.children};
    const page = {..._virtual_uri.page};

    if (page.content._did === virtualData._did) {
      page.content = virtualData;
    }
    else {
      children.order.map(uri => {
        if (children.list[uri]._did === virtualData._did) {
          children.list[uri] = virtualData;                            
        }
      })
    }

    content._virtual_uri = _virtual_uri;
    content._virtual_uri.children = children;
    content._virtual_uri.page = page;

    return {content}
  }),

  addChildNode: (node, permits) => set((state) => {
    const content = {...state.content};
    content.children = addChildNode(content.children, node, permits);
    return {content}
  }),

  addChildNodeVirtualUri: (node, permits) => set((state) => {
    const content = {...state.content};
    const _virtual_uri = {...content._virtual_uri};
    let children = {..._virtual_uri.children};

    children = addChildNode(children, node, permits);

    content._virtual_uri = _virtual_uri;
    content._virtual_uri.children = children;

    return {content}
  }),

  renamePages: (renamed) => set((state) => {
    const content = {...state.content};

    if (content.page) {
      Object.keys(renamed).map(oldUri => {
        if (oldUri === content.page._uri) {
          content.page = renamed[oldUri]
        }
      })
    }

    content.children = renamePages(renamed, content.children);

    return {content}
  }),

  renamePagesVirtualUri: (renamed) => set((state) => {
    const content = {...state.content};
    const _virtual_uri = {...content._virtual_uri};

    content._virtual_uri = _virtual_uri;
    content._virtual_uri.children = renamePages(renamed, _virtual_uri.children);

    return {content};
  }),

  deleteChildNode: (uri) => set((state) => {
    const content = {...state.content};
    
    content.children = deleteChildNode(content.children, uri)

    return {content}
  }),

  deleteChildNodesVirtualUri: (uri) => set((state) => {
    const content = {...state.content};
    const _virtual_uri = {...content._virtual_uri};
    let children = {..._virtual_uri.children};

    children = deleteChildNode(children, uri);
    content.children = deleteChildNode(content.children, uri)

    content._virtual_uri = _virtual_uri;
    content._virtual_uri.children = children;

    return {content};
  }),
}))

// methods
function addChildNode(data, node, newpermits) {
  if (data) {
    const source = {...data};
    const list = {...source.list || {}};
    const permits = {...source.permits || {}};
    const order = [...source.order || []];

    list[node._uri] = node;
    permits[node._uri] = newpermits;
    order.unshift(node._uri);

    source.list = list;
    source.permits = permits;
    source.order = order;

    return source;
  }
  return data;
}

function deleteChildNode (data, uri) {
  const source = {...data}

  if (source?.order) {
    const order = [...source.order]
    const list = {...source.list}

    delete list[uri]

    const i = order?.findIndex(i => i === uri);
    if (i > -1) { order.splice(i, 1); }

    source.list = list;
    source.order = order;
  }

  return source;
}

function renamePages(renamed, data) {
  const source = {...data}

  if (source?.order) {
    const order = [...source.order]
    const list = {...source.list}
    const permits = {...source.permits}

    Object.keys(renamed).map(oldUri => {
      list[renamed[oldUri]._uri] = renamed[oldUri];
      permits[renamed[oldUri]._uri] = permits[oldUri];

      delete list[oldUri];
      delete permits[oldUri];

      const i = order?.findIndex(i => i === oldUri);
      order[i] = renamed[oldUri]._uri;
    })

    source.list = list;
    source.permits = permits;
    source.order = order;
  }

  return source;
}

function validatePage(store, _published) {
  const clone = {...store};
  clone._published = _published;
  return clone;
}
