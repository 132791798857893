import React from 'react';
import { isMobile } from 'react-device-detect';
import {useLocation} from 'react-router-dom'

import {normalizeRoute} from 'ultra/helpers/route';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientUrl} from 'ultra/configs/general';

import {useConfigStore} from '../../../../Stores/config';

import {getFilter} from '../../../../Helpers/filters';

import FilterField from '../../../../Components/Form/Filter';

import './index.scss'

export default function CityFilter(props) {
    const {list} = props;

    const location = useLocation();

    const {configs} = useConfigStore();
    const {filters} = configs;

    const filter = getFilter(filters, undefined, 'cities');

    const cities = {}
    cities.label = filter.label
    cities.values = {}
    list.map(city => {
        if (city === DEFAULT_CITY) {
            cities.values[city] = "Головна сторінка"
        }
        else {
            cities.values[city] = filter.values[city]
        }
    })

    console.log(cities)

    if (Object.keys(cities.values).length < 2) return <></>

    const cityFilter = {
        id: 'city',
        placeholder: 'Місто',
        type: 'filter',
        values: cities,
        options: {
            hasNoEmptyValue: true
        }
    }

    return <div className='FiltersLine'>
        <FilterField
            size={!isMobile ? "small" : ""}
            content={{
                'city': getClientCity(window) || DEFAULT_CITY
            }}
            field={cityFilter}
            onChange={(e) => {
                const city = e.target.value;
                const path = normalizeRoute(location.pathname) + location.search;
                window.location.assign(getClientUrl(city, path))
            }}
        />
    </div>
}
