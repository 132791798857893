import dayjs from 'dayjs';
import React from 'react';

import {NavLink as RouterLink} from "react-router-dom";
import JsonView from 'react18-json-view'
import {restorePath} from 'ultra/helpers/route'
import {hasFullAccess} from 'ultra/helpers/auth';

import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {getClientCity} from 'ultra/configs/general';
import {getFormatedDate, timestampToDate} from 'ultra/helpers/date'
import {DEFAULT_CITY, NO_CITY} from 'ultra/const/general';
import {CurrencyFormat} from 'ultra/helpers/utils';
import {totalPrice} from "ultra/helpers/shop";
import {getFacebookLink, getInstagramLink} from 'ultra/helpers/messengers';
import {getClientCityTimezone} from 'ultra/configs/general';

import Chip from '@mui/material/Chip';

import {useConfigStore} from '../../../../../Stores/config';

import {getBookStatusLabel} from '../../../Helpers/library';
import {isYoursAccount} from '../../../../../Helpers/user';
import {makeUrlsClickable} from '../../../../../Helpers/format';
import {useGlobalCustomContext} from '../../../../../Helpers/context';

import MessageToReader from '../../../Widgets/MessageToReader';
import EmailButton from '../../../Widgets/EmailButton';
import BotNotificationButton from '../../../Widgets/BotNotificationButton';

import TopicAvatar from '../../../Widgets/TopicAvatar';
import PartnerImage from '../../../Widgets/PartnerImage';
import FiltertValue from '../../../Widgets/FilterValue';
import FilterMultiChip from '../../../Widgets/FilterMultiChip';
import MessengerValue from '../../../Widgets/MessengerValue';
import LocationValue from '../../../Widgets/LocationValue';
import URLValue from '../../../Widgets/URLValue';
import CKEditorContent from '../../../Widgets/CKEditorContent';
import Avatar from '../../../Widgets/Avatar';
import NodeImage from '../../../Widgets/NodeImage';
import ContentImage from '../../../Widgets/ContentImage';
import PriceWidget from '../../../Widgets/Price';
import Link from '../../../Widgets/Link';
import NodeValue from '../../../Widgets/NodeValue';
import Notification from '../../../Widgets/Notification';
import DateValue from '../../../Widgets/DateValue';
import TimeValue from '../../../Widgets/TimeValue';
import DefaultImage from '../../../Widgets/DefaultImage';
import TextZoomImage from '../../../Widgets/TextZoomImage';

import UserHistoryLink from '../../UserHistoryLink';
import UserHistoryLinkByID from '../../UserHistoryLinkByID';

import NotPublishedIcon from '../../../Widgets/NotPublishedIcon';

import ConversationAvatar from '../../ConversationAvatar';
import {getConversationTitle} from '../../../../../Helpers/conversation';

import {getDateText} from '../../../../../Modules/Profile/Helpers/conversations'

import {PreloaderMessage} from '../../../../../Components/Preloaders/PreloaderMessages'

import './index.scss'


function getNodeTitle(published, values, value, noChildLinks) {
    return <span className={published ? "" : "notPublishedWrap"}>
        {published ? '' : <NotPublishedIcon />}

        {!noChildLinks && values._uri && <RouterLink to={restorePath(values._uri)} onClick={(e) => e.stopPropagation()}>
            <span className='notPublishedTitle'>{value}</span>
        </RouterLink>}

        {(noChildLinks || !values._uri) && <>
            <span className="notPublishedTitle">{value}</span>
        </>}
    </span>
}

export default function CellContent(props) {
    const {fields, field, values, published, placeholder, noChildLinks} = props;

    const {configs} = useConfigStore();
    const {userState} = useGlobalCustomContext();

    if (!values) return <></>

    const city = getClientCity(window) || DEFAULT_CITY;
    const timeZone = getClientCityTimezone(configs, city);
    const config = fields?.[field];
    const value = values[field];

    function getNodeCellContent() {
        if (field === '_reading_by') {
            return <>
                <div className='text'>
                    <span>{values._reading_by.name} </span>
                    {values?._reading_from && <span>з {getFormatedDate(timestampToDate(values?._reading_from), timeZone)} до {getFormatedDate(timestampToDate(values?._reading_till), timeZone)}</span>}
                </div>
    
                <div className='actions'>
                    <EmailButton fullWidth label="Написати листа" email={values._reading_by.email} />
                    <MessageToReader reading={values._reading_by} />
                    <BotNotificationButton fullWidth id={values._reading_by} />
                </div>
    
                <div className='deposit'>
                    <TextZoomImage
                        label={<Button fullWidth variant='outlined'>Скріншот про сплату <PriceWidget value={values.deposit} /></Button>}
                    >
                        <DefaultImage image={values?._reading_by?.deposit} width="60" zoom />
                    </TextZoomImage>
                </div>
            </>
        }
    
        if (field === 'title') {
            if (config?.type === 'book-title-with-status') {
                return <div>
                    <div className='title'>{getNodeTitle(published, values, value, noChildLinks)}</div>
                    <div className='status'>{getBookStatusLabel(values)}</div>
                </div>
            }
    
            if (config?.type === 'networking-link') {
                return <UserHistoryLink id={values._created_by} name={getNodeTitle(published, values, value, noChildLinks)} />
            }
    
            return getNodeTitle(published, values, value, noChildLinks);
        }
    
        if (config?.type === 'user-title') {
            let v = values.displayName;
            if (values._error) v += ` (error: ${values._error})`;
            return v;
        }

        if (config?.type === 'message') {
            if (userState?.user) {
                const initByYou = isYoursAccount(userState, values._init_by);
                let className = initByYou ? 'CellMessage sender' : 'CellMessage recipient';

                if (config.conversation.conversation && config.conversation.private) {
                    className += ' privateConversation';
                }
                else if (config.conversation.conversation && !config.conversation.private) {
                    className += ' publicConversation';
                }

                const participants = config.conversation.participants;
                const readedByOthers = participants
                    .filter(id => id !== userState?.user?.id)
                    .filter(id => !values?._seen_by?.includes(id))
                    .length === 0;

                return <div className={className}>
                    <div className="name">
                        <div className="avatar">
                            <Avatar image={config.extraData?.[values._init_by]?.photoURL} />
                        </div>
        
                        <span>{config.extraData?.[values._init_by]?.displayName}</span>
                        {config.extraData?.[values._init_by]?.roles && config.extraData?.[values._init_by].roles.map(role => <Chip key={`role_${values._init_by}_${role}`} label={role} />)}
                    </div>
                    <div className='message'>
                        <div className='text'>
                            <div className='content'>
                                <Notification code={values._code} values={values} />
                            </div>
                            <div className='timestamp'>
                                <TimeValue value={values._timestamp} />

                                {initByYou && <>
                                    {values.sending && <CircularProgress />}
                                    {config.conversation.private && <>
                                        {!values.sending && !readedByOthers && <DoneIcon />}
                                        {!values.sending && readedByOthers && <DoneAllIcon className='readed' />}
                                    </>}
                                    {!config.conversation.private && <>
                                        {!values.sending && <DoneIcon className='readed' />}
                                    </>}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            return <Skeleton variant="circular" width={66} height={66} />
        }

        if (config?.type === 'conversation') {
            const title = getConversationTitle(values, userState, config.extraData)

            if (userState?.user) {
                return <div className='CellConversation'>
                    <div className='CellConversationTitle'>
                        <RouterLink to={`/profile/messages/${values._cid}`}>{title}</RouterLink>
                    </div>
                    <div className='content'>
                        <Notification code={values._last_message._code} values={values._last_message} short />
                    </div>
                    {values._updated && <div className='timestamp updated'>
                        {getDateText(timestampToDate(values._updated))?.label}
                        &nbsp;
                        <TimeValue value={values._updated} />
                    </div>}
                </div>
            }
            else {
                return <PreloaderMessage hideAvatar />
            }
        }
    
        if (config?.type === 'order') {
            if (config?.extraData?.link?.partner?.shop) {
                return <RouterLink to={`/profile/history/shop/${config.extraData.link.partner.id}/${config.extraData.link.partner.shop}/${values._oid}`}>{values._oid}</RouterLink>
            }
            else if (config?.extraData?.link?.partner?.form) {
                return <RouterLink to={`/profile/history/form/${config.extraData.link.partner.id}/${config.extraData.link.partner.shop}/${values._oid}`}>{values._oid}</RouterLink>
            }
        }
    
        if (config?.type === 'conversation-open') {
            return <RouterLink to={`/profile/messages/${values._cid}`} className="moreBtnLink">
                <KeyboardArrowRightIcon className='moreBtn' />
            </RouterLink>
        }
    
        if (config?.type === 'conversation-logo') {
            if (userState?.user) {
                return <ConversationAvatar showBadge conversation={values} users={config.extraData} />
            }
            return <Skeleton variant="circular" width={66} height={66} />
        }
    
        if (config?.type === 'user') {
            return <UserHistoryLink id={value.id} name={value.name} />
        }
    
        if (config?.type === 'location') {
            return <LocationValue value={value} />
        }
    
        if (config?.type === 'url') {
            return <URLValue value={value} />
        }
    
        if (config?.type === 'messenger') {
            return <MessengerValue messenger={value} />
        }
    
        if (config?.type === 'city') {
            if (!value) return <></>;
    
            if (value === NO_CITY) return <>Не вказано</>
            if (value === DEFAULT_CITY) return <Chip className='cityLabel' variant="outlined" label="Головна" />
            return <Chip className='cityLabel' variant="outlined" label={configs.content.cities[value].city} />
        }
    
        if (config?.type === 'filter') {
            if (config?.options?.multi) {
                return <FilterMultiChip className="textOnly" values={value} config={config} />
            } else {
                return <FiltertValue value={value} config={config} />
            }
        }
    
        if (config?.type === 'wysiwyg' || config?.type === 'textarea') {
            return <CKEditorContent content={value} />
        }
    
        if (config?.type === 'tags') {
            return <>{value.join(', ')}</>;
        }
    
        if (config?.type === 'price') {
            return <PriceWidget value={value} />
        }
    
        if (config?.type === 'timestamp') {
            return <DateValue value={value} />
        }
    
        if (config?.type === 'boolean') {
            const label = config?.options?.labels?.[value];
            if (label) return <>{label}</>;
            if (!label) return <>{value === true ? 'так' : 'ні'}</>;
        }
    
        if (config?.type === 'node') {
            return <NodeValue value={value} />
        }
    
        if (config?.type === 'roles') {
            return <>{value.map(role => <Chip key={role} variant="outlined" label={role} />)}</>
        }
    
        if (config?.type === 'history-user-link') {
            return <UserHistoryLinkByID id={value} onlyText={!hasFullAccess(userState?.user)}/>
        }
    
        if (config?.type === 'shop-total') {
            return totalPrice(values.data.order);
        }

        if (config?.type === 'order-status-action') {
            return <Chip variant='outlined' label={(config.extraData?._partner?.actions?.[value] || config.extraData?._actions?.[value])?.label} />;
        }
    
        if (config?.type === 'order-status') {
            if (!values.status_label) return <></>;
            return <Chip variant='outlined' label={values.status_label} />;
        }
    
        if (config?.type === 'history-details') {
            return <JsonView
                src={value}
                editable={false}
                collapsed={false}
                enableClipboard={false}
            />
        }
    
        if (config?.type === 'json') {
            return <JsonView
                src={value}
                editable={false}
                collapsed={false}
                enableClipboard={false}
            />
        }
    
        if (config?.type === 'notification') {
            return <Notification code={value} values={values} />
        }
    
        if (config?.type === 'request') {
            return <pre dangerouslySetInnerHTML={{ __html: makeUrlsClickable(value) }} className="DetailsBlockWrap"></pre>
        }
    
        if (config?.type === 'book-status') {
            return getBookStatusLabel(values)
        }
    
        // by field name
        if (field === 'creationTime') {
            return <>{dayjs(new Date(value)).format('YYYY-MM-DD HH:mm')}</>;
        }
    
        if (field === 'facebook') {
            return <Link blank link={getFacebookLink(value)} />
        }
    
        if (field === 'instagram') {
            return <Link blank link={getInstagramLink(value)} />
        }
    
        // images
        if (field === 'photoURL') {
            return <Avatar image={value} />
        }
    
        if (config?.type === 'node_image') {
            return <NodeImage city={values._city} values={values} className="tableImages" />
        }
    
        if (config?.type === 'image') {
            return <ContentImage src={field} image={value} className="tableImages" />
        }
    
        return <>{value}</>
    }

    const content = getNodeCellContent();

    if (content) {
        if (placeholder) {
            return <>
                <span className="label">{placeholder}:</span>
                <span className="labelValue">{content}</span>
            </>
        }
        else {
            return content;
        }
    }

    return <></>
}