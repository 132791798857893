import TextField from '../Text';

export default function NumberFormField(props) {
    props.field.options = {regexp: '^[0-9\.]*$'};
    return <TextField {...props} onChange={(e)=>{
        const value = e.target.value;
        props.onChange({target: {
            // Number(e.target.value) - dont work with float numbers
            value: value[value.length - 1] === '.' ? value: Number(value)
        }})
    }}
    />
}
