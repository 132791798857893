import React, {useEffect, useState} from 'react';
import {merge} from 'lodash';

import {isMobile} from "react-device-detect";

import {getClientCity} from 'ultra/configs/general'
import {DEFAULT_CITY} from 'ultra/const/general';
import {hasFullAccess} from 'ultra/helpers/auth';

// import {Link as RouterLink} from "react-router-dom";

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs';
import TitleWidget from '../../../../../../Widgets/Title';

import {NodeTableInlineActions, getActions} from '../../../../../../Widgets/NodeInlineActions';
import CKEditorContent from '../../../../../../Widgets/CKEditorContent';
import {TableView} from '../../../../../../Widgets/Table';

import PreloaderTable from '../../../../../../../../Components/Preloaders/PreloaderTable';

import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';

import './index.scss';

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props; // form, 

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore();
    const {userState} = useGlobalCustomContext();
    const [fields, setFields] = useState()
    const [tableOptions, setTableOptions] = useState()
    const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    useEffect(() => {
        // TODO: FIX - SHOULD USE NOT ONLY ONE TYPE OF CHILDREN ???
        const childType = permits.children.read[0];
        
        const fields = configs.content.nodes[childType]?.fields;
        if (config.categories) fields.category = config.categories;

        if (config?.childrenOptions?.[childType]?.fields) {
            setFields(merge(fields, config?.childrenOptions?.[childType]?.fields));
        }
        else {
            setFields(fields);
        }

        if (config?.templateOptions) {
            setTableOptions(isMobile ? config?.templateOptions?.mobile : config?.templateOptions?.desktop);
        } else {
            const visibleFields = []
            if (configs.content.nodes[childType]?.fields) {
                Object.keys(configs.content.nodes[childType]?.fields).map(field => {
                    const fieldOptions = configs.content.nodes[childType]?.fields[field];

                    if (!fieldOptions) return;
                    if (fieldOptions?.options?.system) return;
                    if (!fieldOptions?.placeholder) return;

                    visibleFields.push(field)
                })
            }

            setTableOptions({visible: visibleFields});
        }
    }, [content])

    if (contentLoading) return <PreloaderTable/>

    return <div className="NodeTableTemplate">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {config?.templateOptions?.descriptionPosition === 'top' && <CKEditorContent content={content.description} />}

        <TableView
            content={children}
            fields={fields}
            showPagination={content.showPagination}
            noChildLinks={config?.templateOptions?.noChildLinks}
            topAlignVisibleData={config?.templateOptions?.topAlignVisibleData}
            tableOptions={tableOptions}

            actionsComponent={NodeTableInlineActions}
            actionsProps={{
                unfolded: isMobile,
                city: city,
            }}
            hasActions={(data, id) => 
                getActions({
                    permits: data.permits[id],
                    node: data.list[id],
                    hasFullAccess: hasFullAccess(userState.user)
                })
                ?.length > 0
            }
        />

        {config?.templateOptions?.descriptionPosition !== 'top' && <CKEditorContent content={content.description} />}
    </div>
}
