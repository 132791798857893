import React, {useEffect, useState} from 'react';

import {isArray, isString} from 'lodash';

import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import {getClientCity} from 'ultra/configs/general';

import {useConfigStore} from '../../../Stores/config';
import {useToasterStore} from '../../../Stores/toster';
import {useContentStore} from '../../../Stores/content';
import DefaultImage from '../../../Modules/Content/Widgets/DefaultImage';
import ContentImage from '../../../Modules/Content/Widgets/ContentImage';

import FilterChip from '../../../Modules/Content/Widgets/FilterChip';
import FilterValue from '../../../Modules/Content/Widgets/FilterValue';

import {hasCityFilterForSource, getFilter} from '../../../Helpers/filters';

import './index.scss'

function MyListSubheader(props) {
    const {children} = props;
    return <li {...props}>{children}</li>;
}
MyListSubheader.muiSkipListHighlight = true;

export default function Filter(props) {
    const {size, content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const contentStore = useContentStore();
    const {configs} = useConfigStore();
    const {showError} = useToasterStore();

    const {filters} = configs;
    const city = content._city || getClientCity(window);

    const [open, setOpen] = useState(false);

    const [value, setValue] = useState(field?.options?.multi ? [] : '')
    const [filterInputOpen, setFilterInputOpen] = useState(false)
    const [listFilter, setListFilter] = useState('')
    const [newValue, setNewValue] = useState(field?.options?.multi ? [] : '')
    const [prevValue, setPrevValue] = useState(field?.options?.multi ? [] : '')

    const [values, setValues] = useState([])

    const [label, setLabel] = useState(field.placeholder)
    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    function getFilterObject() {
        let filter
        if (!field.source) {
            filter = {...field.values};
        }
        else {
            filter = getFilter(filters, city, field.source) || {};
        }

        if (field.placeholder) {
            filter.label = field.placeholder;
        }

        return filter;
    }

    function getInitValues(filter) {
        const initValues = [];
        if (filter?.order) {
            filter.order.map(id => {
                const item = {
                    id,
                    label: filter.values[id],
                }
    
                if (filter?.images?.[id]) {
                    item.image = filter.images[id]
                }
    
                initValues.push(item)
            })
        }
        else if (filter?.values) {
            Object.keys(filter.values)
                .sort((a, b) => filter.values[a]?.localeCompare(filter.values[b]))
                .map(id => {
                    const item = {
                        id,
                        label: filter.values[id],
                    }
        
                    if (filter?.images?.[id]) {
                        item.image = filter.images[id]
                    }
        
                    initValues.push(item)
                })
        }

        return initValues;
    }

    useEffect(() => {
        let v

        if (field?.options?.multi) {
            if (content[field.id]) {
                let val
                try {
                    val = JSON.parse(content[field.id]);
                } catch(e) {
                    val = content[field.id];
                }

                if (isArray(val)) {
                    v = val
                }
                else if (isString(val)) {
                    v = [content[field.id]]
                }
            }
            else {
                v = []
            }
        }
        else {
            v = content[field.id] || ''
        }

        setValue(v);
        setNewValue(v);
        setPrevValue(v);
    }, [content[field.id]])

    useEffect(() => {
        let filter = getFilterObject();
        const initValues = getInitValues(filter);

        if (filter?.label) setLabel(filter.label)

        if (field?.options?.filterByChildren) {
            const presentFilters = []
            contentStore.content.filters[field.id].map(childId => {
                if (!presentFilters.includes(childId)) {
                    presentFilters.push(childId);
                }
            })

            setValues(initValues.filter(filter => presentFilters.includes(filter.id)))
        }
        else {
            setValues(initValues)
        }
    }, [field])

    const onChangeHandler = (e) => {
        e?.stopPropagation()
        setOpen(false)
        setListFilter('')
        setFilterInputOpen(false)

        onChange(e)
    }

    const onChangeMultiHandler = (e) => {
        const v = e.target.value

        let updatedValue = [...newValue];
        if (newValue.includes(v)) {
            updatedValue = updatedValue.filter(i => i !== v)
        }
        else {
            if (field?.options?.max && newValue.length === field.options.max) {
                showError({snackbarMessage: `Для поля "${field.placeholder}" ви можете обрати не більше ${field.options.max} значень`})
                return;
            }

            updatedValue.push(v)
        }

        setNewValue(updatedValue);
    }

    const onCloseHandle = (e) => {
        e?.stopPropagation()
        setOpen(false)
        setListFilter('')
        setFilterInputOpen(false)

        setTimeout(() => {
            setNewValue(prevValue);
        }, 200)
    }

    const onApplyHandle = (e) => {
        e?.stopPropagation()
        setOpen(false)
        setListFilter('')
        setFilterInputOpen(false)

        setTimeout(() => {
            onChange({target: {value: newValue}})
        }, 200)
    }

    const onClearHandle = (e) => {
        e?.stopPropagation()
        setNewValue([]);
    }

    const onFilterChange = (e) => {
        setListFilter(e.target.value)
    }

    if (field?.options?.display === 'chips') {
        return (<div className='FilterChips'>
            {values.map(item =>
                <Chip
                    key={`filter_chip_${item.id}`}
                    variant={value === item.id ? 'outlined' : ''}
                    label={item.label} onClick={() => { onChange({target: {value: item.id}}) }}
                />
            )}
        </div>)
    }

    return (<>
        {values?.length > 0 && <FormControl fullWidth required={field.required} error={showErrorText} size={size} className={field?.options?.multi ? 'FilterField FilterFieldMulti' : 'FilterField'}>
            <InputLabel>{label}</InputLabel>
            <Select
                open={open}

                MenuProps={
                    values?.length > 10 ?
                    // {className: filterInputOpen ? "FilterFieldVerticalMenuPopover FilterFieldVerticalMenuPopoverFilterOpen" : "FilterFieldVerticalMenuPopover"}
                    {className: "FilterFieldVerticalMenuPopover"}
                    : {}
                }

                disabled={isDisabled}
                label={label}
                required={field.required}
                value={value}
                multiple={field?.options?.multi}

                onClick={() => {
                    if (!isDisabled) {
                        setOpen(true);
                    }
                }}

                onClose={onCloseHandle}

                renderValue={(selected) => {
                    if (field?.options?.multi) {
                        return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((item) => field.source ?
                                <FilterChip key={'select_chip_' + item} value={item} config={field} />
                                : <Chip label={field.values.values[item]} variant="outlined" />
                            )}
                        </Box>
                    } else {
                        return field.source ?
                            <FilterValue value={selected} config={field} />
                            : field.values.values[selected]
                    }
                }}
            >
                <MyListSubheader className='FilterFieldTitle'>
                    {field.placeholder}

                    {values?.length > 10 && <>
                        {filterInputOpen && <CloseIcon
                            className='FilterFieldTitleSearch'
                            onClick={() => {
                                setListFilter('')
                                setFilterInputOpen(!filterInputOpen)
                            }}
                        />}

                        {!filterInputOpen && <SearchIcon
                            className='FilterFieldTitleSearch'
                            onClick={() => {
                                setFilterInputOpen(!filterInputOpen)
                            }}
                        />}

                        {filterInputOpen && <div className="FilterFieldTitleInput">
                            <div className='FilterFieldTitleInputWrap'>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    onChange={onFilterChange}
                                />
                            </div>
                        </div>
                        }
                    </>}
                </MyListSubheader>

                <MyListSubheader className={`FilterFieldValues ${filterInputOpen && 'FilterFieldValuesOpen'} ${values?.length > 10 && 'FilterFieldValuesHasFilter'}`}>
                    <ul className='FilterFieldValuesList'>
                        {(!field.required && !field?.options?.hasNoEmptyValue) && !field?.options?.multi && <MenuItem
                            value=""
                            key={`${field.id}_empty`}
                            className={newValue.length === 0 ? 'selected' : ''}
                            onClick={(e) => {
                                e.target = {value: ""};
                                onChangeHandler(e)
                            }}
                        >
                            <div className='FilterControlItem'>Всі</div>
                        </MenuItem>}

                        {values
                            .filter(item => {
                                if (listFilter) {
                                    return item.label.toLowerCase().includes(listFilter.toLowerCase())
                                }

                                return true
                            })
                            .map(item =>
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                    onClick={(e) => {
                                        e.target = {value: item.id};
                                        field?.options?.multi ? onChangeMultiHandler(e) : onChangeHandler(e)
                                    }}
                                    className={(field?.options?.multi ? newValue.indexOf(item.id) > -1 : item.id === newValue) ? 'selected' : ''}
                                >
                                        <div className='FilterControlItem'>
                                            {field?.options?.multi && <Checkbox checked={newValue.indexOf(item.id) > -1} />}

                                            {item.image && (hasCityFilterForSource(filters, city, field.source) ?
                                                <ContentImage image={item.image} className='icon'/>
                                                : <DefaultImage image={item.image} className='icon'/>
                                            )}
                                            <span className='text'>{item.label}</span>
                                        </div>
                                </MenuItem>
                        )}
                    </ul>
                </MyListSubheader>

                {/* PSEUDO ELEMENTS: IT'S NEEDED
                BECAUSE MUI SELECT SHOULD HES MenuItem values in root */}
                <MenuItem
                    style={{display: 'none'}}
                    key={`copy_${field.id}_empty`}
                    value=""
                />
                {values.map(item =>
                            <MenuItem
                                style={{display: 'none'}}
                                key={`copy_${item.id}`}
                                value={item.id}
                            />
                        )}

                {field?.options?.multi && <MyListSubheader className='FilterFieldSaveButton'>
                    {newValue.length > 0 && <Button variant='outlined' fullWidth onClick={onClearHandle}>Очистити</Button>}
                    {newValue.length === 0 && <Button variant='outlined' fullWidth onClick={onCloseHandle}>Закрити</Button>}
                    <Button variant="contained" fullWidth onClick={onApplyHandle}>Обрати</Button>
                </MyListSubheader>}
            </Select>
            {showErrorText && <FormHelperText>{showErrorText}</FormHelperText>}
        </FormControl>}
    </>)
}
