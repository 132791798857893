import React, {useEffect, useState} from 'react';

import { NavLink as RouterLink } from "react-router-dom";
import {isMobile} from "react-device-detect";
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {getClientUrl} from 'ultra/configs/general'
import { hasFullAccess } from "ultra/helpers/auth";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getFormOrderHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/Preloaders/PreloaderTable';

import UserHistoryLink from '../../../../Content/Widgets/UserHistoryLink'

import {TableView} from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initData = useLoaderData();

    let {partner, form} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Замовник',
        },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        },
        _action: {
            type: 'order-status-action',
            placeholder: "Статус",
            extraData: initData
        },
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_action'],
            hidden: ['_init_by', '_timestamp'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_init_by'],
            hidden: [],
        })

        setPermits({})

        setPageTitle('Історія заявок')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initData);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateHistory(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    const content = {
        title: `${history?.title}`
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\partnership'] = {title: "Партнерська панель"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getFormOrderHistory(partner, form, url.search)
            .promise
            .then(updateHistory)
    }

    return <div  className='Content'>
            <div className='HistoryFormOrderAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs}/>}

                {(!fields) && <PreloaderTable/>}

                {fields && history && <TableView
                    content={history}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                />}

                <Grid container spacing={2} className='HistoryFormAdminPageDetails'>
                    {history?.link && <Grid item xs={12} className='labelWrap'>
                        <span className='label'>Посилання:</span>
                        <span className='labelValue'>
                            <span className="shopLink">
                                <RouterLink to={getClientUrl(history?.partner.city, restorePath(normalizeRoute(history?.link._uri)))}>{history?.link.title}</RouterLink>
                            </span>
                        </span>
                    </Grid>}
                    {history?.access && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Доступ:</span>
                        <span className='labelValue'>
                            {history.access.map(i =>
                                <Chip label={i.displayName ? (hasFullAccess(userState.user) ? <UserHistoryLink id={i.id} name={i.displayName} /> : i.displayName) : i} variant="outlined" />
                            )}
                        </span>
                    </Grid>}
                    {history?.manager && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Менеджер:</span>
                        <span className='labelValue'>
                            <Chip label={<>
                                {history.manager.conversation && <span>
                                    {history.manager?.conversation?.private && 'Приватний чат '}
                                    {history.manager?.channel?.private && 'Приватний канал'}

                                    {!history.manager?.conversation?.private && 'Публічний чат '}
                                    {!history.manager?.channel?.private && 'Публічний канал'}
                                </span>}

                                {history?.manager?.channel && <span>Канал сповіщень у {history.manager.channel}</span>}
                            </>} variant="outlined" />
                            {history.manager.contact && <Chip label={hasFullAccess(userState.user) ? <UserHistoryLink id={history.manager.contact.id} name={history.manager.contact.displayName} /> : history.manager.contact.displayName} variant="outlined" />}
                        </span>
                    </Grid>}
                    {history?.owners && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Власник:</span>
                        <span className='labelValue'>
                            {history.owners.map(i => <Chip label={i.displayName || i} variant="outlined" />)}
                        </span>
                    </Grid>}
                </Grid>
            </div>
        </div>
}
