import {useEffect, useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {useNavigate} from "react-router-dom";

import {ROLES} from "ultra/const/user";
import {isEmptyObj} from 'ultra/helpers/utils';
import {isTgID} from 'ultra/helpers/telegram';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import {getCachedUser} from '../../../Profile/Helpers/user';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss'

export default function UserHistoryLinkByID(props) {
    const {id, onlyText} = props;

    const navigate = useNavigate();

    const {userState, userDispatch} = useGlobalCustomContext();

    const [account, setAccount] = useState();

    useEffect(() => {
        if (id === ROLES.ANONYMOUS) {
            setAccount({
                displayName: 'Anonymous'
            });
        }
        else if (isTgID(id)) {
            setAccount({displayName: "Telegram користувач"})
        }
        else if (id) {
            const cachedUserAccount = userState?.ids[id];

            if (cachedUserAccount) {
                setAccount({...cachedUserAccount})
            }
            else {
                getCachedUser(id)
                    .then((acc) => {
                        const result = {};
                        result[id] = acc;
                        userDispatch({type: USER_ACTIONS.SET_USER_BY_ID, data: {[id]: acc}});

                        setAccount({...acc});
                    })
            }
        }
        else {
            setAccount({});
        }
    }, [id, userState])

    const onClickHandler = () => {
        if (id !== ROLES.ANONYMOUS) {
            return navigate(`/profile/history/user/${id}`)
        }
    }

    if (!account) {
        return <Skeleton variant="rectangular" width={'122px'} height={12} style={{display: 'inline-block'}} />
    }

    if (isEmptyObj(account)) {
        return <>дані відсутні</>
    }

    if (onlyText) return account.displayName

    return <span className={id === ROLES.ANONYMOUS ? '' : 'pseudoLink'} onClick={onClickHandler}>{account.displayName}</span>
}
