import React, {useEffect, useState} from 'react';

import {useLoaderData, useNavigation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {hasFullAccess} from 'ultra/helpers/auth';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import {getMessages} from '../../Helpers/messages'; // removeAllMessages
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {useConfigStore} from '../../../../Stores/config';
import {useConversationsStore} from '../../../../Stores/conversations';

import PreloaderTable from '../../../../Components/Preloaders/PreloaderTable';

import {TableView} from '../../../Content/Widgets/Table';
import TitleWidget from '../../../Content/Widgets/Title';
import FiltersLine from '../../../Content/Widgets/FiltersLine';

import {NODE_ACTIONS} from '../../../Content/Reducers/node';

import {setPageTitle} from '../../../../Helpers/router';

import './index.scss';

const TITLE = 'Сповіщення';

export default () => {
    const rawNodes = useLoaderData();

    const navigation = useNavigation();
    const {configs} = useConfigStore();
    
    const {userState, nodeState, nodeDispatch} = useGlobalCustomContext();

    const {conversations, setConversations} = useConversationsStore();

    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        logo: {
            type: 'conversation-logo',
            placeholder: 'Лого',
            extraData: rawNodes.users,
        },
        conversation: {
            type: 'conversation',
            placeholder: 'Розмова',
            extraData: rawNodes.users,
        },
        conversationOpen: {
            type: 'conversation-open',
            placeholder: '',
        },
    }

    const [fields] = useState(baseFields);

    const MobileConfig = {
        visible: ['logo', 'conversation', 'conversationOpen'],
        hidden: [],
        noHeaders: true,
        infinityScroll: true,
    }
    const DesktopConfig = {
        visible: ['logo', 'conversation', 'conversationOpen'],
        hidden: [],
        noHeaders: true,
        infinityScroll: true,
    }

    useEffect(() => {
        setTableOptions(isMobile ? MobileConfig : DesktopConfig)
        setPermits({})

        return () => {
            setConversations(null);
        }
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage();
        }
    }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            setConversations(rawNodes);
        }
        setPageTitle(TITLE)
    }, [navigation])

    useEffect(() => {
        if (hasFullAccess(userState.state)) {
        }

        setTableOptions(isMobile ? MobileConfig : DesktopConfig)

        const config = {
            templateOptions: {}
        }

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    const updatePage = () => {
        const url = new URL(window.location.href);

        setConversations({})
        getMessages(url.search)
            .promise
            .then(setConversations)
    }

    const selectClasses = (data) => {
        let s;

        if (data?._not_readed > 0) {
            s = 'tr_unseen';
        }

        return `${s ? s : ''}`
    }

    const content = {
        title: TITLE,
    }

    const breadcrumbs = {}

    return <div className='Content'>
            <div className='Conversations'>
                <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />

                {isMobile && config?.showFilters?.filterByInline && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {userState?.user?._not_checked && <div className='informer globalInformer error'>
                    <ErrorOutlineIcon />
                    Ваш аккаунт проходить перевірку після реєстрації
                </div>}
            
                {userState?.user?.emailNotVerified && <div className='informer globalInformer error'>
                    <AlternateEmailIcon />
                    Підтвердіть вашу електрону адресу, перейшовши за посиланням, яке ми відправили вам на email
                </div>}

                {/* {conversations?.order?.length === 0 && <div className='emptyConversations'></div>} */}
                {/* {hasFullAccess(userState?.user) && <NotificationLineActions />} */}

                {fields && conversations && <TableView
                    content={conversations}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    selectClasses={selectClasses}
                    noDataText={(!userState?.user?._not_checked && !userState?.user?.emailNotVerified) ? "На даний момент у вас відсутні сповіщення" : ""}
                />}
            </div>
        </div>
}

// function NotificationLineActions() {
//     const [actions, setActions] = useState([])
//     const {showPromt, closeToaster} = useToasterStore();
//     const {hideBackdropPreloader} = useBackdropPreloaderStore()

//     const {nodeDispatch} = useGlobalCustomContext();

//     useEffect(() => {
//         const actions = []
//         // actions.push({
//         //     id: 'clean',
//         //     icon: <CleaningServicesOutlinedIcon className='Icon errorIcon' />,
//         //     onClick: () => {
//         //         showPromt({
//         //             snackbarMessage: 'Бажаєте видалити всі нотифікації?',
//         //             snackbarDuration: 10000,
//         //             onApproveHandler: () => {
//         //                 closeToaster();

//         //                 removeAllMessages()
//         //                     .then(() => {
//         //                         nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
//         //                         hideBackdropPreloader();
//         //                     })
//         //                     .catch(() => {
//         //                         nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
//         //                         hideBackdropPreloader();
//         //                     })
//         //             },
//         //         })
//         //     }
//         // })

//         setActions(actions)
//     }, [])

//     return <InlineActions actions={actions} className="CurrentPageInlineActions" unfolded id={?} />
// }
