import React, { useState, useRef, useEffect } from "react";

import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Collapse from '@mui/material/Collapse';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getClientCity} from 'ultra/configs/general';
import {getFormatedDate} from 'ultra/helpers/date';
import {attachUserData} from 'ultra/helpers/auth';

import Form from './Components/Form';
import Proposals from './Components/Proposals';
import FAQ from './Components/Info/FAQ';
import SubmitForm from './Components/SubmitForm';
import ProposalDetails from './Components/ProposalsDetails';

import {getInsuranceConfig} from '../../Helpers/module';
import {useGlobalCustomContext} from '../../Helpers/context';

import {useNavigationStore} from '../../Stores/navigation';
import {useConfigStore} from '../../Stores/config';

import PreloaderText from '../../Components/Preloaders/PreloaderText';
import PreloaderForm from '../../Components/Preloaders/PreloaderForm';
import FormContainer from '../../Components/Form/FormContainer'

import {getPrices} from './Helpers/data';

import './index.scss'

export default function Insurance() {
  const formRef = useRef(null)
  const faqRef = useRef(null)
  const supportRef = useRef(null)

  const {contentLoading} = useNavigationStore()
  const {userState} = useGlobalCustomContext();

  // const today = new Date()
  // const minDays = 3

  const defaultState = {
    clients: '1',
    children: '0',
    countries: ['Індонезія'],
    sport: false,
    from: null, // today.setDate(today.getDate() + 1)
    to: null, // today.setDate(today.getDate() + minDays - 1),
    days: null, //minDays,
    coverage: '30000$',
    showFAQ: false,
    showHIT: false,
    showSupport: false,
  }

  const attachDefaultValues = (config) => {
    const defaultValues = {}

    Object.keys(config.fields).map(field => {
      if (config.fields[field].default) {
        const defVal = attachUserData(config.fields[field].default, userState?.user)

        if (defVal) {
          defaultValues[field] = defVal
        }
      }
    })

    config.defaultValues = defaultValues

    return config;
  }

  const [state, setState] = useState({...defaultState, insuranceConfig: null})
  const [isPackagesLoading, setIsPackagesLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [prices, setPrices] = useState(null)
  const [selected, setSelected] = useState(null)
  const [isFormWasFilled, setIsFormWasFilled] = useState(null)

  const {clients, children, countries, sport, from, to, days, coverage, showFAQ, showHIT, showSupport, insuranceConfig} = state;

  const {configs} = useConfigStore();

  const [timeZone, setTimeZone] = useState()
    useEffect(() => {
      const city = getClientCity(window) || DEFAULT_CITY;
      setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

  useEffect(() => {
    // TODO: show skeleton
    getInsuranceConfig()
      .then(config => {
        const finalConfig = config;
        const city = getClientCity(window) || DEFAULT_CITY;
        
        if (!finalConfig.support.form.endpoint.includes(city)) {
          finalConfig.support.form.endpoint += '/' + city;
        }

        setState({
          ...state,
          insuranceConfig: finalConfig
        })
        // TODO: hide skeleton
      })
  }, [])

  useEffect(() => {
    setIsFormWasFilled(Boolean(days) && Boolean(countries.length > 0) && (Boolean(Number(clients) || Number(children))));
    // getSelectedPrices();
  }, [days, countries, clients, sport])

  const getSelectedPrices = () => {
    if (!isFormWasFilled) return;

    setIsPackagesLoading(true);
    setStep(2);

    getPrices(sport, days, clients, children, countries)
      .promise
      .then(prices => {
        setIsPackagesLoading(false);
        setPrices(prices);
      })
  }

  const getDays = (from, to) => {
    if (!from || !to) return null

    const fromDate = new Date(from)
    const toDate = new Date(to)
    fromDate.setHours(0)
    toDate.setHours(0)
    fromDate.setMinutes(0)
    toDate.setMinutes(0)
    fromDate.setSeconds(0)
    toDate.setSeconds(0)

    const days = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24)) + 1;

    return days >= 3 ? days : 0
  }

  const handleCountriesChange = (e) => {
    setState({...state, countries: e.target.value});
    setSelected(null)
  }

  const handleClientsAmount = (e) => {
    setState({...state, clients: e.target.value});
    setSelected(null)
  }

  const handleCoverageChange = (e) => {
    setState({...state, coverage: e.target.value});
    setSelected(null)
  }

  const handleChildrensAmount = (e) => {
    setState({...state, children: e.target.value});
    setSelected(null)
  }

  const handleStartDate = (e) => {
    if (!e) return
    console.log(e)
    const days = getDays(e.toDate(), to);

    setState({
      ...state,
      from: new Date(e.toDate()).valueOf(),
      days
    });

    setSelected(null);
  }

  const handleEndDate = (e) => {
    if (!e) return

    console.log(e)

    const days = getDays(from, e.toDate());

    setState({
      ...state,
      to: new Date(e.toDate()).valueOf(),
      days
    });

    setSelected(null);
  }

  const handleSportOption = (e) => {
    setState({...state, sport: e.target.checked});
    setSelected(null);
  }

  const handleChooseButton = (selected) => {
    setSelected(selected);
    setStep(3);
    // setTimeout(() => {
    //   formRef.current?.scrollIntoView({ behavior: 'smooth' });
    // }, 500)
  }

  const onSupportTrigger = () => {
    setState(prevState => {return {...state, showSupport: !prevState.showSupport} })
  }

  const onFAQTrigger = () => {
    setState(prevState => {return {...state, showFAQ: !prevState.showFAQ} })
  }

  const onHITTrigger = () => {
    setState(prevState => {return {...state, showHIT: !prevState.showHIT} })
  }

  const afterFormSubmit = () => new Promise(async resolve => {
    setIsPackagesLoading(false);
    setPrices(null);
    setSelected(null);
    setState({...state, ...defaultState});
    setStep(1);
    resolve();
  })

  const afterSupportSubmit = () => new Promise(async resolve => {
    setState({...state, showSupport: false});
    resolve();
  })

  const onOrderConsultHandle = () => {
    onSupportTrigger()
    setTimeout(() => {
      supportRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500)
  }

  const onStepHandler = (s) => {
    if (s === step) return;
    setStep(s);
  }

  const onGetProposals = () => {
    getSelectedPrices();
  }

  if (contentLoading || !insuranceConfig) {
    return <>
      <PreloaderForm />
      <PreloaderText />
    </>
  }

  return (
    <div className="Insurance">
      <HelpOutlineIcon
        className="faqIcon"
        onClick={() => {
          onFAQTrigger();
          setTimeout(() => {
            if (!showFAQ) {
              faqRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        }}
      />
      <div className="InsuranceForm">
        <div className={step === 1 ? 'labelWrap step active' : 'labelWrap step'}>
          <span className="label">Крок 1:</span>
          <span className='labelValue'>
            <span>Загальна інформація</span>
            {(isPackagesLoading || prices) && step !== 1 && <span className='pseudoLink' onClick={() => onStepHandler(1)}><EditOutlinedIcon /> <span className='text'>змінити</span></span>}
          </span>
        </div>

        <Collapse in={step === 1}>
          <div className='Form'>
            <Form
              days={days}

              clients={clients}
              children={children}

              countries={countries}

              from={from}
              to={to}

              sport={sport}

              coverage={coverage}

              insuranceConfig={insuranceConfig}

              onCountriesChanged={handleCountriesChange}
              onClientsAmountChange={handleClientsAmount}
              onCoverageChange={handleCoverageChange}
              onChildrensAmountChange={handleChildrensAmount}

              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}

              onSportsOptionChange={handleSportOption}
            />

            <Button disabled={!isFormWasFilled} fullWidth variant="contained" color='info' onClick={onGetProposals}>Отримати пропозиції</Button>
          </div>
        </Collapse>
      </div>

      {(isPackagesLoading || prices) && <div className="InsuranceOptions">
        <div className={step === 2 ? 'labelWrap step active' : 'labelWrap step'}>
          <span className="label">Крок 2:</span>
          <span className='labelValue'>
            <span>
              Оберіть пропозицію
            </span>
            {selected && step !== 2 && <span className='pseudoLink' onClick={() => onStepHandler(2)}><EditOutlinedIcon /> <span className='text'>змінити</span></span>}
          </span>
        </div>

        {<Collapse in={step === 2}>
          <div className='SummaryWrap'>
            <Proposals
              isPackagesLoading={isPackagesLoading}
              prices={prices}
              activeCoverage={coverage}
              insuranceConfig={insuranceConfig}
              selected={selected}
              onChooseButton={handleChooseButton}
            />

            <p className='finalPrice'>Остаточна ціна та консультація з обраного пакету буде надана консультантом після відправки вашої заявки. Ціна залежить від віку застрахованих осіб та обраного типу активного відпочинку або спорту</p>
          </div>
        </Collapse>}
      </div>}

      {selected && <div className="SubmitForm" ref={formRef}>
        <div className={step === 3 ? 'labelWrap step active' : 'labelWrap step'} onClick={() => onStepHandler(3)}>
          <span className="label">Крок 3:</span>
          <span className='labelValue'>
            <span>Відправити заявку консультанту</span>
            {selected && <span className='pseudoLink' onClick={() => onStepHandler(3)}><EditOutlinedIcon /> <span className='text'>змінити</span></span>}
          </span>
        </div>

        {<Collapse in={step === 3}>
          <div className='SubmitFormWrap'>
            <SubmitForm
              afterSubmit={afterFormSubmit}
              selected={selected}
              insuranceConfig={insuranceConfig}
              data = {{
                days,
                from: getFormatedDate(from, timeZone),
                to: getFormatedDate(to, timeZone),
                clients,
                children,
                countries,
                sport
              }}
            />

            <div className='orderDetails'>
              <p className='title'>Ви відправляєте запит на консультацію та оформлення полісу з наступними параметрами:</p>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <span className='labelWrap'>
                    <span className="label">Період:</span>
                    <span className='labelValue'>
                      {getFormatedDate(from, timeZone)} - {getFormatedDate(to, timeZone)}
                    </span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className='labelWrap'>
                    <span className="label">Кількість днів:</span>
                    <span className='labelValue'>
                      {days}
                    </span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className='labelWrap'>
                    <span className="label">Територія дії:</span>
                    <span className='labelValue'>
                      {countries.join(', ')}
                    </span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <span className='labelWrap'>
                    <span className="label">Орієнтовна ціна:</span>
                    <span className='labelValue'>
                      {selected.price.finalPrice.toFixed()}₴
                    </span>
                  </span>
                </Grid>
                {sport && <Grid item xs={12} md={6}>
                  <span className='labelWrap'>
                    <span className='labelValue'>
                      Планується активний відпочинок або заняття спортом
                    </span>
                  </span>
                </Grid>}
              </Grid>

              <div className='packageDetails'>
                <p className='labelWrap'>
                  <span className="label">Пакет:</span>
                  <span className="labelValue">
                    <span className="company">{selected.company}</span>,
                    &nbsp;
                    {selected.pack.label},
                    &nbsp;
                    {coverage}
                  </span>
                </p>

                <ProposalDetails pack={selected.pack} xs={6} md={6} company={selected.company}/>
              </div>

            </div>
          </div>
        </Collapse>}
      </div>}

      <div className="divider"></div>

      <div className="FAQWrapper FAQWrapperText">
        <div className={showHIT ? "subtitle on" : "subtitle"} onClick={onHITTrigger}>
          <span className="faqTitle">
            <HelpOutlineIcon /> Як це працює?
          </span>
          <KeyboardArrowDownIcon className="arrow down" />
          <KeyboardArrowUpIcon className="arrow up" />
        </div>
        <Collapse in={showHIT}>
          <div className="wrap">
            <p>Сервіс надає можливість обрати вигідну пропозицію та зв'язатись зі страховим консультантом, що надасть більше розгорнуту інформацію та оформить страховий поліс для вас</p>
            <p>Після оформлення заявки, за вказаними контактами, з вами зв'яжеться страховий агент</p>
            <p>Агенти знаходяться в Україні, тому враховуйте часову різницю при очікувані відповіді</p>
            <p>Якщо у вас виникли додаткові питання, ви можете <span className="orderConsult" onClick={onOrderConsultHandle}>замовити консультацію</span></p>
            <p>Через можливу зміну офіційного курсу від НБУ, під час очікування обробки заявки агентом, остаточна ціна може несуттєво відрізнятись від розрахунку калькулятора</p>
          </div>
          </Collapse>
      </div>

      <div className="FAQWrapper" ref={faqRef}>
        <div className={showFAQ ? "subtitle on" : "subtitle"} onClick={onFAQTrigger}>
          <span className="faqTitle">
            <QuestionAnswerOutlinedIcon /> Популярні питання
          </span>
          <KeyboardArrowDownIcon className="arrow down" />
          <KeyboardArrowUpIcon className="arrow up" />
        </div>
        <Collapse in={showFAQ}>
          <FAQ data={insuranceConfig.faq}/>
        </Collapse>
      </div>

      <div className="SupportWrapper" ref={supportRef}>
      {insuranceConfig.support.enabled && <div className="support">
        <div className={showSupport ? "subtitle on" : "subtitle"} onClick={onSupportTrigger}>
          <SupportAgentIcon /> Замовити консультацію
          <KeyboardArrowDownIcon className="arrow down" />
          <KeyboardArrowUpIcon className="arrow up" />
        </div>

        <Collapse in={showSupport}>
          <div className="SupportWrapperForm">
            <FormContainer
                config={attachDefaultValues(insuranceConfig.support)}
                afterSubmit={afterSupportSubmit}
            />
          </div>
        </Collapse>
      </div>}
      </div>

    </div>
  );
}
