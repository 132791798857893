import Chip from '@mui/material/Chip';

import './index.scss'

export default function ProductAddons(props) {
    const {item} = props;

    if (!item?.addons?.list) return <></>

    return <div className="ProductAddons">
        {Object.keys(item.addons.list).length > 0 && <div className='label'>{item?.addons?.placeholder || 'Доступні опції'}:</div>}

        {item.addons.list && Object.keys(item.addons.list).map(addon => <div className='ProductAddonsItem labelWrap labelWrapRow' key={'product_addon_' + addon}>
            <span className='label'>{item.addons.list[addon].title}:</span>
            <span className='labelValue'>
                {Object.keys(item.addons.list[addon].list).map(i => <Chip
                    key={'product_option_' + addon + '_' + i}
                    variant='outlined'
                    className={!item.addons.list[addon].list[i].available ? 'notAvailableOption' : ''}
                    label={item.addons.list[addon].list[i].title}
                />)}
            </span>
        </div>)}
    </div>
}
