import {isMobile} from "react-device-detect";
import React, {useEffect, useState} from "react";

import {Link as RouterLink} from "react-router-dom";

import {isUndefined} from 'lodash';

import Grid from '@mui/material/Grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {hasFullAccess} from 'ultra/helpers/auth';

import {useConfigStore} from '../../../../Stores/config';
import {useSearchStore} from '../../../../Stores/search';

import NodeInlineActions from '../../Widgets/NodeInlineActions';
import NotPublishedIcon from '../../Widgets/NotPublishedIcon';
import Links from '../../Widgets/Links'
import FilterChip from '../../Widgets/FilterChip'
import FiltersLine from '../../Widgets/FiltersLine';

import PreloaderLinksTileWithCategories from '../../../../Components/Preloaders/PreloaderLinksTileWithCategories';

import {getFilter} from '../../../../Helpers/filters';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import ContentImage from '../ContentImage';
import './index.scss'


function CardContent(props) {
    const {className, src, node, nodeConfig, title, label} = props;

    return <div className={className}>
        <div className="photoWrap">
            <ContentImage src={src || 'thumbnail'} image={node[src || 'thumbnail']} className={src || 'thumbnail'} />
            {label && <span className="photoLabel">{label(node)}</span>}
            {node.status && !label && <div className="photoLabel">
                <FilterChip value={node.status} config={nodeConfig?.fields?.status} />
            </div>}
        </div>
        <div className={node._published ? "subtitle" : "subtitle notPublishedWrap"}>
            {title && <>
                {node._published ? '' : <NotPublishedIcon />}
                {title(node)}
            </>}

            {!title && <>
                {node._published ? '' : <NotPublishedIcon />}
                {node.title}
                {node.description && <NavigateNextIcon className="arrow"/>}
            </>}
        </div>
    </div>
}

function Card(props) {
    const {node, permits, configs, isExternalLinksSection, title, label, src} = props;

    const nodeConfig = configs.content.nodes[node._type]
    const {userState} = useGlobalCustomContext();
    const [city] = useState(getClientCity(window) || DEFAULT_CITY);
    const [className, setClassName] = useState()

    useEffect(() => {
        setClassName(((node.description ? 'hasDescription' : '') + ' ' + (node.status ? node.status : '')).trim())
    }, [])

    let coverClassName = 'coverWrap'

    if (isExternalLinksSection) {
        if (node?.link?.link) {
            return <div className={coverClassName}>
                <a href={node.link.link} target='_blank'>
                    <CardContent className={className} src={src} node={node} nodeConfig={nodeConfig} title={title} permits={permits} label={label} />
                </a>
                <NodeInlineActions permits={permits} node={node} direction="up" city={city} />
            </div>
        }
        else {
            coverClassName += ' noLink'
            return <div className={coverClassName}>
                <CardContent className={className} src={src} node={node} nodeConfig={nodeConfig} title={title} permits={permits} label={label} />
                <NodeInlineActions permits={permits} node={node} direction="up" city={city} />
            </div>
        }
    }

    if (!node.description) {
        if (!node._uri) coverClassName += ' noLink'
        return <div className={coverClassName}>
            <CardContent className={className} src={src} node={node} nodeConfig={nodeConfig} title={title} permits={permits} label={label}/>
            <NodeInlineActions permits={permits} node={node} direction="up" city={city} />
        </div>
    }

    if (!isExternalLinksSection) {
        if (node._uri) {
            return <div className={coverClassName}>
                <RouterLink to={node._uri.slice(1)}>
                    <CardContent className={className} src={src} node={node} nodeConfig={nodeConfig} title={title} permits={permits} label={label} />
                </RouterLink>
                <NodeInlineActions  permits={permits} node={node} direction="up" city={city} />
            </div>
        } else {
            coverClassName += ' noLink'
            return <div className={coverClassName}>
                <CardContent className={className} src={src} node={node} nodeConfig={nodeConfig} title={title} permits={permits} label={label} />
                <NodeInlineActions permits={permits} node={node} direction="up" city={city} />
            </div>
        }
    }
}

const Category = (props) => {
    const {value, categoriesConfig, configs, permits} = props;

    function getCategoryConfig() {
        if (categoriesConfig) return categoriesConfig;

        // TODO: fine better way to get data about filter option
        // if there will be 2 node types, they should has same category option
        // if (types.length > 1) {
        //     showError({snackbarMessage: 'Помилка конфігурації, в каталогі присутні різні типи вузлів'})
        // }
        // TODO: check is all fields has category
        const nodesWithCategories = []
        permits.children.read.map((i) => {
            if (configs.content.nodes[i].fields?.category) {
                nodesWithCategories.push(i)
            }
        })

        return configs.content.nodes[nodesWithCategories[0]].fields?.category
    }

    return <div className="sectioCategory" id={`section_${value}`}>
        <a className="sectionAnchor" href={`#section_${value}`}>#</a>
        <FilterChip value={value} config={getCategoryConfig()} />
    </div>
}

export default function LinksTileWithCategories(props) {
    const {links, title, label, categoriesConfig, spacing, xs, md, src, permits, absentText, showFilters, showSkeleton, searchBy, isExternalLinksSection} = props

    const {configs} = useConfigStore();
    const {search} = useSearchStore();

    const [noCategorisedLinks, setNoCategorisedLinks] = useState()
    const [categories, setCategories] = useState()
    const [byCategories, setByCategories] = useState()
    const [filterBy, setFilterBy] = useState();

    useEffect(() => {
        const finalCategoriesOrder = []
        const sortedItems = {}

        const noCategoriesItems = {}
        const noCategoriesItemsPermits = {}

        let list = links?.list;

        let categoriesOrder
        if (categoriesConfig) {
            const city = getClientCity(window);
            const config = getFilter(configs.filters, city, categoriesConfig.source);
            categoriesOrder = config.order || Object.keys(config.values).sort()
        }
        else {
            categoriesOrder = []
            links.order.map(item => {  
                const category = list[item]?.category;
                if (!category) return;

                if (!categoriesOrder.includes(category)) {
                    categoriesOrder.push(category)
                }
            })
            // categoriesOrder.sort()
        }

        categoriesOrder?.map(category => {
            links.order.map(item => {
                if (!list[item]) return;

                if (list[item].category === category) {
                    sortedItems[category] = sortedItems[category] || []

                    sortedItems[category].push(list[item])
                }
            })

            if (sortedItems[category]?.length > 0) {
                finalCategoriesOrder.push(category)
            }
        });

        links.order.map(item => {
            if (!list[item]) return;

            if (!categoriesOrder.includes(list[item].category)) {
                noCategoriesItems[item] = list[item]
                noCategoriesItemsPermits[item] = links.permits[item]
            }
        })

        Object.keys(noCategoriesItems)

        setNoCategorisedLinks({
            list: noCategoriesItems,
            order: Object.keys(noCategoriesItems).sort(),
            permits: noCategoriesItemsPermits
        });
        setCategories(finalCategoriesOrder);

        setByCategories(sortedItems);

        setFilterBy(showFilters?.filterByInline);
    }, [links, search])

    if (showSkeleton || !categories) return <PreloaderLinksTileWithCategories spacing={spacing} xs={xs} md={md} src={src} />

    return <>
        <div className="LinksTile LinksTileWithCategories">

            {isMobile && filterBy?.length && <div className="MobileFiltersLine">
                <FiltersLine
                    size="small"
                    filters={filterBy}
                />
            </div>}

            {categories.map(category => <div className='CategoryWrap' key={category}>
                <div className="title">
                    <Category value={category} categoriesConfig={categoriesConfig} configs={configs} permits={permits}/>
                </div>

                <div className="items">
                <Grid container spacing={isUndefined(spacing) ? 2 : 0}>
                    {byCategories[category].map(item =>
                        <Grid item xs={xs} md={md} key={item._uri}>
                            <Card node={item} permits={links.permits[item._uri]} configs={configs} isExternalLinksSection={isExternalLinksSection} src={src} title={title} label={label}/>
                        </Grid>
                    )}
                </Grid>
                </div>
            </div>
            )}

            {categories.length === 0 && <div className="noResults">
                {absentText || 'Нажаль, дані відсутні'}
            </div>}
        </div>

        {/* if no category, will be displayed like links */}
        <Links
            links={noCategorisedLinks}
            searchBy={searchBy}
        />
    </>
}
