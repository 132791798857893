import {useState} from 'react';

import {DEFAULT_CITY} from 'ultra/const/general'

import Fab from '@mui/material/Fab';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

import AdaptiveMenu from '../../../../../../Components/Adaptive/Menu';

import {useConfigStore} from '../../../../../../Stores/config';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import {NODE_ACTIONS} from '../../../../Reducers/node';

import './index.scss';

export default function CorrectNodeButton(props) {
    const {disabled, closeAction} = props;

    const {nodeDispatch} = useGlobalCustomContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleAddClick = (e) => {
        if (disabled) return;
        closeAction();
        setAnchorEl(e.currentTarget);
    };

    const handleAddClose = () => {
        setAnchorEl(null);
    };

    function showFormPopup(did) {
        nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: {city: DEFAULT_CITY, did}})
    }

    const onProposeChanges = () => {
        showFormPopup('MRfpSwxWtzb5OueyzcUg');
    }

    const onBecomeModerator = () => {
        showFormPopup('BZPWPLKstcJ0GG3Uhv7u');
    }

    return <>
        <Fab
            className='CorrectNodeButton'
            color="neutral"
            onClick={handleAddClick}>
                <DriveFileRenameOutlineIcon />
        </Fab>

        <AdaptiveMenu
            anchorMenu={anchorEl}
            open={Boolean(open)}
            className="CorrectNodeButtonMenu"
            links={<MenuList>
                <MenuItem onClick={onProposeChanges}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Запропонувати правки
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onBecomeModerator}>
                    <ListItemIcon>
                        <LocalPoliceIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Стати модератором
                    </ListItemText>
                </MenuItem>
            </MenuList>}
            onClose={handleAddClose}/>
    </>
}
