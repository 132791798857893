import { create } from 'zustand'

export const useVirtualUriStore = create((set) => ({
  virtualUri: null,
  virtualUriLoading: false,

  setVirtualUri: (uri) => set(() => {
    return {virtualUri: uri, virtualUriLoading: false}
  }),

  removeVirtualUri: () => set(() => {
    return {virtualUri: null, virtualUriLoading: false}
  }),

  setVirtualUriLoading: () => set((store) => {
    return {
      virtualUriLoading: true,
      virtualUri: store.virtualUri
    }
  }),

  removeVirtualUriLoading: () => set((store) => {
    return {
      virtualUriLoading: false,
      virtualUri: store.virtualUri
    }
  })
}))
