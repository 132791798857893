import {create} from 'zustand'
import {isNull} from 'lodash'

import {isEmptyObj} from 'ultra/helpers/utils';
import {isTimestamp, timestampToDate, getFormatedDate, getTime} from 'ultra/helpers/date'
import {CONVERSATION} from "ultra/const/general"

import {getDateText} from '../Modules/Profile/Helpers/conversations'

function getCategories(conversation, tz) {
  if (!conversation) return null;

  const categories = {};

  // let hasNotReaded;

  conversation?.order?.map(mid => {
    const item = {...conversation.list[mid]};
    const date = getTime(isTimestamp(item._timestamp) ? timestampToDate(item._timestamp) : item._timestamp, tz);

    const textLabels = getDateText(date);

    let id = textLabels?.id || getFormatedDate(date);
    let label = textLabels?.label || getFormatedDate(date);

    categories[id] = categories[id] || {label: label, items: []};
    categories[id].items.push(mid);
  })

  return categories;
}

function updateConversation(conversation, tz) {
  if (!conversation) return;
  conversation.categories = getCategories(conversation, tz);
}

export function makeReaded(conversation, ids, uid) {
  const list = {...conversation.list};
  ids?.map(mid => {
    const item = {...list[mid]}
    if (!isEmptyObj(item) && !(item._seen_by.includes(uid))) {
      item._seen_by.push(uid)

      list[mid] = {...item}
    }
  })

  return list;
}

export const useConversationStore = create((set) => ({
  // scrollToBottom: false,
  conversation: null,

  setConversation: (conversation, tz) => set(() => {
    updateConversation(conversation, tz);

    return {conversation};
  }),

  clearConversation: () => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    conversation.order = []
    conversation.list = {}

    return {conversation}
  }),

  setAllReadedInConversation: (uid, tz) => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    if (conversation?.conversation) {
      conversation.list = makeReaded(conversation, conversation.order, uid)

      conversation.conversation._not_readed = 0;
      delete conversation.conversation.firstNotReaded;

      updateConversation(conversation, tz);
    }

    return {conversation};
  }),

  attachNextMessages: (update, tz) => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    if (conversation?.conversation) {
      update.order.map(uri => {
        if (conversation.list[uri]) return;

        conversation.order.push(uri);
        conversation.list[uri] = {...update.list[uri]};
      })

      updateConversation(conversation, tz);
    }

    return {conversation};
  }),

  attachPrevMessages: (update, tz) => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    if (conversation?.conversation) {
      update.order.reverse().map(uri => {
        if (conversation.list[uri]) return;

        conversation.order.unshift(uri);
        conversation.list[uri] = {...update.list[uri]};
      })

      updateConversation(conversation, tz);
    }

    return {conversation};
  }),

  addMessage: (msg, tz) => set((state) => { // uid
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    // if (conversation?.conversation) {
    if (msg.details._not_readed) {
      conversation.conversation._not_readed = msg.details._not_readed;
    }

    conversation.conversation._last_message = msg;

    let mid = msg.details._mid.toString()

    if (!conversation.list[mid]) {
      if (
        !conversation.conversation._not_readed
        || conversation.order.includes(conversation?.conversation?.firstNotReaded)
        || conversation.conversation._not_readed <= CONVERSATION.PER_PAGE
      ) {
        conversation.order.push(mid);
        conversation.list[mid] = msg;
      }
    }
    else {
      // TODO: check is all data same
      conversation.list[mid].sending = false;
    }

    updateConversation(conversation, tz);
    // }

    return {conversation};
  }),

  cleanSendedMessages: () => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    const midToRemove = [];
    conversation?.order?.map(mid => {
      if (conversation.list[mid].sending) {
        delete conversation.list[mid];
        midToRemove.push(mid)
      }
    })

    conversation.order = conversation.order.filter(mid => !midToRemove.includes(mid));
    const lastMid = conversation.order.reverse()[conversation.order.length - 1];

    conversation.conversation._last_message = conversation.list[lastMid];

    return {conversation};
  }),

  updateSeenStatus: (uid, messages, _not_readed, firstNotReaded, tz) => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;

    if (conversation?.conversation) {
      conversation.list = makeReaded(conversation, messages, uid)

      if (!isNull(_not_readed)) conversation.conversation._not_readed = _not_readed;

      if (!firstNotReaded) {
        delete conversation.conversation.firstNotReaded;
      }
      else {
        conversation.conversation.firstNotReaded = firstNotReaded;
      }

      updateConversation(conversation, tz);
    }

    return {conversation};
  }),

  updateOrderStatus: (oid, status, actions) => set((state) => {
    const conversation = {...state.conversation};

    delete conversation.scrollToBottom;
    // if (conversation) {
      conversation.order.map(id => {
        const item = {...conversation.list[id]}
        const details = {...item.details};

        if (details?._oid === oid) {
          details.status_actual = status;
          details.actions = actions;
          item.details = details;

          conversation.list[id] = item;
        }
      })
    // }

    return {conversation};
  }),
}))
