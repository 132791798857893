import Button from '@mui/material/Button';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import {useBackdropPreloaderStore} from '../../../../../../../../../../Stores/backdropPreloader';

import {getTelegramMode} from '../../../../../../../../../../Helpers/telegram';
import {useGlobalCustomContext} from '../../../../../../../../../../Helpers/context';

import PriceWidget from '../../../../../../../../Widgets/Price';

import FormContainer from '../../../../../../../../../../Components/Form/FormContainer';

import {NODE_ACTIONS} from '../../../../../../../../Reducers/node';

export default function DepositStep(props) {
    const {submit, content, belongsToLibrary} = props;

    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {nodeDispatch} = useGlobalCustomContext();
    const city = getClientCity(window) || DEFAULT_CITY;

    if (belongsToLibrary) { // getTelegramMode() || 
        const config = {
            fields: {
                did: {
                    type: 'hidden'
                },
                deposit: {
                    type: 'image',
                    placeholder: 'Скріншот оплати',
                    required: true,
                }
            },
            defaultValues: {
                did: content._did,
            },
            form: {
                submitText: "Забронювати книгу",
            }
        };

        const onSubmit = (data) => {
            hideBackdropPreloader();
            submit(data);
        }

        return <div className='DepositStep'>
            <p className="titleEmojiText titleEmojiTitle depositInfo">1️⃣ Внесіть <PriceWidget value={content.deposit} /> депозиту</p>

            <div className='monobankLink'>
                <Button variant="contained" fullWidth href="https://send.monobank.ua/jar/A9hxJ2DCid" target="_blank">Поповнити Монобанку</Button>
            </div>
            {/* <p className='textNotes depostChoise'>або</p>
            <div className='monobankLink'>
                <Button variant="outlined" fullWidth href="" target="_blank">Напишіть бібліотекарю</Button>
            </div> */}
            <p className='textNotes depostInfo'>
                З поверненням книги у бібліотеку, ваш депозит буде повернуто <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: 'g0jb7AElHeLpnF7ezk11' }})}>згідно правил</span>
            </p>

            <p className="titleEmojiText titleEmojiTitle depositInfo">
                2️⃣ Завантажте підтвердження
            </p>
            <FormContainer
                config={config}
                submitHandler={onSubmit}
            />
        </div>
    }
}
