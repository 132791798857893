import {isFile} from 'ultra/helpers/utils';
import {isGalleryObject} from 'ultra/helpers/gallery';
import {getCacheObj, setCacheObj, getCacheObjInProgress, setCacheObjInProgress, removeCacheObjInProgress} from './cache';

export function getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function authReqest(url, options, body) {
    return new Promise(async (resolve, reject) => {
        // let repeated = 0;

        options.credentials = 'include';

        let response;
        if (body) {
            const formData  = new FormData();

            for(const name in body) {
                // append single file (it also will be presented as array)
                if (body[name] instanceof FileList) {
                    for (const file of body[name]) {
                        formData.append(name, file, file.name)
                    }
                }
                // append gallery
                else if (isGalleryObject(body[name])) {
                    // INFO: File Object will send array of files
                    // and separate stringified object
                    // with _isGallery: true and leaveThoseFiles parameter,
                    // which includes files which must be left on storage

                    // TODO: ??? move outside on the gallery level ... it should be preapred there and by structure on backend

                    const _isGallery = true
                    const leaveThoseFiles = []

                    Object.keys(body[name])
                        .map(n => {
                            const f = body[name][n]
                            if (isFile(f) && body[name][n].size) {
                                formData.append(name, body[name][n], body[name][n].name)
                            } else if (typeof f === 'string'){
                                leaveThoseFiles.push(body[name][n])
                            }
                        })

                    // append object with meta data
                    formData.append(name, JSON.stringify({
                        _isGallery,
                        leaveThoseFiles
                    }))
                }
                // append file
                else if (isFile(body[name])) {
                    formData.append(name, body[name], body[name].name)
                }
                // append other type values
                else {
                    formData.append(name, JSON.stringify(body[name]))
                }
            }

            options.body = formData
        }

        const sendRequest = async () => {
            // repeated++;

            // // TODO: signout and redirect to login page
            // if (repeated > 5) {
            //     reject({message: 'Виникли проблеми авторизації, будь ласка, оновіть сторінку та увійдіть повторно'});
            //     removeUser();
            //     return;
            // }

            try {
                response = await fetch(url, options)
                if (response?.ok) {
                    const text = await response.text()
                    try {
                        const data = JSON.parse(text)
                        resolve(data)
                    } catch(err) {
                        resolve(text)
                    }
                }
                else {
                    if (response?.status === 404) {
                        const error = JSON.parse(await response.text())
                        reject({message: `Сторінка не знайдена`, status: 404, breadcrumbs: error.breadcrumbs})
                    }
                    else if (response?.status === 429) {
                        const error = JSON.parse(await response.text())
                        reject({message: `${error.message}`, status: 429, breadcrumbs: {}})
                    }
                    else {
                        let error = await response.text()

                        try {
                            error = JSON.parse(error)
                            reject({...error, status: response?.status})
                        } catch(err) {
                            reject({...error, status: response?.status})
                        }
                    }

                    // const error = await response.json()

                    // if (response?.status === 401 && error.tokenExpired && window.location.pathname !== '/profile/login') {
                    //     const auth = getAuth();
                    //     const unsubscribe = onAuthStateChanged(auth, async user => {
                    //         const accessToken = await user?.getIdToken(true)

                    //         options.headers = {
                    //             access: accessToken,
                    //         }
    
                    //         sendRequest()

                                // window.location.assign(`/profile/login?tokenExpired=true&redirect=${window.location.href}`)

                    //         unsubscribe()
                    //     })
                    // } else {
                    //      reject({...error, status: response?.status})
                    // }
                }
            } catch (error) {
                // TODO: use same format {message, status}
                console.error(error)
                // TODO: WAS REMOVED FOR SIGNAL WORK PROPERLY
                // reject(error)
            }
        }

        // if (getUser()?.accessToken) {
        //     options.headers = {
        //         access: getUser()?.accessToken,
        //     }
        // }

        sendRequest();
    })
}

export function cachedGet(store, key, url) {
    const inProgressRequest = getCacheObjInProgress(store, key);
    if (inProgressRequest) {
        inProgressRequest?.controller?.abort('New request');
    }

    let promise;
    const cached = getCacheObj(store, key);
    if (cached) {
        promise = new Promise(resolve => {
            removeCacheObjInProgress(store, key);
            resolve(cached);
        })

        return {
            promise
        }
    }

    const result = get(url);
    setCacheObjInProgress(store, key, result);

    promise = new Promise(async res => {
        const data = await result.promise

        setCacheObj(store, key, data);
        removeCacheObjInProgress(store, key);
        res(data);
    })

    return {
        promise,
        controller: result.controller
    }
}

export function get(url) {
    const controller = new AbortController();
    const signal = controller.signal;
    const promise = authReqest(url, {method: 'GET', signal});

    return {
        promise: new Promise(async (resolve, reject) => {
            promise
                .then(resolve)
                .catch(e => {
                    // console.log(e.name)
                    reject(e)
                    // if (e.name === 'AbortError') {
                    //     reject(e)
                    // }
                    // else {
                    //     reject(e)
                    // }
                })
        }),
        controller
    }
}

export function post(url, body) {
    return authReqest(url, {method: 'POST'}, body)
}

export function patch(url, body) {
    return authReqest(url, {method: 'PATCH'}, body)
}

export function del(url) {
    return authReqest(url, {method: 'DELETE'})
}
