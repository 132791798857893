import dayjs from 'dayjs';

import {getClientConfig} from 'ultra/configs/general';

import {get, post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

// export function getLastMessages(cid, last) {
//   let link = `${api}/messages/conversation/${cid}`;

//   let search = '';
//   if (last) search = '?' + `last=${last}`;

//   return get(link + search); 
// }

export function getConversationMessages(cid, after, before, from, end) {
    let link = `${api}/messages/conversation/${cid}`;

    let str = '', search = '';
    if (end) str +=  `&end=true`;
    if (from) str += `&from=${from}`;
    if (after) str += `&after=${after}`;
    if (before) str += `&before=${before}`;
    if (str) search = '?' + str.substring(1);

    return get(link + search);
}

export function sendConversationMessage(cid, data) {
    let link = `${api}/messages/conversation/${cid}`;
    return post(link, data);
}

export function readAllConversationMessages(cid) {
  let link = `${api}/messages/conversation/read-all/${cid}`;
  return post(link);
}
// export function getConversationInfo(cid, search) {
//     let link = `${api}/messages/conversation/info/${cid}`;
//     if (search) link += search
//     return get(link);
// }

export function updateMessagesSeenStatus(cid, messages) {
    let link = `${api}/messages/conversation/seen/${cid}`;
    return post(link, {messages});
}

export function getDateText(date) {
  let id, label;

  const formated = dayjs(date).format('YYYY.MM.DD')
  const yesterday = dayjs().subtract(1, 'day').format('YYYY.MM.DD')
  const today = dayjs().format('YYYY.MM.DD')

  if (today === formated) {
    id = 'today'
    label = 'Сьогодні'

    return {id, label}
  }
  else if (yesterday === formated) {
    id = 'yesterday'
    label = 'Вчора'

    return {id, label}
  }

  return null;
}
