import {useEffect, useRef, useState} from 'react'

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';

import {getStoreFileName} from 'ultra/helpers/storage';
import {isFile} from 'ultra/helpers/utils';

import {useToasterStore} from '../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../Stores/backdropPreloader';

import ContentImage from '../../../Modules/Content/Widgets/ContentImage';

import './index.scss';

export default function UploadImage(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText, minimal} = props;

    const inputRef = useRef(null);
    const hiddenImagesRef = useRef(null);
    const [sizeInfo, setSizeInfo] = useState(null);

    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {toaster, showPromt, closeToaster} = useToasterStore();

    const [name, setName] = useState('')
    const [img, setImg] = useState(null)
    const [imgAvailable, setImgAvailable] = useState(false)

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const onRemoveHandler = () => {
        inputRef.current.value = null;
        onChange({target: {files: [new File([''], 'null-file')]}})
    }

    const onButtonClick = (e) => {
        inputRef.current.click()
    }

    const [placeholder] = useState(field.placeholder ? field.placeholder + (field.required ? ' *' : '') : undefined)
    const [textFieldClassName, setTextFieldClassName] = useState()
    const [actionClassName, setActionClassName] = useState(showErrorText ? 'action error' : 'action')

    function getTextFieldClassName() {
        let textFieldClassName = isDisabled ? 'filesList' : 'filesList emulateEnabled';
        textFieldClassName += showErrorText ? ' error' : ''
        return textFieldClassName
    }

    useEffect(() => {
        if (field?.options?.size) {
            setSizeInfo(`Рекомендовані розміри зображення - ${field?.options?.size.width} x ${field?.options?.size.height}`)
        }

        setTextFieldClassName(getTextFieldClassName());
    }, [])

    useEffect(() => {
        const value = content[field.id];

        if (isFile(value)) {
            // file
            setName(value.name)
            setImg(URL.createObjectURL(value))
            setImgAvailable(value.size > 0)
        } else if (value && typeof value === 'string') {
            // string
            setName(getStoreFileName(value))
            setImg(value)
            setImgAvailable(true)
        } else {
            setName(null)
            setImg(null)
            setImgAvailable(false)
        }
    }, [content[field.id]])

    useEffect(() => {
        setTextFieldClassName(getTextFieldClassName());
        setActionClassName(showErrorText ? 'action error' : 'action')
    }, [showErrorText])

    return <div className={'UploadImage' + (isDisabled ? ' disabled' : '')}>
        <div className='fileUploadButton'>
            <div className='details'>
                <input
                    disabled={isDisabled}
                    className='originalControl'
                    accept="image/*"
                    type="file"
                    ref={inputRef}
                    onChange={e => {
                        const img = document.createElement('img');

                        const selectedImage = e.target.files[0]; // fileInput.files[0];
                    
                        const objectURL = URL.createObjectURL(selectedImage);

                        img.onload = function handleLoad() {
                            if (!field?.options?.size) {
                                onChange(e);
                            }
                            else if (img.width < field.options.size.width) {
                                showPromt({
                                    snackbarMessage: 'Ваше зображеня менше рекомендованих розмірів. Для хорошої якості зображення, ширина має бути більше ' + field.options.size.width + ' пікселів. Бажаєте продовжити?',
                                    snackbarDuration: 10000,
                                    onApproveHandler: () => {
                                        onChange(e);
                                        if (toaster.onCloseHandler) toaster.onCloseHandler();
                                        closeToaster();
                                        hideBackdropPreloader();
                                    },
                                })
                                // inputRef.current.value = null;
                            }
                            else if (img.height < field.options.size.height) {
                                showPromt({
                                    snackbarMessage: 'Ваше зображеня менше рекомендованих розмірів. Для хорошої якості зображення, висота має бути більше ' + field.options.size.height + ' пікселів. Бажаєте продовжити?',
                                    snackbarDuration: 10000,
                                    onApproveHandler: () => {
                                        onChange(e);
                                        if (toaster.onCloseHandler) toaster.onCloseHandler();
                                        closeToaster();
                                        hideBackdropPreloader();
                                    },
                                })
                                // inputRef.current.value = null;
                            }
                            else {
                                onChange(e);
                            }

                            URL.revokeObjectURL(objectURL);
                        };

                        img.src = objectURL;

                        hiddenImagesRef.current.appendChild(img);
                    }}
                />

                {!minimal && <TextField
                    fullWidth
                    disabled
                    className={textFieldClassName}
                    variant="outlined"
                    label={placeholder}
                    value={imgAvailable ? name : ''}
                    error={Boolean(showErrorText)}
                    helperText={showErrorText}
                />}

                <Button
                    className={actionClassName}
                    variant="contained"
                    component="label"
                    tabIndex={-1}
                    onClick={onButtonClick}>
                        {!imgAvailable && <AttachFileIcon />}
                </Button>
            </div>

            <div className='fieldNotes'>
                {sizeInfo && <p>* {sizeInfo}</p>}
                {field.description && <p>* {field.description}</p>}
            </div>
        </div>
        {imgAvailable &&
            <>
                <div className="formPhotos">
                <div className="photo">
                    <span className='imgWrap'>
                        <span className='close' onClick={onRemoveHandler}>
                            <ClearIcon />
                        </span>

                        {img.indexOf('http') !== 0 && <ContentImage image={img} />}
                        {img.indexOf('http') === 0 && <img loading="lazy" src={img} />}
                    </span>
                </div>
                </div>
            </>
        }
        <div className='containerForHiddenImages' ref={hiddenImagesRef}></div>
    </div>
}
