import {timestampToDate, isTimestamp} from 'ultra/helpers/date'; // isTimestamp
import dayjs from 'dayjs';

import './index.scss';

export default function TimeValue(props) {
    const {value} = props;

    if (!value) return <></>

    if (isTimestamp(value)) {
        return <span className='TimeValueWidget'>
            <span className='time'>{dayjs(new Date(timestampToDate(value))).format('HH:mm')}</span>
        </span>
    }

    return <span className='TimeValueWidget'>
        <span className='time'>{dayjs(new Date(value)).format('HH:mm')}</span>
    </span>
}

