import React, { useEffect, useState } from 'react';

import {isNull} from 'lodash';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';

import {useActionsStore} from '../../../../Stores/actions';

import './index.scss';

export default function InlineActions(props) {
    const {actions, unfolded, className, direction, id} = props;

    const {actionId, setActionId, cleanActionId} = useActionsStore();

    const [complexClassName, setComplexClassName] = useState();

    useEffect(() => {
        let newClasses = 'InlineActions';
        // newClasses += ' InlineActions' + actions.length;

        if (className) newClasses += ' ' + className;
        if (unfolded) newClasses += ' ' + 'InlineActionsUnfolded';
        if (direction === 'up') newClasses += ' ' + 'InlineActionsUp';
        if (actionId === id) newClasses += ' ' + 'InlineActionsOpen';

        setComplexClassName(newClasses);
    }, [unfolded, actions, actionId])

    if (actions.length === 0) return <></>

    function showMoreHandle() {
        if (actionId === id) {
            cleanActionId()
        }
        else {
            setActionId(id)
        }
    }

    if (unfolded) {
        return <div className={complexClassName}>
            {actions.map(action => <span key={`inline_actions_unfolded_${action.id}`} className='ActionButton' onClick={(e) => {
                e.stopPropagation();
                action.onClick();
            }}>{action.icon}</span>)}
        </div>
    }

    return <div className={complexClassName}>
        <span className={!isNull(actionId) && actionId !== id ? "ActionButton disabled": "ActionButton"} onClick={showMoreHandle} >
            {actionId !== id && <MoreHorizIcon className='Icon'/>}
            {actionId === id && <CloseIcon className='Icon'/>}
        </span>

        <Slide direction={direction || 'right'} in={(actionId === id)} mountOnEnter unmountOnExit >
            <div className='actions'>
                {actions.map(action => <span key={`inline_actions_${action.id}`} className='ActionButton' onClick={(e) => {
                    e.stopPropagation();
                    action.onClick();
                    cleanActionId();
                }}>{action.icon}</span>)}
            </div>
        </Slide>
    </div>
}
